import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Delete, DragIndicator } from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slider,
  Switch,
  IconButton,
  Tooltip,
} from "@mui/material";
import { SketchPicker } from "react-color";
import { useEffect, useRef, useState } from "react";

const arrayMove = (array, from, to) => {
  const newArray = [...array];
  const [movedItem] = newArray.splice(from, 1);
  newArray.splice(to, 0, movedItem);
  return newArray;
};

function SortableItem({
  id,
  layer,
  toggleVisibility,
  adjustOpacity,
  removeLayer,
  updateColor,
}) {
  const pickerRef = useRef(null);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const [showPicker, setShowPicker] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    marginBottom: "8px",
    boxShadow: 4,
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <ListItem ref={setNodeRef} divider sx={{ ...style }}>
      <ListItemIcon {...attributes} {...listeners} sx={{ cursor: "grab" }}>
        <DragIndicator />
      </ListItemIcon>
      <Tooltip title={layer.name} arrow>
        <ListItemText
          primary={layer.name}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        />
      </Tooltip>
      <Switch checked={layer.visible} onChange={() => toggleVisibility(id)} />
      <Slider
        value={layer.opacity}
        onChange={(event, newValue) => adjustOpacity(id, newValue)}
        min={0}
        max={1}
        step={0.1}
        sx={{ width: 100, ml: 2 }}
      />
      <Box
        sx={{
          width: 36,
          height: 36,
          minWidth: 36,
          minHeight: 36,
          borderRadius: "50%",
          background: layer.color,
          cursor: "pointer",
          boxShadow: 4,
          ml: 2,
        }}
        onClick={() => setShowPicker(!showPicker)}
      />
      {showPicker && (
        <Box
          ref={pickerRef}
          sx={{
            position: "absolute",
            zIndex: 2,
          }}
        >
          <SketchPicker
            color={layer.color}
            onChangeComplete={(color) => updateColor(id, color.hex)}
            styles={{
              default: {
                picker: { background: "#222", borderRadius: "8px" },
                input: { color: "#fff" },
                controls: { background: "#444" },
                sliders: { background: "#555" },
              },
            }}
          />
        </Box>
      )}
      <IconButton onClick={() => removeLayer(id)}>
        <Delete />
      </IconButton>
    </ListItem>
  );
}

export default function LayerControlPanel({ layers, setLayers }) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 5 },
    })
  );

  const toggleVisibility = (id) => {
    setLayers(
      layers.map((layer) =>
        layer.id === id ? { ...layer, visible: !layer.visible } : layer
      )
    );
  };

  const adjustOpacity = (id, newOpacity) => {
    setLayers(
      layers.map((layer) =>
        layer.id === id ? { ...layer, opacity: newOpacity } : layer
      )
    );
  };

  const updateColor = (id, newColor) => {
    setLayers(
      layers.map((layer) =>
        layer.id === id ? { ...layer, color: newColor } : layer
      )
    );
  };

  const removeLayer = (id) => {
    setLayers(layers.filter((layer) => layer.id !== id));
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = layers.findIndex((layer) => layer.id === active.id);
    const newIndex = layers.findIndex((layer) => layer.id === over.id);

    setLayers(arrayMove(layers, oldIndex, newIndex));
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={layers.map((layer) => layer.id)}
        strategy={verticalListSortingStrategy}
      >
        <List>
          {layers.map((layer) => (
            <SortableItem
              key={layer.id}
              id={layer.id}
              layer={layer}
              toggleVisibility={toggleVisibility}
              adjustOpacity={adjustOpacity}
              removeLayer={removeLayer}
              updateColor={updateColor}
            />
          ))}
        </List>
      </SortableContext>
    </DndContext>
  );
}
