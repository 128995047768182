import { Button, useTheme } from "@mui/material";

/**
 * ButtonAtom component for rendering a customizable button.
 *
 * @param {string} variant - The button variant (e.g., 'contained', 'outlined', 'text').
 * @param {string} color - The button color (e.g., 'primary', 'secondary', 'default').
 * @param {function} onClick - The click event handler.
 * @param {boolean} disabled - If true, the button will be disabled.
 * @param {boolean} fullWidth - If true, the button will take the full width of its container.
 * @param {object} sx - Additional custom styles.
 * @param {object} props - Other props to pass to the Button component.
 * @returns {JSX.Element} The customizable Button component.
 */
const ButtonAtom = ({
  variant = "contained",
  color = "primary",
  onClick,
  disabled = false,
  fullWidth = false,
  children,
  startIcon, // Allows an icon to be passed to the left of the text
  endIcon, // Allows an icon to be passed to the right of the text
  sx,
  ...props
}) => {
  const theme = useTheme();

  // Determine styles based on the variant prop.
  const containedStyles = {
    backgroundColor: theme.palette[color].main,
    color: theme.palette.getContrastText(theme.palette[color].main),
    "&:hover": {
      backgroundColor: theme.palette[color].dark,
      transform: "scale(1.05)",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
    },
  };

  const outlinedStyles = {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette[color].main}`,
    color: theme.palette[color].main,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      transform: "scale(1.05)",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
    },
  };

  return (
    <Button
      variant={variant}
      color={color}
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        textTransform: "none", // Keeps text in normal case
        transition: "transform 0.2s ease, box-shadow 0.2s ease", // Smooth transition effects
        // Conditional styling based on variant:
        ...(variant === "contained" ? containedStyles : outlinedStyles),
        "&:active": {
          transform: "scale(0.98)", // Slight shrink on click
        },
        "&:disabled": {
          backgroundColor: theme.palette.action.disabledBackground,
          color: theme.palette.action.disabled,
          cursor: "not-allowed",
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ButtonAtom;
