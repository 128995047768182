import React, { useEffect, useState } from "react";
import { useMap, Rectangle } from "react-leaflet";
import html2canvas from "html2canvas";
import ButtonAtom from "components/ButtonAtom";
import { Box } from "@mui/material";
import L from "leaflet";

const PrintMap = ({ thresholdValue, colorScale }) => {
  const map = useMap();

  const rgbToHex = (r, g, b) =>
    "#" + [r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("");

  // Convert gradient array to color stops
  const colorStops = colorScale.map((stop) => ({
    value: stop.value,
    color:
      typeof stop.color === "string" ? stop.color : rgbToHex(...stop.color),
  }));

  //   const colorScale = [
  //     { value: 0, color: "#313695" },
  //     { value: 0.1, color: "#4575b4" },
  //     { value: 0.2, color: "#74add1" },
  //     { value: 0.3, color: "#abd9e9" },
  //     { value: 0.4, color: "#e0f3f8" },
  //     { value: 0.5, color: "#fee090" },
  //     { value: 0.6, color: "#fdae61" },
  //     { value: 0.7, color: "#f46d43" },
  //     { value: 0.8, color: "#d73027" },
  //     { value: 1.0, color: "#a50026" },
  //   ];

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (isEditing) {
      const handleMapClick = (e) => {
        if (!e.target._bounds) {
          // If click target is not the rectangle
          map.eachLayer((layer) => {
            if (layer instanceof L.Rectangle) {
              layer.editing.disable();
              handleBoundsUpdate({ target: layer });
            }
          });
          setIsEditing(false);
        }
      };

      map.on("click", handleMapClick);
      return () => map.off("click", handleMapClick);
    }
  }, [isEditing]);

  const [bounds, setBounds] = useState([
    [34.25, -118.2],
    [34.135, -118],
  ]);

  // Constants for A4 dimensions (mm)
  const A4_WIDTH = 297; // landscape
  const A4_HEIGHT = 210;
  const A4_RATIO = A4_WIDTH / A4_HEIGHT; // ≈ 1.414

  // Utility function to adjust bounds to A4 ratio
  const adjustBoundsToA4 = (bounds) => {
    const center = calculateBoundsCenter(bounds);

    // Convert bounds to pixels
    const topLeft = map.latLngToContainerPoint([bounds[0][0], bounds[0][1]]);
    const bottomRight = map.latLngToContainerPoint([
      bounds[1][0],
      bounds[1][1],
    ]);

    // Calculate pixel dimensions
    const pixelWidth = Math.abs(bottomRight.x - topLeft.x);
    const pixelHeight = Math.abs(bottomRight.y - topLeft.y);

    // Calculate current pixel ratio
    const currentRatio = pixelWidth / pixelHeight;

    // Calculate new dimensions
    let newPixelWidth = pixelWidth;
    let newPixelHeight = pixelHeight;

    if (currentRatio < A4_RATIO) {
      // Too tall - adjust height
      newPixelHeight = pixelWidth / A4_RATIO;
    } else {
      // Too wide - adjust width
      newPixelWidth = pixelHeight * A4_RATIO;
    }

    // Convert back to lat/lng
    const halfWidthLng =
      (Math.abs(bounds[1][1] - bounds[0][1]) * (newPixelWidth / pixelWidth)) /
      2;
    const halfHeightLat =
      (Math.abs(bounds[1][0] - bounds[0][0]) * (newPixelHeight / pixelHeight)) /
      2;

    return [
      [center.lat - halfHeightLat, center.lng - halfWidthLng],
      [center.lat + halfHeightLat, center.lng + halfWidthLng],
    ];
  };

  // Define rectangle style for the print bounds
  const boundsStyle = {
    weight: 2,
    color: "#fff",
    fillOpacity: 0,
    dashArray: "5, 5", // Creates dotted line
    lineCap: "square",
  };

  const calculateBoundsCenter = (bounds) => {
    const lat = (bounds[0][0] + bounds[1][0]) / 2;
    const lng = (bounds[0][1] + bounds[1][1]) / 2;
    return { lat, lng };
  };

  //   const bounds = [
  //     [34.25, -118.2],
  //     [34.135, -118],
  //   ]; // Example selected area

  const calculateScaleBar = () => {
    // Get map scale in meters per pixel
    const metersPerPixel =
      (40075016.686 *
        Math.abs(Math.cos((map.getCenter().lat * Math.PI) / 180))) /
      Math.pow(2, map.getZoom() + 8);

    // Find appropriate round number for scale bar
    let width = 200; // Desired width in pixels
    let distance = width * metersPerPixel; // Real distance in meters

    // Round to appropriate unit
    let roundDistance;
    let unit;

    if (distance >= 1000) {
      roundDistance = Math.round(distance / 1000);
      unit = "km";
    } else {
      roundDistance = Math.round(distance);
      unit = "m";
    }

    // Calculate actual width for rounded distance
    const actualWidth =
      (roundDistance * (unit === "km" ? 1000 : 1)) / metersPerPixel;

    return {
      width: actualWidth,
      distance: roundDistance,
      unit: unit,
    };
  };

  const calculateMapScale = () => {
    const metersPerPixel =
      (40075016.686 *
        Math.abs(Math.cos((map.getCenter().lat * Math.PI) / 180))) /
      Math.pow(2, map.getZoom() + 8);
    return Math.round(metersPerPixel * 1000);
  };

  const generateGraticules = (bounds) => {
    // Get exact bounds
    const [minLat, maxLat] = [bounds[0][0], bounds[1][0]].sort((a, b) => a - b);
    const [minLng, maxLng] = [bounds[0][1], bounds[1][1]].sort((a, b) => a - b);

    // Calculate area size and determine interval
    const latDiff = Math.abs(maxLat - minLat);
    const lngDiff = Math.abs(maxLng - minLng);

    // Dynamic interval based on area size
    let interval;
    if (latDiff > 5) interval = 1;
    else if (latDiff > 2) interval = 0.5;
    else if (latDiff > 1) interval = 0.25;
    else if (latDiff > 0.5) interval = 0.1;
    else interval = 0.05;

    // Calculate decimal precision based on interval
    const precision = Math.max(1, Math.abs(Math.floor(Math.log10(interval))));

    const graticuleHtml = `
      <div class="graticules" style="position: absolute; width: 100%; height: 100%; overflow: hidden;">
        ${Array.from(
          { length: Math.ceil((maxLat - minLat) / interval) + 1 },
          (_, i) => {
            const lat = minLat + i * interval;
            if (lat <= maxLat) {
              return `
              <div class="graticule-line horizontal" style="
                position: absolute;
                top: ${((maxLat - lat) / (maxLat - minLat)) * 100}%;
                width: 100%;
              ">
                <span class="graticule-label">${lat.toFixed(precision)}°N</span>
              </div>
            `;
            }
            return "";
          }
        ).join("")}
        
        ${Array.from(
          { length: Math.ceil((maxLng - minLng) / interval) + 1 },
          (_, i) => {
            const lng = minLng + i * interval;
            if (lng <= maxLng) {
              return `
              <div class="graticule-line vertical" style="
                position: absolute;
                left: ${((lng - minLng) / (maxLng - minLng)) * 100}%;
                height: 100%;
              ">
                <span class="graticule-label">${lng.toFixed(precision)}°E</span>
              </div>
            `;
            }
            return "";
          }
        ).join("")}
      </div>
    `;

    return graticuleHtml;
  };

  const generateLegendHTML = () => `
  <div class="legend">
    <div class="info-label">Probability</div>
    <div class="legend-gradient">
      <div class="gradient-bar"></div>
      <div class="gradient-labels">
        <span style="left: 0%">Low</span>
        <span style="left: 100%">High</span>
      </div>
    </div>
  </div>
  `;

  // Add CSS to style section
  const legendStyles = `
.legend {
  position: fixed;
  bottom: 100px;
  left: 24px;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(12px);
  border-radius: 12px;
  padding: 16px;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.legend-gradient {
  margin-top: 8px;
  width: 184px;
}

.gradient-bar {
  height: 12px;
  border-radius: 6px;
  background: linear-gradient(to right,
    ${colorStops
      .map((stop) => `${stop.color} ${stop.value * 100}%`)
      .join(",\n      ")}
  );
}

.gradient-labels {
  position: relative;
  height: 20px;
  margin-top: 4px;
}

.gradient-labels span {
  position: absolute;
  font-size: 10px;
  color: rgba(255,255,255,0.6);
  transform: translateX(-50%);
}
`;

  const printMap = async () => {
    if (!map || !bounds) return;

    const style = document.createElement("style");
    style.textContent = `
    .leaflet-tile-loaded {
        animation: none !important;
        will-change: auto;
    }
    `;
    document.head.appendChild(style);

    // Set view to bounds and wait for animation
    await new Promise((resolve) => {
      map.once("moveend", () => {
        resolve();
      });
      map.fitBounds(bounds);
    });

    // Disable map interactions
    map.dragging.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();

    const mapContainer = map.getContainer();

    // await setTimeout(() => {}, 1000);

    // Get bounds from selected area
    const boundsPoints = {
      topLeft: map.latLngToContainerPoint(bounds[0]),
      bottomRight: map.latLngToContainerPoint(bounds[1]),
    };

    // Calculate dimensions from bounds
    const width = boundsPoints.bottomRight.x - boundsPoints.topLeft.x - 2;
    const height = boundsPoints.topLeft.y - boundsPoints.bottomRight.y - 2;

    // Get container offset for proper positioning
    const containerRect = map.getContainer().getBoundingClientRect();
    const offsetX = boundsPoints.topLeft.x + 1;
    const offsetY = boundsPoints.bottomRight.y + 1;

    // Map parameters
    const mapScale = calculateMapScale();
    const center = calculateBoundsCenter(bounds);
    const scaleBar = calculateScaleBar();

    // Hide UI elements
    const uiElements = document.querySelectorAll(
      ".leaflet-control, .leaflet-top, .leaflet-bottom, .leaflet-ui"
    ); // Adjust the selector to match your UI elements
    uiElements.forEach((el) => (el.style.display = "none"));

    // Wait for all tiles to finish loading and add post-load delay
    await new Promise((resolve) => {
      const startTime = Date.now();
      const postLoadDelay = 4000; // Additional delay after tiles load
      let tilesLoaded = false; // Track tile loading state

      const checkTiles = () => {
        const container = map.getContainer();
        const loadingTiles = container.querySelectorAll(
          ".leaflet-tile-loading"
        );
        const loadedTiles = container.querySelectorAll(".leaflet-tile-loaded");
        const timeElapsed = Date.now() - startTime;

        if (loadingTiles.length === 0 && loadedTiles.length > 0) {
          if (!tilesLoaded) {
            tilesLoaded = true;
            // Add post-load delay
            setTimeout(() => {
              resolve();
            }, postLoadDelay);
          }
        } else if (timeElapsed >= 30000) {
          // 30 second timeout
          console.warn("Tile loading timeout reached");
          resolve();
        } else {
          setTimeout(checkTiles, 1000);
        }
      };

      checkTiles();
    });

    // Create high resolution canvas with clipping
    const canvas = await html2canvas(mapContainer, {
      useCORS: true,
      scale: 4,
      width: width,
      height: height,
      x: offsetX,
      y: offsetY,
      //   scrollX: -pixelBounds.min.x,
      //   scrollY: -pixelBounds.min.y,
      //   alpha: false,
      //   backgroundColor: "#ffffff",

      imageTimeout: 0,
      logging: true,
      removeContainer: true,
    });

    const dataUrl = canvas.toDataURL("image/png", 1.0);

    // Restore UI elements
    uiElements.forEach((el) => (el.style.display = ""));

    map.dragging.enable();
    map.touchZoom.enable();
    map.doubleClickZoom.enable();
    map.scrollWheelZoom.enable();

    document.head.removeChild(style);

    // Clean up
    // Create print window with new map
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
        <html>
        <head>
            <title>Map Export</title>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
                body { 
                    margin: 0;
                    padding: 0;
                    background: #000;
                    font-family: 'Inter', sans-serif;
                    -webkit-print-color-adjust: exact !important;
                    print-color-adjust: exact !important;
                }
                .map-container {
                    width: 100vw;
                    height: 100vh;
                    object-fit: contain;
                    page-break-inside: avoid;
                    position: fixed;
                    top: 0;
                    left: 0;
                }
                .overlay {
                    position: fixed;
                    bottom: 24px;
                    right: 24px;
                    background: rgba(0, 0, 0, 0.75);
                    backdrop-filter: blur(12px);
                    border-radius: 12px;
                    padding: 16px;
                    color: white;
                    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
                    border: 1px solid rgba(255, 255, 255, 0.1);
                    z-index: 1000;
                    max-width: 320px;
                }
                .info-grid {
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 12px;
                }
                .info-item {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                }
                .info-label {
                    font-size: 11px;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                    color: rgba(255, 255, 255, 0.6);
                    font-weight: 500;
                }
                .info-value {
                    font-size: 13px;
                    color: rgba(255, 255, 255, 0.9);
                    font-weight: 500;
                }
                .compass {
                    position: fixed;
                    top: 24px;
                    right: 24px;
                    background: rgba(0, 0, 0, 0.75);
                    backdrop-filter: blur(12px);
                    padding: 16px;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .scale-bar {
                    position: fixed;
                    bottom: 24px;
                    left: 24px;
                    background: rgba(0, 0, 0, 0.75);
                    backdrop-filter: blur(12px);
                    padding: 12px;
                    border-radius: 8px;
                    color: white;
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    border: 1px solid rgba(255, 255, 255, 0.1);
                }
                .title-bar {
                    position: fixed;
                    top: 24px;
                    left: 24px;
                    right: 120px;
                    background: rgba(0, 0, 0, 0.75);
                    backdrop-filter: blur(12px);
                    padding: 16px 24px;
                    border-radius: 12px;
                    color: white;
                    border: 1px solid rgba(255, 255, 255, 0.1);
                }
                .title {
                    font-size: 24px;
                    font-weight: 600;
                    margin: 0;
                }
                .subtitle {
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.6);
                    margin: 4px 0 0 0;
                }
                .graticule-line {
                    position: absolute;
                    background: rgba(172, 172, 172, 0.5);
                    max-width: 100%;
                    max-height: 100%;
                }
                .graticule-line.horizontal {
                    width: 100%;
                    height: 1px;
                    left: 0;
                }
                .graticule-line.vertical {
                    width: 1px;
                    height: 100%;
                    top: 0;
                }

                .graticule-label {
                    position: absolute;
                    background: rgba(0, 0, 0, 0.75);
                    color: white;
                    padding: 2px 4px;
                    border-radius: 4px;
                    font-size: 10px;
                    white-space: nowrap;
                    z-index: 1000;
                }

                .graticule-line.horizontal .graticule-label {
                    left: 4px;
                    top: -8px;
                    transform: translateY(-50%);
                }

                .graticule-line.vertical .graticule-label {
                    left: 50%;
                    bottom: 4px;
                    transform: translateX(-50%);
                }

                .graticules {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    pointer-events: none;
                    overflow: hidden;
                    padding: 12px;
                    box-sizing: border-box;
                }
                ${legendStyles}
                @media print {
                    @page {
                        size: landscape;
                        margin: 0;
                    }
                    body {
                        background: #000 !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                        color-adjust: exact !important;
                    }
                    .overlay {
                        background: rgba(0, 0, 0, 0.75) !important;
                        color: white !important;
                        border: 1px solid rgba(255, 255, 255, 0.1) !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                    .compass {
                        background: rgba(0, 0, 0, 0.75) !important;
                        color: white !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                    .info-label {
                        color: rgba(255, 255, 255, 0.6) !important;
                    }
                    .info-value {
                        color: rgba(255, 255, 255, 0.9) !important;
                    }
                    .map-container {
                        break-inside: avoid;
                        page-break-inside: avoid;
                    }
                    .graticules {
                        break-inside: avoid;
                        page-break-inside: avoid;
                    }
                }
            </style>
        </head>
        <body>
            <div class="map-wrapper">
                <img src="${dataUrl}" class="map-container" alt="Map Export" />
                ${generateGraticules(bounds)}
            </div>
            <div class="compass">
                <svg width="60" height="60" viewBox="0 0 60 60">
                    <g transform="translate(30,30)">
                        <circle r="28" fill="none" stroke="rgba(255,255,255,0.2)" />
                        <path d="M0,-25L5,-5L0,-8L-5,-5Z" fill="#FF4B4B" transform="rotate(0)" />
                        <path d="M0,-25L5,-5L0,-8L-5,-5Z" fill="white" transform="rotate(180)" />
                        <text x="0" y="-12" fill="white" text-anchor="middle" font-size="10">N</text>
                        <text x="0" y="16" fill="white" text-anchor="middle" font-size="10">S</text>
                        <text x="12" y="2" fill="white" text-anchor="middle" font-size="10">E</text>
                        <text x="-12" y="2" fill="white" text-anchor="middle" font-size="10">W</text>
                    </g>
                </svg>
            </div>
            <div class="title-bar">
                <h1 class="title">Map Export</h1>
                <p class="subtitle">Generated on ${new Date().toLocaleString()}</p>
            </div>
            ${generateLegendHTML()}

            <div class="scale-bar">
                <div style="display: flex; flex-direction: column; gap: 4px;">
                    <div style="display: flex; align-items: flex-end; position: relative;">
                        <div style="width: ${
                          scaleBar.width
                        }px; height: 3px; background: white;">
                            <div style="position: absolute; left: 0; bottom: 0; width: 1px; height: 6px; background: white;"></div>
                            <div style="position: absolute; left: 50%; bottom: 0; width: 1px; height: 6px; background: white;"></div>
                            <div style="position: absolute; right: 0; bottom: 0; width: 1px; height: 6px; background: white;"></div>
                        </div>
                    </div>
                    <div style="display: flex; justify-content: space-between; width: ${
                      scaleBar.width
                    }px;">
                        <span style="font-size: 10px;">0</span>
                        <span style="font-size: 10px;">${
                          scaleBar.distance / 2
                        } ${scaleBar.unit}</span>
                        <span style="font-size: 10px;">${scaleBar.distance} ${
      scaleBar.unit
    }</span>
                    </div>
                </div>
            </div>
            <div class="overlay">
                <div class="info-grid">
                    <div class="info-item">
                        <span class="info-label">Threshold</span>
                        <span class="info-value">${thresholdValue}</span>
                    </div>
                    <div class="info-item">
                        <span class="info-label">Center</span>
                        <span class="info-value">${center.lat.toFixed(
                          3
                        )}°, ${center.lng.toFixed(3)}°</span>
                    </div>
                    <div class="info-item">
                        <span class="info-label">Bounds</span>
                        <span class="info-value">
                            N: ${bounds[1][0].toFixed(3)}°<br/>
                            S: ${bounds[0][0].toFixed(3)}°<br/>
                            E: ${bounds[1][1].toFixed(3)}°<br/>
                            W: ${bounds[0][1].toFixed(3)}°
                        </span>
                    </div>
                    <div class="info-item">
                        <span class="info-label">Export Date</span>
                        <span class="info-value">${new Date().toLocaleString()}</span>
                    </div>
                </div>
            </div>
            <script>window.onload = function() { window.print(); }</script>
        </body>
        </html>
      `);
    printWindow.document.close();
  };

  const handleBoundsUpdate = (e) => {
    const rect = e.target;
    const rawBounds = rect.getBounds();
    const newBounds = adjustBoundsToA4([
      [rawBounds.getNorth(), rawBounds.getEast()],
      [rawBounds.getSouth(), rawBounds.getWest()],
    ]);

    // Update rectangle immediately
    rect.setBounds(newBounds);
    // Update state
    setBounds(newBounds);
  };

  return (
    <>
      <Rectangle
        bounds={bounds}
        pathOptions={boundsStyle}
        draggable={true}
        eventHandlers={{
          drag: handleBoundsUpdate,
          dragend: handleBoundsUpdate,
          mousedown: (e) => {
            const rect = e.target;
            rect.editing.enable();
            setIsEditing(true);
          },
          mouseup: handleBoundsUpdate,
        }}
      />

      <ButtonAtom
        variant="contained"
        sx={{
          marginTop: "16px",
          width: "100%",
          padding: "12px",
          fontWeight: "bold",
          backgroundColor: "#2196f3",
          "&:hover": {
            backgroundColor: "#1976d2",
          },
        }}
        onClick={printMap}
      >
        Export Map
      </ButtonAtom>
    </>
  );
};

export default PrintMap;
