import styled from "@emotion/styled";
import { Drawer, AppBar as MuiAppBar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useScreenSize from "hooks/useScreenSize";
import { forwardRef } from "react";
import { HEADER_HEIGHT } from "utils/constants";
import CustomToolbar from "./Toolbar"; // Assuming this is a custom component

const desktopDrawerWidth = 500;
const mobileDrawerWidth = 300;

const AppHeader = forwardRef(
  ({ isMapPage = false, handleDrawerOpen, open }, ref) => {
    const theme = useTheme();
    const { isDesktopView } = useScreenSize();
    const drawerWidth = isDesktopView ? desktopDrawerWidth : mobileDrawerWidth;

    // Styled AppBar with modern flat design and transition for drawer
    const AppBar = styled(MuiAppBar, {
      shouldForwardProp: (prop) => prop !== "open",
    })(({ open }) => ({
      backgroundColor: "black", // Sleek dark background
      color: "#fff", // White text for contrast
      height: HEADER_HEIGHT,
      boxShadow: theme.shadows[4], // Subtle shadow for depth
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    }));

    return (
      <AppBar
        ref={ref}
        position="fixed"
        open={open}
        sx={{
          zIndex: theme.zIndex.drawer + 1, // Ensures it's above drawer
        }}
      >
        <CustomToolbar
          isMapPage={isMapPage}
          handleDrawerOpen={handleDrawerOpen}
          open={open}
        />
      </AppBar>
    );
  }
);

// Styled main content area that shifts with the drawer
export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => {
  const { isDesktopView } = useScreenSize();
  const drawerWidth = isDesktopView ? desktopDrawerWidth : mobileDrawerWidth;

  return {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      marginLeft: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  };
});

// Drawer header with a modern feel
export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

// Drawer component with a translucent background for a modern effect
export const CustomDrawer = ({ open, children }) => {
  const { isDesktopView } = useScreenSize();
  const drawerWidth = isDesktopView ? desktopDrawerWidth : mobileDrawerWidth;
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          // backgroundColor: "rgba(33, 33, 33, 0.9)", // Translucent black for modern design
          // backdropFilter: "blur(8px)", // Frosted glass effect
          color: "#fff", // White text for contrast
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      {/* Add any content here, such as a close icon or title */}

      {children}
    </Drawer>
  );
};

export default AppHeader;
