import React, { createContext, useContext, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import ThreeDotsWave from "../features/Loading/LoadingAnimation";
import { loadingText } from "config/constants";
import TypographyAtom from "components/TypographyAtom";

const LoadingScreenContext = createContext();

export const LoadingScreenProvider = ({ children }) => {
  const [loadingData, setLoadingData] = useState();
  const [loadingVisible, setLoadingVisible] = useState(false);

  const getLoadingData = () => {
    const len = loadingText.length;
    setLoadingData(loadingText[Math.floor(Math.random() * len)]);
  };

  useEffect(() => {
    getLoadingData();
  }, []);

  const showLoadingScreen = () => {
    getLoadingData();
    setLoadingVisible(true);
  };

  const hideLoadingScreen = () => {
    setLoadingVisible(false);
  };

  return (
    <LoadingScreenContext.Provider
      value={{ showLoadingScreen, hideLoadingScreen }}
    >
      {children}
      {loadingVisible && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor:
              "rgba(0, 0, 0, 0.65)" /* Translucent grey background */,
            display: "flex",
            zIndex: 9999999999,
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              alignItems: "center",
            }}
          >
            <Grid item xs={12}>
              <Box sx={{ justifyContent: "center", display: "flex" }}>
                {loadingData?.icon}
              </Box>

              <TypographyAtom
                variant="h3"
                fontWeight={700}
                sx={{
                  textAlign: "center",
                  textTransform: "uppercase",
                  letterSpacing: 3,
                  color: "primary.alternateText",
                }}
              >
                {loadingData?.top_text}
              </TypographyAtom>
              <TypographyAtom
                variant="h5"
                fontWeight={700}
                sx={{
                  textAlign: "center",
                  textTransform: "uppercase",
                  letterSpacing: 3,
                  color: "primary.alternateText",
                }}
              >
                {loadingData?.bottom_text}
              </TypographyAtom>
              <ThreeDotsWave />
            </Grid>
          </Grid>
        </Box>
      )}
    </LoadingScreenContext.Provider>
  );
};

export const useLoadingScreen = () => {
  return useContext(LoadingScreenContext);
};
