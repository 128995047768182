import DownloadIcon from "@mui/icons-material/Download";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Stack,
} from "@mui/material";
import STOCK_IMG from "assets/EOS_PRODUCT.png";
import ButtonAtom from "components/ButtonAtom";
import TypographyAtom from "components/TypographyAtom";
import AppHeader from "features/AppHeader/AppHeader";

const ProductList = ({ products, metadata }) => {
  return (
    <Container sx={{ paddingTop: "5rem" }}>
      <AppHeader />
      <TypographyAtom variant="h4" component="h1" gutterBottom>
        Private EOS Products
      </TypographyAtom>

      {Object.entries(products?.private_products || {}).map(
        ([folderName, files]) => {
          const current_product_metadata = metadata?.find(
            (item) => item.event_name === folderName
          );

          const latest_product = current_product_metadata?.product_list?.find(
            (product_item) => product_item.isLatest === true
          );

          return (
            <Accordion key={folderName} sx={{ mb: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${folderName}-content`}
                id={`${folderName}-header`}
                sx={{ borderRadius: "5px" }}
              >
                <TypographyAtom variant="h6" sx={{ fontWeight: "bold" }}>
                  {current_product_metadata?.event_display_name}
                </TypographyAtom>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  {Object.entries(files || {})
                    .filter(([fileName]) => fileName.endsWith(".tif"))
                    .map(([fileName, fileData]) => (
                      <Grid item xs={12} key={fileName}>
                        <Card
                          sx={{
                            minHeight: "450px",
                            display: "flex",
                            flexDirection: "column",
                            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <CardMedia
                            component="img"
                            image={latest_product?.prod_main_png || STOCK_IMG}
                            alt="Product Preview"
                            height="350"
                            loading="lazy"
                          />
                          <CardContent
                            sx={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <Stack spacing={1}>
                              <TypographyAtom
                                variant="h5"
                                sx={{
                                  wordWrap: "break-word",
                                  fontWeight: "bold",
                                }}
                              >
                                {fileName}
                              </TypographyAtom>
                              <TypographyAtom variant="body2">
                                {latest_product?.prod_date}
                              </TypographyAtom>
                              <TypographyAtom
                                sx={{
                                  display: "-webkit-box",
                                  overflow: "hidden",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 3,
                                }}
                                variant="body2"
                                color="text.secondary"
                              >
                                {latest_product?.prod_desc}
                              </TypographyAtom>
                              <TypographyAtom
                                variant="body2"
                                sx={{ marginTop: "auto" }}
                              >
                                Event Date:{" "}
                                {current_product_metadata?.event_start}
                              </TypographyAtom>
                            </Stack>
                            <Stack direction="row" spacing={1} mt={2}>
                              {fileData?.file_url && (
                                <ButtonAtom
                                  variant="contained"
                                  startIcon={<DownloadIcon />}
                                  onClick={() =>
                                    window.open(fileData?.file_url, "_blank")
                                  }
                                >
                                  Download Greyscale TIF
                                </ButtonAtom>
                              )}
                              {latest_product?.prod_kmz && (
                                <ButtonAtom
                                  variant="contained"
                                  startIcon={<DownloadIcon />}
                                  onClick={() =>
                                    window.open(
                                      latest_product.prod_kmz,
                                      "_blank"
                                    )
                                  }
                                >
                                  Download KMZ
                                </ButtonAtom>
                              )}
                            </Stack>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        }
      )}
    </Container>
  );
};

export default ProductList;
