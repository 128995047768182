import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import ButtonAtom from "components/ButtonAtom";
import TypographyAtom from "components/TypographyAtom";
import { useState } from "react";
import SidePanel from "./Sidepanel";

const SarfinderPanel = ({
  setOpenLayers,
  openLayers,
  formData,
  handleSubmit,
  handleAWSJobSubmit,
  handleDateTimeChange,
  handleChange,
  handleJobTypeChange,
  jobType,
  theme,
}) => {
  const [isSubmitHover, setIsSubmitHover] = useState(false);
  const [submissionType, setSubmissionType] = useState("immediate");
  const [tabIndex, setTabIndex] = useState(0);

  const handleSubmissionTypeChange = (event, newSubmissionType) => {
    if (newSubmissionType !== null) {
      setSubmissionType(newSubmissionType);
    }
  };

  const highlightedTextFieldStyle = (isHover) => ({
    border: isHover
      ? `2px solid ${theme.palette.primary.main}`
      : "2px solid transparent", // Use primary color
    boxShadow: isHover ? `0 0 5px ${theme.palette.primary.main}33` : "none", // Subtle shadow with primary color
    transition: "border 0.3s ease, box-shadow 0.3s ease", // Smooth transition
    borderRadius: "8px", // Rounded edges
  });

  const handleCopyDates = () => {
    handleChange({
      target: { name: "awsStartDateTime", value: formData.startDateTime },
    });
    handleChange({
      target: { name: "awsEndDateTime", value: formData.endDateTime },
    });
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleCopyOrbit = () => {
    handleChange({
      target: { name: "awsRelOrbitNumber", value: formData.relOrbitNumber },
    });
  };

  return (
    <SidePanel setOpenLayers={setOpenLayers} openLayers={openLayers}>
      <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab label="Search" />
        <Tab label="AWS HPC Controls" />
      </Tabs>
      <Divider
        sx={{
          marginY: "1rem",

          width: "100%",
          borderColor: "rgba(255, 255, 255, 0.7)",
        }}
      />
      {tabIndex === 0 && (
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ width: "100%", marginBottom: 2 }} spacing={2}>
            <Grid item xs={12}>
              <TypographyAtom
                variant="h5" // Use a typography variant for styling
                fontWeight={700} // Adjust font weight for better readability
                color="white" // Adjust text color for better readability on dark background
                sx={{ marginBottom: "1rem" }} // Space below the title
                textAlign={"center"}
                fullWidth
              >
                SEARCH
              </TypographyAtom>
            </Grid>
            <Grid item xs={12} md={4}>
              <DateTimePicker
                label="Start Date and Time"
                inputVariant="outlined"
                // defaultValue={dayjs("2022-04-17T15:30")}
                value={formData.startDateTime}
                timezone={"UTC"}
                onChange={(val) => {
                  handleDateTimeChange(val, "startDateTime");
                }}
                fullWidth
                margin="normal"
                format="YYYY-MM-DD T HH:mmz"
                //for DPM1
                sx={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DateTimePicker
                label="End Date and Time"
                inputVariant="outlined"
                // defaultValue={dayjs("2022-04-17T15:30")}
                value={formData.endDateTime}
                timezone={"UTC"}
                onChange={(val) => {
                  handleDateTimeChange(val, "endDateTime");
                }}
                fullWidth
                margin="normal"
                format="YYYY-MM-DD T HH:mmz"
                //for DPM1
                sx={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                fullWidth
                variant="outlined"
                error={formData.platformName.length === 0}
              >
                <InputLabel id="platformName-label">Platform</InputLabel>
                <Select
                  labelId="platformName-label"
                  id="platformName"
                  name="platformName"
                  label="Platform"
                  multiple
                  value={formData.platformName}
                  onChange={handleChange}
                >
                  <MenuItem value="alos2">ALOS-2</MenuItem>
                  <MenuItem value="alos1">ALOS-1</MenuItem>
                  <MenuItem value="sentinel1">Sentinel-1</MenuItem>
                  <MenuItem value="cosmo_skymed">Cosmo Skymed</MenuItem>
                </Select>
                {formData.platformName.length === 0 && (
                  <FormHelperText>
                    Please select at least one platform
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="beamMode-label">Beam Mode</InputLabel>
                <Select
                  labelId="beamMode-label"
                  id="beamMode"
                  name="beamMode"
                  label="Beam Mode"
                  multiple
                  value={formData.beamMode}
                  onChange={handleChange}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="IW">
                    IW (Interferometric Wide Swath)
                  </MenuItem>
                  <MenuItem value="EW">EW (Extra Wide Swath)</MenuItem>
                  <MenuItem value="WV">WV (Wave)</MenuItem>
                  <MenuItem value="SM">SM (Stripmap)</MenuItem>

                  <MenuItem value="SM1">SM1 (Stripmap 3m)</MenuItem>
                  <MenuItem value="SM2">SM2 (Stripmap 6m)</MenuItem>
                  <MenuItem value="SM3">SM3 (Stripmap 10m)</MenuItem>
                  <MenuItem value="SPT">SPT (Spotlight)</MenuItem>
                  <MenuItem value="WD1">WD1 (ScanSAR)</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="productType-label">Product Type</InputLabel>
                <Select
                  labelId="productType-label"
                  id="productType"
                  name="productType"
                  label="Product Type"
                  multiple
                  value={formData.productType}
                  onChange={handleChange}
                >
                  <MenuItem value="SLC">SLC</MenuItem>
                  <MenuItem value="RAW">RAW</MenuItem>
                  <MenuItem value="GRDH">GRD</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="orbitDirection-label">
                  Orbit Direction
                </InputLabel>
                <Select
                  labelId="orbitDirection-label"
                  id="orbitDirection"
                  name="orbitDirection"
                  label="Orbit Direction"
                  value={formData.orbitDirection}
                  onChange={handleChange}
                >
                  <MenuItem value="">Both</MenuItem>
                  <MenuItem value="Ascending">Ascending</MenuItem>
                  <MenuItem value="Descending">Descending</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="relOrbitNumber"
                  name="relOrbitNumber"
                  label="Relative Orbit"
                  variant="outlined"
                  // InputLabelProps={{ shrink: true }}
                  value={formData.relOrbitNumber}
                  onChange={handleChange}
                  //for DPM1
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="endRelOrbitNumber"
                  name="endRelOrbitNumber"
                  label="End Relative Orbit"
                  variant="outlined"
                  value={formData.endRelOrbitNumber}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="frameNumber"
                  name="frameNumber"
                  label="Frame"
                  variant="outlined"
                  value={formData.frameNumber}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="endFrameNumber"
                  name="endFrameNumber"
                  label="End Frame"
                  variant="outlined"
                  value={formData.endFrameNumber}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="identifier"
                  name="identifier"
                  label="Identifier"
                  variant="outlined"
                  value={formData.identifier}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <ButtonAtom
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Search
              </ButtonAtom>
            </Grid>
          </Grid>
        </form>
      )}

      {tabIndex === 1 && (
        <form>
          <Grid container sx={{ width: "100%", marginBottom: 2 }} spacing={2}>
            <Grid item xs={12}>
              <TypographyAtom
                variant="h5" // Use a typography variant for styling
                fontWeight={700} // Adjust font weight for better readability
                color="white" // Adjust text color for better readability on dark background
                sx={{ marginBottom: "1rem" }} // Space below the title
                textAlign={"center"}
                fullWidth
              >
                AWS HPC CONTROLS
              </TypographyAtom>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="directoryName"
                  name="directoryName"
                  label="Directory Name (e.g. TD123_Libya_Earthquake)"
                  variant="outlined"
                  value={formData.directoryName}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleChange({
                      target: {
                        name: name,
                        value: value.replace(/ /g, "_").replace("__", "_"),
                      },
                    });
                  }}
                  style={highlightedTextFieldStyle(isSubmitHover)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={5}>
              <DateTimePicker
                label="Start Date and Time"
                inputVariant="outlined"
                // defaultValue={dayjs("2022-04-17T15:30")}
                value={formData.awsStartDateTime}
                timezone={"UTC"}
                onChange={(val) => {
                  handleDateTimeChange(val, "awsStartDateTime");
                }}
                fullWidth
                margin="normal"
                format="YYYY-MM-DD T HH:mmz"
                //for DPM1
                sx={{
                  ...highlightedTextFieldStyle(isSubmitHover),
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <DateTimePicker
                label="End Date and Time"
                inputVariant="outlined"
                // defaultValue={dayjs("2022-04-17T15:30")}
                value={formData.awsEndDateTime}
                timezone={"UTC"}
                onChange={(val) => {
                  handleDateTimeChange(val, "awsEndDateTime");
                }}
                fullWidth
                margin="normal"
                format="YYYY-MM-DD T HH:mmz"
                //for DPM1
                sx={{
                  ...highlightedTextFieldStyle(isSubmitHover),
                  width: "100%",
                }}
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={2}>
              <ButtonAtom
                type="button"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mx: 0, p: 0, height: "100%" }}
                onClick={handleCopyDates}
              >
                Copy dates
              </ButtonAtom>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="awsRelOrbitNumber"
                  name="awsRelOrbitNumber"
                  label="Relative Orbit"
                  variant="outlined"
                  InputLabelProps={{ shrink: formData.awsRelOrbitNumber }}
                  value={formData.awsRelOrbitNumber}
                  onChange={handleChange}
                  //for DPM1
                  style={highlightedTextFieldStyle(isSubmitHover)}
                />
              </FormControl>
            </Grid>

            {/* Submit Button */}
            <Grid item xs={2}>
              <ButtonAtom
                type="button"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mx: 0, p: 0, height: "100%" }}
                onClick={handleCopyOrbit}
              >
                Copy Orbit
              </ButtonAtom>
            </Grid>

            <Grid item xs={12} md={3}>
              <DatePicker
                id="masterDate"
                name="masterDate"
                label="Master Date (Pre Event Scene)"
                inputVariant="outlined"
                value={formData.awsMasterDate}
                timezone={"UTC"}
                onChange={(val) => {
                  console.log(val);
                  handleDateTimeChange(val, "awsMasterDate");
                }}
                fullWidth
                margin="normal"
                format="YYYY-MM-DD"
                //for DPM1
                sx={{
                  ...highlightedTextFieldStyle(isSubmitHover),
                  width: "100%",
                }}
              />
            </Grid>
            {/* Event Date text field for AWS HPC */}
            <Grid item xs={12} md={3}>
              <DatePicker
                id="eventDate"
                name="eventDate"
                label="Event Date"
                inputVariant="outlined"
                value={formData.awsEventDate}
                timezone={"UTC"}
                onChange={(val) => {
                  handleDateTimeChange(val, "awsEventDate");
                }}
                fullWidth
                margin="normal"
                format="YYYY-MM-DD"
                //for DPM1
                sx={{
                  ...highlightedTextFieldStyle(isSubmitHover),
                  width: "100%",
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <ToggleButtonGroup
                fullWidth
                value={submissionType}
                exclusive
                onChange={handleSubmissionTypeChange}
                aria-label="submission type"
              >
                <ToggleButton value="immediate" aria-label="immediate">
                  Immediate
                </ToggleButton>
                <ToggleButton value="scheduled" aria-label="scheduled">
                  Scheduled
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {submissionType === "scheduled" && (
              <>
                {/* Expected Capture Date */}
                <Grid item xs={4}>
                  <DatePicker
                    id="expectedCaptureDatetime"
                    name="expectedCaptureDatetime"
                    label="Expected Capture DateTime"
                    inputVariant="outlined"
                    value={formData.expectedCaptureDatetime}
                    timezone={"UTC"}
                    onChange={(val) => {
                      handleDateTimeChange(val, "expectedCaptureDatetime");
                    }}
                    fullWidth
                    margin="normal"
                    format="YYYY-MM-DD"
                    //for DPM1
                    sx={{
                      ...highlightedTextFieldStyle(isSubmitHover),
                      width: "100%",
                    }}
                  />
                </Grid>

                {/* Queue End Date */}
                <Grid item xs={4}>
                  <DatePicker
                    id="queueEndDate"
                    name="queueEndDate"
                    label="Queue End Date"
                    inputVariant="outlined"
                    value={formData.queueEndDate}
                    timezone={"UTC"}
                    onChange={(val) => {
                      handleDateTimeChange(val, "queueEndDate");
                    }}
                    fullWidth
                    margin="normal"
                    format="YYYY-MM-DD"
                    //for DPM1
                    sx={{
                      ...highlightedTextFieldStyle(isSubmitHover),
                      width: "100%",
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>

          {/* to submit jobs to AWS HPC*/}
          <Grid container sx={{ width: "100%", marginBottom: 2 }} spacing={2}>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <InputLabel id="job-type-select-label">Job Type</InputLabel>
                <Select
                  labelId="job-type-select-label"
                  value={jobType}
                  onChange={handleJobTypeChange}
                  label="Job Type"
                >
                  <MenuItem value="DPM1">Start DPM1</MenuItem>
                  <MenuItem value="FPM1">Start FPM1</MenuItem>
                  <MenuItem value="DPM2">Start DPM2</MenuItem>
                  <MenuItem value="FPM2">Start FPM2</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Submit Button */}
            <Grid item xs={4}>
              <ButtonAtom
                type="button"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleAWSJobSubmit}
                onMouseOver={() => setIsSubmitHover(true)}
                onMouseLeave={() => setIsSubmitHover(false)}
                style={{
                  height: "100%",
                }}
              >
                Submit Job
              </ButtonAtom>
            </Grid>

            {/* <Grid item xs={12}>
              <ButtonAtom
                fullWidth
                type="button"
                variant="contained"
                color="primary"
                onClick={() => {
                  window.open(
                    "https://s76at197s6.execute-api.us-east-1.amazonaws.com"
                  );
                }}
              >
                Open AWS Fleet
              </ButtonAtom>
            </Grid> */}
          </Grid>
        </form>
      )}
    </SidePanel>
  );
};

export default SarfinderPanel;
