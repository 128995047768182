import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Collapse,
  Divider,
  IconButton,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import React from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ButtonAtom from "components/ButtonAtom";
import TypographyAtom from "components/TypographyAtom";
import { CustomDrawer } from "features/AppHeader/AppHeader";
import { enqueueSnackbar } from "notistack";

const CustomDrawerComponent = ({
  open,
  handleDrawerClose,
  state,
  sidebarDescription,
  theme,
}) => {
  const [expanded, setExpanded] = React.useState(true);

  // Function to handle copy to clipboard
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    // alert("Copied to clipboard: " + text);
    enqueueSnackbar(`Item copied!`, {
      variant: "info",
    });
  };

  // Function to toggle collapse of details
  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  // Function to dynamically render the JSON as key-value pairs
  const renderDescriptionDetails = (description) => {
    // Return null if no description is provided
    if (!description) return null;

    let parsedDescription;
    try {
      // Safely parse the JSON string
      parsedDescription = JSON.parse(description);
    } catch (error) {
      console.error("Failed to parse description JSON:", error);
      return null;
    }

    return (
      <Stack spacing={2}>
        {/* Header section with toggle */}
        <Stack direction="row" alignItems="center">
          <TypographyAtom
            variant="h6"
            sx={{ fontWeight: "bold", color: "#fff" }}
          >
            Details
          </TypographyAtom>
          <IconButton onClick={handleToggleExpand}>
            {expanded ? (
              <ExpandLessIcon sx={{ color: "#fff" }} />
            ) : (
              <ExpandMoreIcon sx={{ color: "#fff" }} />
            )}
          </IconButton>
        </Stack>

        {/* Collapsible content */}
        <Collapse in={expanded}>
          <Stack gap={1}>
            {Object.entries(parsedDescription)
              // Filter out entries with falsy values
              .filter(([_, value]) => value)
              .map(([key, value]) => {
                // Format the key for better readability
                const formattedKey = key
                  .replace(/([A-Z])/g, " $1")
                  .toUpperCase();

                return (
                  <Paper
                    key={key}
                    elevation={1}
                    sx={{
                      padding: "0.2rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor: "#151515", // Dark background for the container
                    }}
                  >
                    <TypographyAtom
                      variant="subtitle1"
                      sx={{ fontWeight: "bold", flex: 1, color: "#fff" }}
                    >
                      {formattedKey}:
                    </TypographyAtom>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ mx: 1, backgroundColor: "#555" }}
                    />
                    <TypographyAtom
                      variant="subtitle1"
                      sx={{
                        wordBreak: "break-word",
                        backgroundColor: "#444", // Dark grey for the value background
                        padding: "0.2rem",
                        borderRadius: "4px",
                        flex: 2,
                        color: "#fff",
                      }}
                    >
                      {value}
                    </TypographyAtom>
                    <Tooltip title="Copy to clipboard">
                      <IconButton onClick={() => handleCopy(value)}>
                        <ContentCopyIcon sx={{ color: "#fff" }} />
                      </IconButton>
                    </Tooltip>
                  </Paper>
                );
              })}
          </Stack>
        </Collapse>
      </Stack>
    );
  };

  return (
    <CustomDrawer open={open} anchor="left">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0.5rem",
          position: "sticky", // Stay at the top
          top: 0, // Stay at the top
          backgroundColor: "#404040", // Darker background for contrast
          zIndex: 1, // Above other content
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)", // Soft shadow for depth
        }}
      >
        <TypographyAtom
          width={"100%"}
          variant="h5"
          sx={{
            textDecoration: "underline",
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          Product Details
        </TypographyAtom>

        {/* Improved Close Button */}
        <IconButton
          onClick={handleDrawerClose}
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.1)", // Transparent white background
            color: "#fff", // White icon
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.3)", // More intense on hover
              color: "primary.main", // Highlight on hover
            },
            borderRadius: "50%", // Circle shape
            padding: "8px", // Larger click area
          }}
        >
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon fontSize="large" /> // Larger close icon
          ) : (
            <ChevronRightIcon fontSize="large" />
          )}
        </IconButton>
      </div>

      <Divider sx={{ backgroundColor: "#666" }} />

      <Stack sx={{ padding: "1rem", gap: "1rem" }}>
        {/* Title Section */}
        <TypographyAtom
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "#fff", // White for contrast
            wordBreak: "break-word",
          }}
        >
          {sidebarDescription?.title}
        </TypographyAtom>

        <Divider sx={{ backgroundColor: "#666" }} />

        {/* Event Name */}
        {state?.event?.event_display_name && (
          <TypographyAtom
            variant="h5"
            sx={{ color: "primary.main", fontWeight: "bold" }}
          >
            {state?.event?.event_display_name}
          </TypographyAtom>
        )}

        {renderDescriptionDetails(sidebarDescription?.description)}

        {/* Buttons Section */}
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <Tooltip title="Copy to clipboard">
            <ButtonAtom
              variant="outlined"
              startIcon={<ContentCopyIcon />}
              onClick={() => handleCopy(sidebarDescription?.description)}
              color="primary"
            >
              Copy All Attributes
            </ButtonAtom>
          </Tooltip>
        </Stack>
      </Stack>

      <Divider sx={{ backgroundColor: "#666" }} />
    </CustomDrawer>
  );
};

export default CustomDrawerComponent;
