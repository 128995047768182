// whenOverpass.js

/**
 * Format a Date object as 'YYYY-MM-DDTHH:mm' in UTC.
 * @param {Date} date
 * @returns {string}
 */
// function formatDate(date) {
//   const pad = (n) => n.toString().padStart(2, "0");
//   return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(
//     date.getUTCDate()
//   )}T${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}`;
// }

function formatDate(date) {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short", // Include time zone abbreviation
  };

  return date.toLocaleString(undefined, options);
}

/**
 * Adds a given number of days to a date.
 * @param {Date} date
 * @param {number} days
 * @returns {Date}
 */
const addDays = (date, days) => new Date(date.getTime() + days * 86400000);

/**
 * Computes the next overpass date.
 * Starting at `acqDate`, repeatedly adds `dd` days until the date is on or after `evtDate`.
 * @param {Date} acqDate
 * @param {number} dd
 * @param {Date} evtDate
 * @returns {Date}
 */
const getNextOverpassDate = (acqDate, dd, evtDate) => {
  let chkDate = new Date(acqDate);
  while (chkDate < evtDate) {
    chkDate = addDays(chkDate, dd);
  }
  return chkDate;
};

/**
 * Returns the next overpass information for a given satellite.
 *
 * For satellites S1, A2, TSX, and R2, the function returns an object with the satellite and its next overpass time.
 * For CSK satellites, four candidate overpass dates are computed using different offsets.
 * The function returns an object with:
 *   - `selected`: the candidate with the earliest (minimum) overpass date,
 *   - `sorted`: a list of all candidates sorted by overpass time.
 *
 * @param {string} sat - Satellite name (e.g., 'S1', 'A2', 'TSX', 'R2', 'CSK1', etc.)
 * @param {string} dtsAcq - Acquisition datetime string in 'YYYY-MM-DDTHH:mm' format.
 * @param {string} [dtsEvt] - Event datetime string in 'YYYY-MM-DDTHH:mm' format. If not provided, the current UTC time is used.
 * @returns {Object}
 */
export function getNextOverpass(sat, dtsAcq, dtsEvt) {
  var evtDate = new Date();
  // Use current UTC time if dtsEvt is not provided.
  if (dtsEvt) {
    evtDate = new Date(dtsEvt);
    console.log(`Current UTC (${dtsEvt}) used.`);
  }

  const acqDate = new Date(dtsAcq);

  // Handle non-CSK satellites.
  if (sat === "SENTINEL-1") {
    const dd = 12;
    const ovp = getNextOverpassDate(acqDate, dd, evtDate);
    return { satellite: sat, overpass: formatDate(ovp) };
  } else if (sat === "ALOS-2") {
    const dd = 14;
    const ovp = getNextOverpassDate(acqDate, dd, evtDate);
    return { satellite: sat, overpass: formatDate(ovp) };
  } else if (sat === "TSX") {
    const dd = 11;
    const ovp = getNextOverpassDate(acqDate, dd, evtDate);
    return { satellite: sat, overpass: formatDate(ovp) };
  } else if (sat === "R2") {
    const dd = 24;
    const ovp = getNextOverpassDate(acqDate, dd, evtDate);
    return { satellite: sat, overpass: formatDate(ovp) };
  } else if (sat.startsWith("CSK")) {
    let candidates = [];
    const dd = 16;
    // Calculate candidate dates based on CSK satellite type
    if (sat === "CSK1") {
      const ovp1 = getNextOverpassDate(acqDate, dd, evtDate);
      const ovp2 = getNextOverpassDate(addDays(acqDate, 8), dd, evtDate);
      const ovp3 = getNextOverpassDate(addDays(acqDate, 9), dd, evtDate);
      const ovp4 = getNextOverpassDate(addDays(acqDate, 12), dd, evtDate);
      candidates = [
        { satellite: "CSK1", overpass: ovp1 },
        { satellite: "CSK2", overpass: ovp2 },
        { satellite: "CSK3", overpass: ovp3 },
        { satellite: "CSK4", overpass: ovp4 },
      ];
    } else if (sat === "CSK2") {
      const ovp2 = getNextOverpassDate(acqDate, dd, evtDate);
      const ovp3 = getNextOverpassDate(addDays(acqDate, 1), dd, evtDate);
      const ovp4 = getNextOverpassDate(addDays(acqDate, 4), dd, evtDate);
      const ovp1 = getNextOverpassDate(addDays(acqDate, 8), dd, evtDate);
      candidates = [
        { satellite: "CSK1", overpass: ovp1 },
        { satellite: "CSK2", overpass: ovp2 },
        { satellite: "CSK3", overpass: ovp3 },
        { satellite: "CSK4", overpass: ovp4 },
      ];
    } else if (sat === "CSK3") {
      const ovp3 = getNextOverpassDate(acqDate, dd, evtDate);
      const ovp4 = getNextOverpassDate(addDays(acqDate, 3), dd, evtDate);
      const ovp1 = getNextOverpassDate(addDays(acqDate, 7), dd, evtDate);
      const ovp2 = getNextOverpassDate(addDays(acqDate, 15), dd, evtDate);
      candidates = [
        { satellite: "CSK1", overpass: ovp1 },
        { satellite: "CSK2", overpass: ovp2 },
        { satellite: "CSK3", overpass: ovp3 },
        { satellite: "CSK4", overpass: ovp4 },
      ];
    } else if (sat === "CSK4") {
      const ovp4 = getNextOverpassDate(acqDate, dd, evtDate);
      const ovp1 = getNextOverpassDate(addDays(acqDate, 4), dd, evtDate);
      const ovp2 = getNextOverpassDate(addDays(acqDate, 12), dd, evtDate);
      const ovp3 = getNextOverpassDate(addDays(acqDate, 13), dd, evtDate);
      candidates = [
        { satellite: "CSK1", overpass: ovp1 },
        { satellite: "CSK2", overpass: ovp2 },
        { satellite: "CSK3", overpass: ovp3 },
        { satellite: "CSK4", overpass: ovp4 },
      ];
    } else {
      throw new Error("Satellite name is not valid.");
    }

    // Find the candidate with the minimum overpass date.
    const selected = candidates.reduce((prev, curr) =>
      curr.overpass < prev.overpass ? curr : prev
    );
    // Sort candidates by overpass date.
    const sorted = candidates
      .slice()
      .sort((a, b) => a.overpass - b.overpass)
      .map((c) => ({
        satellite: c.satellite,
        overpass: formatDate(c.overpass),
      }));

    return {
      selected: {
        satellite: selected.satellite,
        overpass: formatDate(selected.overpass),
      },
      sorted,
    };
  } else {
    throw new Error("Satellite name is not valid.");
  }
}
