import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DialogContentText,
  useTheme,
} from "@mui/material";
import TypographyAtom from "./TypographyAtom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialOceanic } from "react-syntax-highlighter/dist/esm/styles/prism";
import ButtonAtom from "./ButtonAtom";

const CustomConfirmationDialog = ({
  open,
  title,
  message,
  children,
  onConfirm,
  onCancel,
  confirmText = "Yes",
  cancelText = "No",
  confirmButtonColor = "primary",
  cancelButtonColor = "primary",
  maxWidth = "md",
  fullWidth = true,
}) => {
  const theme = useTheme();

  // const formattedMessage =
  //   typeof message === "object"
  //     ? JSON.stringify(message, null, 2) // Format JSON with 2-space indentation
  //     : message;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          borderRadius: 3, // Rounded corners
          boxShadow: `0px 4px 12px rgba(0, 0, 0, 0.3)`,
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "1.25rem",
          paddingBottom: 1,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 2 }}>
        {/* {formattedMessage && (
          <SyntaxHighlighter
            language="json"
            style={materialOceanic}
            customStyle={{
              backgroundColor: theme.palette.background.default,
              padding: "16px",
              borderRadius: "8px",
              fontSize: "0.9rem",
              overflowX: "auto",
            }}
          >
            {formattedMessage}
          </SyntaxHighlighter>
        )} */}
        {children}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", paddingBottom: 2 }}>
        <ButtonAtom
          onClick={onCancel}
          color={cancelButtonColor}
          variant="outlined"
          sx={{
            borderRadius: "999px",
            padding: "6px 16px",
            fontWeight: "bold",
          }}
        >
          {cancelText}
        </ButtonAtom>
        <ButtonAtom
          onClick={onConfirm}
          color={confirmButtonColor}
          variant="contained"
          sx={{
            borderRadius: "999px",
            padding: "6px 16px",
            fontWeight: "bold",
            marginLeft: 1,
          }}
          autoFocus
        >
          {confirmText}
        </ButtonAtom>
      </DialogActions>
    </Dialog>
  );
};

export default CustomConfirmationDialog;
