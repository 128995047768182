import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getRoute } from "utils/routes";
import ProductList from "features/ProductAccess/ProductList.";
import { useCallAPI } from "utils/helper";
import { useUser } from "provider/auth/useUser";
import { useMetadataContext } from "context/MetadataContext";

const ProductAccessPage = () => {
  const userQuery = useUser();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [bucketResponses, setBucketResponses] = useState([]);
  const metadata = useMetadataContext();

  // API call to fetch bucket responses
  const fetchBucketResponses = useCallAPI(
    `fetchBucketResponses`,
    `/bucket/responses/`,
    "GET"
  );

  // Check user authentication/authorization
  useEffect(() => {
    if (
      !userQuery.isPending &&
      !userQuery.data?.username &&
      !(userQuery.data?.is_staff || userQuery.data?.is_superuser)
    ) {
      navigate(getRoute("home"));
    }
  }, [userQuery, navigate]);

  // Fetch bucket responses on component mount
  useEffect(() => {
    fetchBucketResponses.mutate(null, {
      onSuccess: (data) => {
        console.log(data);
        setBucketResponses(data); // Store the bucket responses in state
      },
      onError: (error) => {
        enqueueSnackbar("Failed to fetch bucket responses", {
          variant: "error",
        });
      },
    });
  }, []);

  return <ProductList products={bucketResponses} metadata={metadata} />;
};

export default ProductAccessPage;
