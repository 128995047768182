import { Box, Card, CardContent, Divider, Grid } from "@mui/material";

import { useEffect, useRef, useState } from "react";

import "leaflet-area-select";

import CssBaseline from "@mui/material/CssBaseline";

import { useTheme } from "@mui/material/styles";

import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useLoadingScreen } from "provider/LoadingScreen";
import { MapWithBounds, parseXmlError, useCallAPI } from "utils/helper";

//DPM1
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import AppHeader, { Main } from "features/AppHeader/AppHeader";
import MapView from "features/MapPage/Map/MapView";
import SlidingTable from "features/Sarfinder/SlidingTable";
import { useUser } from "provider/auth/useUser";
import { GeoJSON, Polygon, TileLayer } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import { getRoute } from "utils/routes";

import CustomConfirmationDialog from "components/ConfirmationDialog";
import TypographyAtom from "components/TypographyAtom";
import AWSTaskSubmitter from "features/Sarfinder/AWSStackJobSubmitter";
import CustomDrawerComponent from "features/Sarfinder/Drawer";
import { QueryUtils } from "features/Sarfinder/QueryUtils";
import SarfinderPanel from "features/Sarfinder/SarfinderPanel";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialOceanic } from "react-syntax-highlighter/dist/esm/styles/prism";

const getCenterPoint = (event_bbox) => {
  return [
    (event_bbox[0][0] + event_bbox[1][0]) / 2.0,
    (event_bbox[0][1] + event_bbox[1][1]) / 2.0,
  ];
};

const SarfinderPage = () => {
  const userQuery = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !userQuery.isLoading &&
      !userQuery.data?.username &&
      !(userQuery.data?.is_staff || userQuery.data?.is_superuser)
    ) {
      navigate(getRoute("home"));
    }
  }, [userQuery, navigate]);

  const theme = useTheme();
  const [state, setState] = useState();

  const [sidebarDescription, setSidebarDescription] = useState({});
  const appBarRef = useRef(null);
  const mapViewRef = useRef(null);
  const [appBarHeight, setAppBarHeight] = useState(0);
  const [open, setOpen] = useState(false);
  const [openLayers, setOpenLayers] = useState(false);
  const [selectionArea, setSelectionArea] = useState();
  const { showLoadingScreen, hideLoadingScreen } = useLoadingScreen();
  const [currentHighlight, setCurrentHighlight] = useState(null);

  const [selected, setSelected] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  // const [selectedIdentifier, setSelectedIdentifier] = useState(null);

  //useState created for DPM1 button
  const [jobType, setJobType] = useState("DPM1");
  const [jsonData, setJsonData] = useState([]);

  const geoJsonRefs = useRef([]);
  const layerRefs = useRef([]);

  const [formData, setFormData] = useState({
    platformName: ["sentinel1"],
    beamMode: [],
    orbitDirection: "",
    relOrbitNumber: "",
    endRelOrbitNumber: "",
    productType: [],
    frameNumber: "",
    endFrameNumber: "",
    identifier: "",
    startDateTime: moment.utc().subtract(1, "year").startOf("year"),
    endDateTime: moment.utc().endOf("day"),
    awsStartDateTime: null,
    awsEndDateTime: null,
    awsRelOrbitNumber: null,

    directoryName: "",
    awsMasterDate: null,
    awsEventDate: null,
    expectedCaptureDatetime: null,
    queueEndDate: null,
  });

  const outline_color = ["#be93e677", "#FFA50077", "#00800077"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateTimeChange = (date, key) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: date,
    }));
  };

  const handleJobTypeChange = (event) => {
    setJobType(event.target.value);
  };

  const fixAntiMeridian = (geojson) => {
    // if (geojson && Array.isArray(geojson.features)) {
    //   geojson.features.forEach((feature) => {
    //     if (feature.geometry && feature.geometry.type === "Polygon") {
    //       feature.geometry.coordinates = feature.geometry.coordinates.map(
    //         (ring) =>
    //           ring.map((coord) => {
    //             if (coord[0] < 0) {
    //               coord[0] = 180 + (180 + coord[0]);
    //             }
    //             return coord;
    //           })
    //       );
    //     } else if (
    //       feature.geometry &&
    //       feature.geometry.type === "MultiPolygon"
    //     ) {
    //       feature.geometry.coordinates = feature.geometry.coordinates.map(
    //         (polygon) =>
    //           polygon.map((ring) =>
    //             ring.map((coord) => {
    //               if (coord[0] < 0) {
    //                 coord[0] = 180 + (180 + coord[0]);
    //               }
    //               return coord;
    //             })
    //           )
    //       );
    //     }
    //   });
    // }
    return geojson;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Clears and resets all data
    geoJsonRefs.current = [];
    layerRefs.current = [];
    setJsonData([]);
    setSelected([]);

    setOpenLayers(false);
    showLoadingScreen();
    const fetchPromises = formData.platformName.map((platform) => {
      const queryUtils = new QueryUtils(formData, selectionArea);

      return GeoJSONMap(
        "https://sf.earthobservatory.sg:8443/geoserver/wfs?service=wfs&version=2.0.0&request=GetFeature&typeNames=EOS:" +
          platform +
          "&outputFormat=application/json" +
          queryUtils.getFilters()
      );
    });

    Promise.all(fetchPromises)
      .then((promises) => {
        var result_array = promises.map((result) => {
          return fixAntiMeridian(result);
        });
        setJsonData(result_array);
        enqueueSnackbar(`Data loaded`, {
          variant: "info",
        });
      })
      .catch((error) => {
        enqueueSnackbar(`Error fetching data: ${error}`, {
          variant: "error",
        });
        hideLoadingScreen();
      })
      .finally(() => {
        hideLoadingScreen();
      });
  };

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, [appBarRef.current]);

  const GeoJSONMap = async (url) => {
    const response = await fetch(url);
    if (response.status == 200) {
      const data = await response.json();
      return data;
    } else {
      const xmlString = await response.text();

      throw new Error(parseXmlError(xmlString)?.exceptionText);
    }

    // fetch(url)
  };

  const handleSideBarDisplay = async (title, description) => {
    setSidebarDescription({ title: title, description: description });
    handleDrawerOpen();
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const submitJob = useCallAPI("submitJob", "/sarfinder/submit-job/", "POST");

  const handleAWSJobSubmit = async () => {
    const taskSubmitter = new AWSTaskSubmitter(
      jobType,
      selectionArea,
      jsonData
        .map((platform) => platform.features)
        .flat()
        .filter((item) => selected.includes(item.id)),
      formData,
      selected,
      setDialogOpen,
      setDialogProps,
      userQuery.data?.is_staff
    );
    await taskSubmitter.submitTask(submitJob);
  };

  const onEachClick = (feature, layer, title, description) => {
    layer.on({
      click: (e) => {
        handleFeatureHighlight(layer.options.id);
      },
    });
  };

  const handleFeatureHighlight = (id) => {
    if (!geoJsonRefs.current) return;
    // Loop through the features to find the matching identifier
    layerRefs.current.forEach((layerRef) => {
      if (layerRef.options.id === id) {
        setOpenLayers(false);

        const feature = layerRef.options.feature;
        const bbox = feature?.bbox;

        // Fly to bounds
        mapViewRef.current.flyToBounds(
          [
            [bbox[1], bbox[0]], // Southwest corner
            [bbox[3], bbox[2]], // Northeast corner
          ],
          { duration: 1, easeLinearity: 0.1 }
        );

        // Update sidebar display
        handleSideBarDisplay(
          decodeURIComponent(escape(feature?.id)),
          JSON.stringify(feature?.properties, null, 2)
        );

        // Set layer color after sidebar is updated
        setTimeout(async () => {
          setLayerColor(layerRef, "#ffd126AA"); // Highlight color
        }, 100); // Short delay to ensure other updates are done
        setCurrentHighlight(id);
      }
    });
  };

  const setLayerColor = (layer, color) => {
    layer.setStyle({ color: color });
    layer.bringToFront();
  };
  // const resetLayerStyle = (layer) => {
  //   layer.resetStyle();
  // };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppHeader
        position="fixed"
        isMapPage
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        ref={appBarRef}
      />
      <SarfinderPanel
        setOpenLayers={setOpenLayers}
        openLayers={openLayers}
        formData={formData}
        handleSubmit={handleSubmit}
        handleAWSJobSubmit={handleAWSJobSubmit}
        handleDateTimeChange={handleDateTimeChange}
        handleChange={handleChange}
        handleJobTypeChange={handleJobTypeChange}
        jobType={jobType}
        theme={theme}
      />

      <CustomDrawerComponent
        open={open}
        handleDrawerClose={handleDrawerClose}
        state={state}
        sidebarDescription={sidebarDescription}
        theme={theme}
      />

      <CustomConfirmationDialog
        open={dialogOpen}
        title={dialogProps.title}
        message={dialogProps.message}
        onConfirm={dialogProps.onConfirm}
        onCancel={dialogProps.onCancel}
        confirmText={dialogProps.confirmText || "Yes"}
        cancelText={dialogProps.cancelText || "No"}
        confirmButtonColor={dialogProps.confirmButtonColor || "primary"}
        cancelButtonColor={dialogProps.cancelButtonColor || "primary"}
      >
        {dialogProps.data?.variable_data && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <TypographyAtom variant="h6">
                    Processing Mode:{" "}
                    <span
                      style={{
                        color:
                          dialogProps.data.processing_mode === "immediate"
                            ? "red"
                            : "green",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {dialogProps.data.processing_mode}
                    </span>
                  </TypographyAtom>
                  <Divider sx={{ my: 1 }} />
                  <TypographyAtom variant="h6">
                    Submission Type: {dialogProps.data.submission_type}
                  </TypographyAtom>
                  <Divider sx={{ my: 1 }} />
                  <TypographyAtom variant="h6">
                    Date Submitted:{" "}
                    {new Date(dialogProps.data.date_submitted).toLocaleString()}
                  </TypographyAtom>
                  <Divider sx={{ my: 1 }} />
                  <TypographyAtom variant="h6">
                    Priority: {dialogProps.data.priority}
                  </TypographyAtom>
                  <Divider sx={{ my: 1 }} />
                  <TypographyAtom variant="h6">
                    Number of SLCs: {dialogProps.data.number_of_slcs}
                  </TypographyAtom>
                  <Divider sx={{ my: 1 }} />
                  <TypographyAtom variant="h6">
                    Estimated Duration: {dialogProps.data.estimated_duration}
                  </TypographyAtom>
                  <Divider sx={{ my: 1 }} />
                  <TypographyAtom variant="h6">
                    Feedback: {dialogProps.data.feedback}
                  </TypographyAtom>
                  <Divider sx={{ my: 1 }} />
                  <TypographyAtom variant="h6">
                    Request Comment: {dialogProps.data.request_comment}
                  </TypographyAtom>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <MapView
                style={{ height: "400px", width: "100%" }}
                center={[0, 0]}
                zoom={13}
                minimapView={false}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />

                <Polygon
                  positions={dialogProps.data?.selection_area.map((coord) => [
                    coord[1],
                    coord[0],
                  ])}
                  pathOptions={{
                    color: "white", // White outline
                    weight: 2, // Adjust thickness of the outline
                    fillColor: "transparent", // No fill color
                    fillOpacity: 0, // Fully transparent fill
                  }}
                />

                {dialogProps.data.reference_frame_polygon &&
                  Array.isArray(dialogProps.data.reference_frame_polygon) &&
                  dialogProps.data.reference_frame_polygon.map(
                    (polygon, index) => (
                      <Polygon
                        key={index}
                        positions={polygon.map((coord) => [coord[1], coord[0]])}
                      />
                    )
                  )}
                <MapWithBounds
                  polygonBoxes={dialogProps.data.reference_frame_polygon}
                />
              </MapView>
            </Grid>
            <Grid item xs={12}>
              <SyntaxHighlighter
                language="json"
                style={materialOceanic}
                customStyle={{
                  backgroundColor: theme.palette.background.default,
                  padding: "16px",
                  borderRadius: "8px",
                  fontSize: "0.9rem",
                  overflowX: "auto",
                }}
              >
                {JSON.stringify(dialogProps.data.variable_data, null, 2)}
              </SyntaxHighlighter>
            </Grid>
          </Grid>
        )}
      </CustomConfirmationDialog>

      <Main
        open={open}
        style={{
          height: `calc(100dvh - ${appBarHeight}px)`,
          marginTop: `${appBarHeight}px`,
        }}
      >
        <MapView
          setSelectionArea={setSelectionArea}
          selectionArea={selectionArea}
          mapViewRef={mapViewRef}
          enableRectangleSelection={true}
        >
          {jsonData?.map((platformName, platformIndex) => {
            return (
              // platformName?.features?.map((item, jsonIndex) => {
              // return (
              <GeoJSON
                style={{
                  color: outline_color[platformIndex],
                  weight: 3,
                  fillOpacity: 0, // Make the fill transparent
                }}
                ref={(geoRef) => {
                  geoJsonRefs.current[platformIndex] = geoRef;
                }}
                onEachFeature={(feature, layer) => {
                  layer.options.id = feature.id;
                  layer.options.bbox = feature.bbox;
                  layer.options.feature = feature;
                  layerRefs.current.push(layer);
                  onEachClick(
                    feature,
                    layer,
                    decodeURIComponent(escape(feature?.id)),
                    // decodeURIComponent(escape(item?.properties))
                    JSON.stringify(feature?.properties, null, 2)
                  );
                }}
                key={platformIndex}
                data={platformName}
              />
              //   );
              // });
            );
          })}
        </MapView>

        <SlidingTable
          jsonData={jsonData}
          selected={selected}
          setSelected={setSelected}
          currentHighlight={currentHighlight}
          handleFeatureHighlight={handleFeatureHighlight}
        />
      </Main>
    </Box>
  );
};

export default SarfinderPage;
