import CloseIcon from "@mui/icons-material/Close";
import ButtonAtom from "components/ButtonAtom";
import useScreenSize from "hooks/useScreenSize";
import { useState } from "react";
const {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  Box,
} = require("@mui/material");
const { default: TypographyAtom } = require("components/TypographyAtom");
const { Link } = require("react-router-dom");

const DisclaimerPopupOrganism = ({ drawerIsOpen, drawerWidth }) => {
  const [open, setOpen] = useState(false);
  const [closeWarning, setCloseWarning] = useState(false);
  const { isDesktopView } = useScreenSize();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseWarning = () => {
    setCloseWarning(true);
  };

  return (
    <>
      <Box
        sx={{
          visibility: closeWarning ? "hidden" : "none",
          position: "absolute",
          bottom: "5%",
          left: drawerIsOpen ? `calc( 50% + ${drawerWidth}px / 2)` : "50%",
          zIndex: 1000,
          backgroundColor: "#32323288",
          transform: "translate(-50%, -50%)",
          borderRadius: "5px",
          padding: "1rem",
          backdropFilter: "blur(8px)",
          display: "flex",
          width: isDesktopView ? "none" : "70dvw",
        }}
      >
        <TypographyAtom>
          This map is provided for general information purpose only.
          <br />
          By using EOS-RS maps, you agree to our{" "}
          <Link
            variant="contained"
            color="primary"
            onClick={handleOpen}
            sx={{ fontWeight: 800, cursor: "pointer" }}
          >
            terms of use
          </Link>
        </TypographyAtom>
        <IconButton onClick={handleCloseWarning} sx={{ height: "fit-content" }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 10000 }}
        color=""
        PaperProps={{
          style: {
            // backgroundColor: "#e80c1a66",
            borderRadius: "5px",
            padding: "1rem",
            backdropFilter: "blur(8px)",
          },
        }}
      >
        <DialogTitle color={"primary"}>Legal Disclaimer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            While EOS-RS has made reasonable efforts to ensure the accuracy of
            the information presented, EOS-RS makes no representations or
            warranties of any kind, express or implied, about the completeness,
            accuracy, reliability, suitability, or availability with respect to
            the map or the information, products, services, or related graphics
            contained on the map for any purpose. Any reliance you place on such
            information is therefore strictly at your own risk.
            <br />
            In no event will EOS-RS be liable for any profits, loss of goodwill,
            loss of use, loss of production or business interruption costs, or
            any type of indirect, special, consequential, or incidental damages
            arising in connection with the use of this map.
            <br />
            Please use this map responsibly and consult official sources for any
            decision-making. If you have any questions or concerns about the map
            or its content, please contact us at{" "}
            <Link
              sx={{ color: "primary", cursor: "pointer" }}
              href="mailto:eos-rs@ntu.edu.sg"
            >
              eos-rs@ntu.edu.sg
            </Link>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonAtom onClick={handleClose} color="primary" variant="outlined">
            Close
          </ButtonAtom>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DisclaimerPopupOrganism;
