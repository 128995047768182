import { useEffect, useRef, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  CircularProgress,
  Fade,
  Grow,
  Slide,
  Snackbar,
  AppBar,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardMedia,
  CardContent,
  Card,
  Grid,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import AppHeader from "features/AppHeader/AppHeader";
import { useUser } from "provider/auth/useUser";
import { Link, useNavigate } from "react-router-dom";
import { getRoute } from "utils/routes";
import { CSRF_COOKIE_NAME, SARFINDER_BE_API_URL } from "utils/constants";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import { formatDetails } from "utils/helper";
import { useCallAPI } from "utils/helper";
import EventDetailCard from "features/EventCard/EventDetailCard";
import TypographyAtom from "components/TypographyAtom";

const LinkedInCaptionPage = () => {
  const userQuery = useUser();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [eventDetails, setEventDetails] = useState({});
  // const [productCaption, setProductCaption] = useState("");
  const [additionalContext, setAdditionalContext] = useState("");
  const [additionalPrompt, setAdditionalPrompt] = useState("");
  // const [productLink, setProductLink] = useState("");
  const [result, setResult] = useState("");
  const [copyFeedback, setCopyFeedback] = useState(false);

  const generateLinkedinCaption = useCallAPI(
    `generateLinkedInCaption`,
    `/linkedin/caption/`,
    "POST"
  );

  const fetchEvents = useCallAPI(`fetchEvents`, `/linkedin/responses/`, "GET");

  const createPost = useCallAPI(`createPost`, `/linkedin/posts/`, "POST");

  const fetchEventDetail = useCallAPI(
    `fetchEventDetail`,
    `/linkedin/response-details/?event_title=${selectedEvent}`,
    "GET"
  );

  useEffect(() => {
    if (
      !userQuery.isPending &&
      !userQuery.data?.username &&
      !(userQuery.data?.is_staff || userQuery.data?.is_superuser)
    ) {
      navigate(getRoute("home"));
    }
  }, [userQuery, navigate]);

  useEffect(() => {
    fetchEvents.mutate(null, {
      onSuccess: (data) => {
        console.log(data?.products);
        setEvents(data?.products); // Assuming the events list is in `data.events`
      },
      onError: (error) => {
        enqueueSnackbar("Failed to fetch events", { variant: "error" });
      },
    });
  }, []);

  const handleEventSelect = (event) => {
    const eventTitle = event.target.value;
    setSelectedEvent(eventTitle);

    fetchEventDetail.mutate(null, {
      onSuccess: (data) => {
        // Handle the fetched event detail (e.g., display it in the UI)
        console.log(data);
        setEventDetails(data);
      },
      onError: (error) => {
        enqueueSnackbar("Failed to fetch event details", { variant: "error" });
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setResult("");

    generateLinkedinCaption.mutate(
      {
        product_caption: eventDetails.product_caption,
        context: additionalContext,
        additional_prompt: additionalPrompt,
        product_link: `https://products.earthobservatory.sg/#/leaflet/${eventDetails.event_title}`,
      },
      {
        onSuccess: (success) => {
          console.log(success);
          setResult(success.linkedin_caption);
          enqueueSnackbar("LinkedIn caption generated successfully!", {
            variant: "success",
          });
        },
      },
      {
        onError: (error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        },
      }
    );
  };

  const handleCreatePost = () => {
    createPost.mutate(
      {
        event_title: selectedEvent,
        color_type: "NORMAL",
        caption: result,
        user: userQuery.data?.id,
        additional_prompt: additionalPrompt,
        additional_context: additionalContext,
      },
      {
        onSuccess: (success) => {
          console.log(success);
          setResult(success.linkedin_caption);
          enqueueSnackbar(
            "LinkedIn caption submitted for approval successfully! Please wait for admin to approve.",
            {
              variant: "success",
            }
          );
        },
      },
      {
        onError: (error) => {
          console.log(error);
          if (error.message.startsWith("Bad Request")) {
            setResult("Error: Invalid input provided");
            enqueueSnackbar("Error: Invalid input provided", {
              variant: "error",
            });
          } else {
            setResult("Error generating caption");
            enqueueSnackbar("Error generating caption", {
              variant: "error",
            });
          }
        },
      }
    );
  };

  // const handleSubmitApproval = () => {
  //   submitApproval.mutate(
  //     {
  //       event_title: "TEST",
  //       color_type: "normal",
  //       caption: productCaption,
  //       user: userQuery.data?.username,
  //     },
  //     {
  //       onSuccess: (success) => {
  //         console.log(success);
  //         setResult(success.linkedin_caption);
  //         enqueueSnackbar("LinkedIn caption generated successfully!", {
  //           variant: "success",
  //         });
  //       },
  //     },
  //     {
  //       onError: (error) => {
  //         console.log(error);
  //         if (error.message.startsWith("Bad Request")) {
  //           setResult("Error: Invalid input provided");
  //           enqueueSnackbar("Error: Invalid input provided", {
  //             variant: "error",
  //           });
  //         } else {
  //           setResult("Error generating caption");
  //           enqueueSnackbar("Error generating caption", {
  //             variant: "error",
  //           });
  //         }
  //       },
  //     }
  //   );
  // };

  const handleCopy = () => {
    navigator.clipboard.writeText(result);
    setCopyFeedback(true);
    setTimeout(() => setCopyFeedback(false), 2000); // Show feedback for 2 seconds
    enqueueSnackbar("Copied to clipboard!", { variant: "info" });
  };

  return (
    <Container maxWidth="lg" sx={{ paddingY: `5rem`, overflowY: "auto" }}>
      <AppHeader />
      <Box sx={{ mt: 5 }}>
        <TypographyAtom variant="h6">Select Event:</TypographyAtom>
        <FormControl fullWidth>
          <InputLabel id="event-select-label">Event</InputLabel>
          <Select
            labelId="event-select-label"
            id="event-select"
            value={selectedEvent}
            label="Event"
            onChange={handleEventSelect}
          >
            {events?.map((event) => (
              <MenuItem key={event} value={event}>
                {event}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {selectedEvent && <EventDetailCard event={eventDetails} />}

      <Box sx={{ mt: 2 }}>
        <TypographyAtom variant="h4" gutterBottom>
          Generate LinkedIn Caption
        </TypographyAtom>
        <form onSubmit={handleSubmit}>
          {/* <TextField
            fullWidth
            label="Product Caption"
            value={productCaption}
            onChange={(e) => setProductCaption(e.target.value)}
            margin="normal"
            required
            multiline
            minRows={1} // minimum number of rows
            maxRows={10} // maximum number of rows
            disabled={generateLinkedinCaption.isPending}
          /> */}
          <TextField
            fullWidth
            label="Context"
            value={additionalContext}
            onChange={(e) => setAdditionalContext(e.target.value)}
            margin="normal"
            required
            multiline
            minRows={2} // minimum number of rows
            maxRows={10} // maximum number of rows
            disabled={generateLinkedinCaption.isPending}
          />
          <TextField
            fullWidth
            label="Additional Prompt"
            value={additionalPrompt}
            onChange={(e) => setAdditionalPrompt(e.target.value)}
            margin="normal"
            minRows={2}
            maxRows={10}
            disabled={generateLinkedinCaption.isPending}
          />

          {/* <TextField
            fullWidth
            label="Product Link"
            value={productLink}
            onChange={(e) => setProductLink(e.target.value)}
            margin="normal"
            required
            disabled={generateLinkedinCaption.isPending}
          /> */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3 }}
            disabled={generateLinkedinCaption.isPending}
          >
            {generateLinkedinCaption.isPending ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              "Generate LinkedIn Caption"
            )}
          </Button>
        </form>

        {result && (
          <Grow in={true}>
            <Box sx={{ mt: 4 }}>
              <TypographyAtom variant="h6">Generated Caption:</TypographyAtom>
              <TextField
                fullWidth
                multiline
                minRows={1} // minimum number of rows
                maxRows={10} // maximum number of rows
                value={result}
                onChange={(e) => setResult(e.target.value)}
                // InputProps={{
                //   readOnly: false,
                // }}
                variant="outlined"
              />
              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 2 }}
                onClick={handleCopy}
              >
                {copyFeedback ? "Copied!" : "Copy to Clipboard"}
              </Button>
            </Box>
          </Grow>
        )}

        <Stack direction={"row"} gap={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            fullWidth
            onClick={handleCreatePost}
            disabled={generateLinkedinCaption.isPending}
          >
            Save
          </Button>
          {result && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
              fullWidth
              disabled={generateLinkedinCaption.isPending}
              onClick={handleCreatePost}
            >
              Submit for Approval
            </Button>
          )}
        </Stack>
      </Box>
    </Container>
  );
};

export default LinkedInCaptionPage;
