import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Box, Typography, Grid, TextField } from "@mui/material";
import AppHeader from "features/AppHeader/AppHeader";
import { useNavigate } from "react-router-dom";
import { getRoute } from "utils/routes";
import { useUser } from "provider/auth/useUser";
import Cookies from "js-cookie";
import { CSRF_COOKIE_NAME, SARFINDER_BE_API_URL } from "utils/constants";
import TypographyAtom from "components/TypographyAtom";

export default function PasswordResetRequest() {
  const userQuery = useUser();
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!userQuery.isLoading && userQuery.data?.username) {
      navigate(getRoute("home"));
    }
  }, [userQuery, navigate]);

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await fetch(
      `${SARFINDER_BE_API_URL}/accounts/password_reset/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get(CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(formData),
        credentials: "include",
      }
    );

    const data = await response.json();

    if (!response.ok) {
      if (data.email) {
        setErrorMessage(data.email[0]);
      } else {
        console.error(data);
      }
    } else {
      setIsSubmitted(true);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <AppHeader />

      <Box sx={{ flexGrow: 1, display: "flex" }}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: 3,
            }}
          >
            {isSubmitted ? (
              <Box sx={{ maxWidth: 900, margin: "auto" }}>
                <TypographyAtom variant="h4" gutterBottom textAlign={"center"}>
                  Check your email
                </TypographyAtom>

                <TypographyAtom variant="h6" gutterBottom textAlign={"center"}>
                  We have sent you an email with a one-time link to reset your
                  password. Please check your inbox and follow the instructions
                  in the email to reset your password.
                </TypographyAtom>
              </Box>
            ) : (
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ maxWidth: 900, margin: "auto" }}
              >
                <TypographyAtom variant="h4" gutterBottom textAlign={"center"}>
                  Reset your password
                </TypographyAtom>

                <TypographyAtom variant="h6" gutterBottom textAlign={"justify"}>
                  If your email is registered within our system, we will be
                  sending you an email with a one-time link to reset your
                  password.{" "}
                  <strong>
                    Please do not share that link with anyone else
                  </strong>
                </TypographyAtom>

                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  required
                  error={!!errorMessage}
                  helperText={errorMessage}
                  FormHelperTextProps={{
                    sx: { minHeight: "1.5em" },
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Send password reset email
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
