import {
  ViewModule as CardIcon,
  ImageTwoTone,
  List as ListIcon,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CustomFooter from "components/CustomFooter";
import MultipleSelectChip from "components/MultiSelect";
import TypographyAtom from "components/TypographyAtom";
import { useMetadataContext } from "context/MetadataContext";
import AppHeader from "features/AppHeader/AppHeader";
import EventCard from "features/EventCard/EventCard";
import EventList from "features/EventCard/EventList";
import MapView from "features/MapPage/Map/MapView";
import L from "leaflet";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Marker, Polyline, Popup } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import { HEADER_HEIGHT } from "utils/constants";
import {
  capitalizeEachWord,
  convertCoordinateAntiMeridian,
} from "utils/helper";
import { getDynamicRoute } from "utils/routes";

import SatelliteAnimation from "components/Animations/SatelliteAnimation";
import ButtonAtom from "components/ButtonAtom";
import {
  getEventIcon,
  getTypeIcon,
} from "components/EventProductIcons/EventProductIcons";
import * as d3 from "d3-force";
import * as d3geo from "d3-geo";
// import "react-leaflet-markercluster/styles";

const itemsPerPage = 8;

// const typeIcons = {
//   explosion: L.divIcon({
//     html: `<div class="circle-icon"><img src="${WildfireIcon}" alt="Explosion" /></div>`,
//     iconSize: [48, 48],
//     className: "custom-icon",
//   }),
//   wildfire: L.divIcon({
//     html: `<div class="circle-icon"><img src="${WildfireIcon}" alt="Explosion" /></div>`,
//     iconSize: [48, 48],
//     className: "custom-icon",
//   }),
//   flood: L.divIcon({
//     html: `<div class="circle-icon"><img src="${FloodIcon}" alt="Flood" /></div>`,
//     iconSize: [48, 48],
//     className: "custom-icon",
//   }),
//   cyclone: L.divIcon({
//     html: `<div class="circle-icon"><img src="${CycloneIcon}" alt="Cyclone" /></div>`,
//     iconSize: [48, 48],
//     className: "custom-icon",
//   }),
//   earthquake: L.divIcon({
//     html: `<div class="circle-icon"><img src="${EarthquakeIcon}" alt="Earthquake" /></div>`,
//     iconSize: [48, 48],
//     className: "custom-icon",
//   }),
//   typhoon: L.divIcon({
//     html: `<div class="circle-icon"><img src="${CycloneIcon}" alt="Typhoon" /></div>`,
//     iconSize: [48, 48],
//     className: "custom-icon",
//   }),
//   landslide: L.divIcon({
//     html: `<div class="circle-icon"><img src="${LandslideIcon}" alt="Landslide" /></div>`,
//     iconSize: [48, 48],
//     className: "custom-icon",
//   }),
//   volcano: L.divIcon({
//     html: `<div class="circle-icon"><img src="${VolcanoIcon}" alt="Volcano" /></div>`,
//     iconSize: [48, 48],
//     className: "custom-icon",
//   }),
//   default: L.divIcon({
//     html: `<div class="circle-icon"><img src="${DefaultIcon}" alt="Default" /></div>`,
//     iconSize: [48, 48],
//     className: "custom-icon",
//   }),
// };

function forceRectCollide() {
  let nodes;
  let strength = 1;
  function force(alpha) {
    for (let i = 0; i < nodes.length; i++) {
      const a = nodes[i];
      const halfWidthA = a.width / 2;
      const halfHeightA = a.height / 2;
      for (let j = i + 1; j < nodes.length; j++) {
        const b = nodes[j];
        const halfWidthB = b.width / 2;
        const halfHeightB = b.height / 2;
        const dx = b.x - a.x;
        const dy = b.y - a.y;
        const overlapX = halfWidthA + halfWidthB - Math.abs(dx);
        const overlapY = halfHeightA + halfHeightB - Math.abs(dy);
        if (overlapX > 0 && overlapY > 0) {
          // Push apart along the axis with the smaller overlap.
          if (overlapX < overlapY) {
            const sign = dx < 0 ? -1 : 1;
            const adjust = overlapX * sign * strength * alpha;
            a.x -= adjust;
            b.x += adjust;
          } else {
            const sign = dy < 0 ? -1 : 1;
            const adjust = overlapY * sign * strength * alpha;
            a.y -= adjust;
            b.y += adjust;
          }
        }
      }
    }
  }
  force.initialize = function (_nodes) {
    nodes = _nodes;
  };
  force.strength = function (_) {
    if (!arguments.length) return strength;
    strength = +_;
    return force;
  };
  return force;
}

// Custom force to ensure each label’s x-position is not less than its marker’s x plus a fixed offset.
function forceConstrainX(offset = 0) {
  let nodes;
  function force(alpha) {
    nodes.forEach((d) => {
      const desired = d.lon + offset;
      if (d.x < desired) {
        d.x += (desired - d.x) * alpha;
      }
    });
  }
  force.initialize = function (_nodes) {
    nodes = _nodes;
  };
  return force;
}

const EventMarkers = ({ events }) => {
  const navigate = useNavigate();

  // Define our projection.
  const mapWidth = 800;
  const mapHeight = 600;
  const projection = d3geo
    .geoMercator()
    .scale(150)
    .translate([mapWidth / 2, mapHeight / 2]);

  // Build markers and run simulation.
  const { markers, labelSim } = useMemo(() => {
    if (!events || events.length === 0) {
      return { markers: [], labelSim: { nodes: () => [] } };
    }

    // Compute fixed marker positions.
    const markers = events
      .map((event, index) => {
        if (!event?.event_bbox || event.event_bbox.length < 2) return null;
        const [lat1, lon1] = event.event_bbox[0] || [];
        const [lat2, lon2] = event.event_bbox[1] || [];
        if (
          lat1 === undefined ||
          lon1 === undefined ||
          lat2 === undefined ||
          lon2 === undefined
        )
          return null;
        const lat = (lat1 + lat2) / 2;
        const lon = (lon1 + lon2) / 2;
        if (isNaN(lat) || isNaN(lon)) return null;
        event.event_display_name = event.event_display_name
          .replace(/EOS-RS/g, "")
          .replace(/EOS ARIA-SG/g, "")
          .trim();
        const [px, py] = projection([lon, lat]);
        return { lat, lon, px, py, event, index };
      })
      .filter(Boolean);

    // Create label nodes in pixel space.
    const labels = markers.map((d) => {
      const textLength = d.event.event_display_name.length;
      const width = textLength * 4 + 32;
      const height = 32;
      return { x: d.px, y: d.py, width, height, ...d };
    });

    // For each label, define a link from its fixed marker to the label.
    const links = markers.map((marker, i) => ({
      source: { x: marker.px, y: marker.py },
      target: labels[i],
    }));

    const maxClusterDistance = 80;

    const minDistance = 20; // Minimum distance for repelling
    const maxDistance = 100; // Maximum distance for attraction
    const repulsionStrength = 3;
    const attractionStrength = 2; // Attraction force should be small

    // Create the simulation.
    const sim = d3
      .forceSimulation(labels)
      .force("markerRepel", (alpha) => {
        labels.forEach((label) => {
          markers.forEach((marker) => {
            if (label.index === marker.index) return;

            // Calculate the distance between the label and the marker
            const dx = label.x - marker.px;
            const dy = label.y - marker.py;
            const distance = Math.sqrt(dx * dx + dy * dy);

            // Apply repulsion if too close (if within the max cluster distance)
            const minDistance =
              distance < maxClusterDistance ? maxClusterDistance : 0;

            if (distance < minDistance && distance > 0) {
              const repulsionStrength = 3; // Adjust as needed
              const forceVal =
                (repulsionStrength * (minDistance - distance)) / distance;
              label.vx += (dx / distance) * forceVal * alpha;
              label.vy += (dy / distance) * forceVal * alpha;
            }
          });
        });
      })
      // .force("labelRepel", (alpha) => {
      //   labels.forEach((labelA, i) => {
      //     labels.forEach((labelB, j) => {
      //       if (i === j) return; // Skip self comparison

      //       const dx = labelA.x - labelB.x;
      //       const dy = labelA.y - labelB.y;
      //       const distance = Math.sqrt(dx * dx + dy * dy);

      //       if (distance > 0) {
      //         let forceVal = 0;

      //         if (distance < minDistance) {
      //           // **Repulsion when too close**
      //           forceVal =
      //             (repulsionStrength * (minDistance - distance)) / distance;
      //         }
      //         if (distance > maxDistance) {
      //           // **Attraction when too far**
      //           forceVal =
      //             -(attractionStrength * (distance - maxDistance)) / distance;
      //         }

      //         // Apply forces
      //         labelA.vx += (dx / distance) * forceVal * alpha;
      //         labelA.vy += (dy / distance) * forceVal * alpha;
      //       }
      //     });
      //   });
      // })
      // .force("link", d3.forceLink(links).distance(1).strength(2))
      .force("charge", d3.forceManyBody().strength(-5))
      .force("x", d3.forceX((d) => d.px).strength(1.8))
      .force("y", d3.forceY((d) => d.py).strength(3.0))
      .force("rectCollide", forceRectCollide().strength(5))
      .stop();

    // Run the simulation.
    for (let i = 0; i < 500; i++) sim.tick();

    return { markers, labelSim: sim };
  }, [events, projection]);

  if (!markers || markers.length === 0) return null;

  // Helper: decide text alignment based on relative positions.
  const calculateAlignment = (markerPx, labelPx) => {
    return labelPx < markerPx ? "rtl" : "ltr";
  };

  return (
    <>
      {markers.map(({ lat, lon, event, px, py, index }) => {
        const mainEventType =
          event.event_type_tags?.find(
            (tag) =>
              !tag.includes("flood proxy map") &&
              !tag.includes("damage proxy map")
          ) || "default";

        const eventIcon = getEventIcon(mainEventType, index + 1);
        const label = labelSim.nodes()[index];
        const [labelLon, labelLat] = projection.invert([label.x, label.y]);

        return (
          <React.Fragment key={index}>
            {/* Main Event Marker */}
            <Marker
              icon={eventIcon}
              position={convertCoordinateAntiMeridian([lat, lon])}
              title={event.event_display_name}
              description={event.event_type_tags?.join(", ")}
            >
              <Popup>
                <div
                  style={{
                    maxWidth: "250px",
                    backgroundColor: "#f9f9f9",
                    borderRadius: "8px",
                  }}
                >
                  <h3
                    style={{
                      fontSize: "18px",
                      color: "#3f51b5",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {event.event_display_name}
                  </h3>
                  <p style={{ fontSize: "14px", textAlign: "center" }}>
                    <strong style={{ color: "#3f51b5" }}>Event Number:</strong>{" "}
                    {index + 1}
                  </p>
                  <p style={{ fontSize: "14px", textAlign: "center" }}>
                    <strong style={{ color: "#3f51b5" }}>Event Types:</strong>{" "}
                    {event.event_type_tags?.join(", ")}
                  </p>
                  <p style={{ fontSize: "14px", textAlign: "center" }}>
                    <strong style={{ color: "#3f51b5" }}>Location:</strong>{" "}
                    {event.event_location_tags?.join(", ")}
                  </p>
                  <p style={{ fontSize: "14px", textAlign: "center" }}>
                    <strong style={{ color: "#3f51b5" }}>End Date:</strong>{" "}
                    {new Date(event.event_end).toLocaleDateString()}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <ButtonAtom
                      variant="contained"
                      color="primary"
                      style={{ width: "150px", borderRadius: "25px" }}
                      onClick={() =>
                        navigate(
                          getDynamicRoute("eventproduct", event.event_name)
                        )
                      }
                    >
                      View Event
                    </ButtonAtom>
                  </div>
                </div>
              </Popup>
            </Marker>

            {/* Label Marker */}
            <Marker
              position={convertCoordinateAntiMeridian([labelLat, labelLon])}
              icon={L.divIcon({
                className: "event-label",
                html: `<div style="padding:6px 12px; border-radius:6px; box-shadow:0 2px 5px rgba(0,0,0,0.2); font-size:7px; font-weight:bold; white-space:nowrap; color:#fff; direction:${calculateAlignment(
                  px,
                  label.x
                )};">${event?.event_display_name}</div>`,
              })}
            />

            {/* Connecting Polyline */}
            <Polyline
              positions={[
                convertCoordinateAntiMeridian([lat, lon]),
                convertCoordinateAntiMeridian([labelLat - 0.8, labelLon]),
              ]}
              color="#aaaaaa"
              weight={1}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

const HorizontalEventList = ({ events }) => {
  const Navigate = useNavigate();

  if (!events || events.length === 0) return;

  return (
    <div
      style={{
        display: "flex", // Use flex to align items horizontally
        overflowX: "auto", // Enable horizontal scrolling
        padding: "10px",
        width: "100%",
        gap: "20px", // Space between event cards
      }}
    >
      {events.map((event, index) => {
        const mainEventType =
          event.event_type_tags?.find(
            (tag) =>
              !tag.includes("flood proxy map") &&
              !tag.includes("damage proxy map")
          ) || "default";

        // Check if the event has a valid bbox
        if (!event?.event_bbox || event.event_bbox.length < 2) return null;

        const [lat1, lon1] = event.event_bbox[0] || [];
        const [lat2, lon2] = event.event_bbox[1] || [];
        const lat = (lat1 + lat2) / 2;
        const lon = (lon1 + lon2) / 2;

        if (isNaN(lat) || isNaN(lon)) return null;

        return (
          <div
            key={index}
            style={{
              backgroundColor: "#f9f9f9",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              padding: "10px",
              display: "flex",
              flexDirection: "column", // Arrange items vertically
              alignItems: "center", // Center the content horizontally
              justifyContent: "flex-start", // Align items to the top of the card
              minWidth: "200px", // Minimum width for each card
              height: "auto", // Allow height to be determined by content
            }}
          >
            <div
              style={{
                position: "relative", // Positioning icon relative to the card
                top: "10px", // Space from the top of the card
                left: "50%", // Center horizontally
                transform: "translateX(-50%)", // Adjust the position to exactly center the icon
                width: "40px",
                height: "40px",
                marginBottom: "10px", // Space between icon and content below
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#3f51b5",
                color: "#fff",
                borderRadius: "50%",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              {index + 1} {/* Display event number */}
            </div>
            <div
              style={{
                textAlign: "center",
                marginTop: "10px", // Offset content to start below the icon
                flexGrow: 1, // Make the content area grow to push the button to the bottom
              }}
            >
              <img
                src={getTypeIcon(mainEventType)}
                alt={mainEventType}
                style={{ width: "60px", height: "60px" }}
              />
              <h3
                style={{
                  fontSize: "16px",
                  color: "#3f51b5",
                  fontWeight: "bold",
                  margin: "5px 0",
                }}
              >
                {event.event_display_name}
              </h3>
              <p
                style={{
                  fontSize: "12px",
                  color: "#555",
                  margin: "5px 0",
                }}
              >
                {event.event_type_tags?.join(", ")}
              </p>
            </div>
            <ButtonAtom
              variant="contained"
              color="primary"
              style={{
                width: "100%",
                backgroundColor: "#3f51b5",
                fontWeight: "bold",
                borderRadius: "25px",
                marginTop: "auto", // Push the button to the bottom of the card
              }}
              onClick={() =>
                Navigate(getDynamicRoute("eventproduct", event.event_name))
              }
            >
              View Event
            </ButtonAtom>
          </div>
        );
      })}
    </div>
  );
};

const ProductsPage = () => {
  const metadata = useMetadataContext();
  const Navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [startDate, setStartDate] = useState(moment().subtract(6, "months"));
  const [endDate, setEndDate] = useState(moment());
  const [filter, setFilter] = useState([]);
  const [greyscaleFilter, setGreyscaleFilter] = useState("all");

  const [isScrolled, setIsScrolled] = useState(false);
  const [viewMode, setViewMode] = useState("card"); // New state for view mode

  const [page, setPage] = useState(1);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleViewModeChange = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) {
      setGreyscaleFilter(newFilter);
    }
  };

  const filteredMetadata = metadata
    ?.sort((a, b) => moment(b.event_start) - moment(a.event_start))
    ?.filter((item) => {
      const matchesQuery = item.product_list[0].prod_desc
        .toLowerCase()
        .includes(query.toLowerCase());

      const matchesEventType =
        filter.length == 0 ||
        item.event_type_tags.some((r) => {
          return filter.map((i) => i.toLowerCase()).includes(r);
        });

      const matchesGreyscaleFilter =
        greyscaleFilter !== "greyscale" || item.hasGreyscale === true;

      return matchesQuery && matchesEventType && matchesGreyscaleFilter;
    });

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredMetadata?.slice(startIndex, endIndex);

  useEffect(() => {
    setPage(1);
  }, [filteredMetadata?.length]);

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const mostRecentMetadata = metadata?.filter((item) => {
    const itemDate = moment(item.event_start);
    return itemDate.isAfter(startDate) && itemDate.isBefore(endDate);
  });

  const MapEventCards = (inputData) => {
    return inputData?.map((item) => {
      var latestProduct = item.product_list.find((data) => {
        return data.isLatest == true;
      });

      // console.log(item);
      return (
        <EventCard
          Title={item.event_display_name}
          Image={latestProduct.prod_main_png}
          Description={latestProduct.prod_desc}
          // Date={`${item.event_start} | ${item.event_end}`}
          EventDate={item.event_start}
          LastUpdated={latestProduct.prod_date}
          HasGreyscale={item.hasGreyscale}
          Tags={item.event_type_tags.map((str) => {
            return capitalizeEachWord(str);
          })}
          key={item.event_name}
          onClick={() => {
            Navigate(getDynamicRoute("eventproduct", item.event_name));
          }}
        />
      );
    });
  };

  const MapEventLists = (inputData) => {
    return inputData?.map((item) => {
      var latestProduct = item.product_list.find((data) => {
        return data.isLatest == true;
      });

      // console.log(item);
      return (
        <EventList
          Title={item.event_display_name}
          Image={latestProduct.prod_main_png}
          Description={latestProduct.prod_desc}
          // Date={`${item.event_start} | ${item.event_end}`}
          EventDate={item.event_start}
          LastUpdated={latestProduct.prod_date}
          HasGreyscale={item.hasGreyscale}
          ProductList={item.product_list}
          Tags={item.event_type_tags.map((str) => {
            return capitalizeEachWord(str);
          })}
          key={item.event_name}
          onClick={() => {
            Navigate(getDynamicRoute("eventproduct", item.event_name));
          }}
        />
      );
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      // If scrollTop is at the top, make the header transparent
      setIsScrolled(scrollTop !== 0);
    };

    // Add scroll event listener when component mounts
    window.addEventListener("scroll", handleScroll);

    // Cleanup the scroll event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array means this effect runs only once after the component mounts

  const scrollToEvents = () => {
    let events = document.getElementById("events").getBoundingClientRect();
    // e.preventDefault(); // Stop Page Reloading
    events &&
      window.scrollTo({
        behavior: "smooth",
        top:
          events.top -
          document.body.getBoundingClientRect().top -
          parseInt(HEADER_HEIGHT, 10),
      });
  };

  return (
    <Box>
      <AppHeader isScrolled={isScrolled} />

      <Box
        sx={{
          display: "flex",
          flexDirection: " column",
          flexGrow: 1,
          padding: "2rem",
          gap: "3rem",
          paddingTop: HEADER_HEIGHT,
        }}
      >
        <Stack sx={{ minHeight: "70vh" }} gap={4}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MapView
              style={{ height: "60dvh", width: "100%" }}
              scrollWheelZoom={false}
              worldCopyJump={false}
              center={[0, 180]}
              minimapView={false}
            >
              <EventMarkers events={metadata} />

              <SatelliteAnimation
                enableOffset
                tleArray={[
                  {
                    line1:
                      "1 39634U 14016A   25073.18478893  .00000538  00000-0  12391-3 0  9997",
                    line2:
                      "2 39634  98.1826  82.3757 0001342  90.4459 269.6895 14.59199223582964",
                  },
                ]}
              />
            </MapView>
            <HorizontalEventList events={metadata} />
          </Grid>
          <Grid
            container
            spacing={6}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              md={3}
              sx={{ display: "flex", flexDirection: "column" }}
              id={"events"}
            >
              <TypographyAtom variant="h3" fontWeight="800">
                All events
              </TypographyAtom>
              <TypographyAtom variant="h6">Click to view</TypographyAtom>
            </Grid>

            <Grid
              item
              xs={6}
              md={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={handleViewModeChange}
                aria-label="view mode"
              >
                <ToggleButton value="card" aria-label="card view">
                  <CardIcon />
                </ToggleButton>
                <ToggleButton value="list" aria-label="list view">
                  <ListIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid
              item
              xs={6}
              md={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ToggleButtonGroup
                value={greyscaleFilter}
                exclusive
                onChange={handleFilterChange}
                aria-label="grayscale filter mode"
              >
                <ToggleButton value="all" aria-label="all items">
                  <TypographyAtom variant="body2">All</TypographyAtom>
                </ToggleButton>
                <ToggleButton value="greyscale" aria-label="greyscale items">
                  <ImageTwoTone />
                  <TypographyAtom variant="body2">Greyscale</TypographyAtom>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <MultipleSelectChip itemName={filter} setItemName={setFilter} />
            </Grid>

            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                label="Search"
                variant="outlined"
                fullWidth
                // size="small"
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),

                  style: { borderRadius: "20px" },
                }}
              />
            </Grid>
          </Grid>
          <Grid sx={{ padding: "1rem" }} container spacing={{ xs: 2, md: 3 }}>
            {/* Render events based on view mode */}
            {viewMode === "card" ? (
              <Grid container spacing={2}>
                {MapEventCards(paginatedData)}
              </Grid>
            ) : (
              MapEventLists(paginatedData)
            )}
          </Grid>
          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <Pagination
              // variant="outlined"
              color="primary"
              count={Math.ceil(filteredMetadata?.length / itemsPerPage)}
              page={page}
              onChange={handlePageChange}
              size="large"
            />
          </Box>
        </Stack>
      </Box>
      <CustomFooter />
    </Box>
  );
};

export default ProductsPage;
