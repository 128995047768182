import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
  Fade,
  Slide,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import Joyride from "react-joyride"; // Make sure to install react-joyride
import INSAR_DIAGRAM from "assets/WelcomeImages/inSAR_diagram.png";
import DPM_SAMPLE from "assets/WelcomeImages/DPM_Sample.png";
import TypographyAtom from "components/TypographyAtom";

const WelcomeUserOrganism = ({
  openWelcome,
  onClose,
  steps = [
    // {
    //   title: "Complete Your Profile",
    //   description: "Fill in your personal details and set your preferences.",
    //   graphic: (
    //     <img
    //       src={INSAR_DIAGRAM}
    //       alt="Complete Your Profile"
    //       style={{ maxWidth: "100%", height: "auto" }}
    //     />
    //   ),
    //   // icon: <YourProfileIcon />
    // },
    {
      title: "Welcome to Sarfinder",
      description:
        "The all-in-one platform for creating and producing maps based on InSAR",
      // icon: <DashboardIcon />
    },
    {
      title: "What is InSAR?",
      description: `
        InSAR (Interferometric Synthetic Aperture Radar) is a remote sensing technique that uses radar signals to measure minute changes in the Earth's surface. It works in all weather conditions, day or night, making it ideal for detecting ground deformations caused by earthquakes, volcanic activities, landslides, or subsidence.
        
        **How It Works:**  
        1. A radar sensor emits microwave signals toward the ground and records the reflected signals.  
        2. The sensor revisits the same area at different times, capturing multiple datasets.  
        3. An interferogram is generated by comparing the phase information from the different passes.  
        4. The phase differences reveal subtle shifts in the Earth's surface, which are processed into detailed displacement maps.
      `,
      graphic: (
        <img
          src={INSAR_DIAGRAM}
          alt="What is InSAR?"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      ),
    },
    {
      title: "DPM & Our Products",
      description: `
        What is a DPM?  
        A Damage Proxy Map (DPM) visualizes the spatial distribution of surface displacement derived from InSAR data. It is essential for identifying ground movement and assessing geological hazards or structural changes.
        
        What We Make:
        At EOS Remote Sensing, we combine InSAR to create advanced monitoring solutions. Our products include damage proxy maps and flood proxy maps, integrated remote sensing tools, and custom analytical systems that support decision makers in hazard assessment and environmental management.
      `,
      graphic: (
        <img
          src={DPM_SAMPLE}
          alt="DPM & Our Products"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      ),
    },
    {
      title: "Go through a brief tour of our site!",
      description:
        "See what tools are available for you by following the red dot icon 😊.",

      // icon: <SettingsIcon />
    },
  ],
  onComplete, // Callback when onboarding is fully completed
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [runTour, setRunTour] = useState(false);

  // Check localStorage to auto-close if welcome has already been shown
  useEffect(() => {
    const welcomeShown = localStorage.getItem("welcomeShown");
    if (welcomeShown === "true") {
      onClose();
    }
  }, [onClose]);

  // Define tour steps (these selectors should match elements in your app)
  const tourSteps = [
    {
      target: ".profile-section", // Ensure an element in your app has this class
      content: "This is your profile. Complete your details here.",
    },
    {
      target: ".sarfinder-page",
      content: "Use this page to search for SLC acquisitions.",
    },
    {
      target: ".admin-page",
      content: "Manage all the submitted requests within this page.",
    },
  ];

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prev) => prev + 1);
    } else {
      // Final step reached: save localStorage flag if requested

      localStorage.setItem("welcomeShown", "true");

      if (onComplete) {
        onComplete();
      }
      // Optionally, trigger the guided tour after finishing
      setRunTour(true);
      // You could also navigate to a specific page:
      // history.push("/dashboard");
      // onClose();
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  // const handleCheckboxChange = (event) => {
  //   setDontShowAgain(event.target.checked);
  // };

  return (
    <>
      <Dialog
        open={openWelcome}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            borderRadius: 2,
            p: 2,
            backdropFilter: "blur(4px)",
            boxShadow: 3,
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <TypographyAtom variant="h6">
            Welcome to EOS Remote Sensing's Sarfinder!
          </TypographyAtom>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ mb: 3 }}>
            <TypographyAtom variant="body1">
              We're excited to have you here! Let us guide you through the first
              steps to help you get started.
            </TypographyAtom>
          </Box>
          <Box sx={{ mb: 3 }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel
                    icon={
                      step.icon ? (
                        <Slide direction="up" in mountOnEnter unmountOnExit>
                          <Box>{step.icon}</Box>
                        </Slide>
                      ) : undefined
                    }
                  >
                    {step.title}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Fade in timeout={500}>
            <Box sx={{ mb: 3 }}>
              <TypographyAtom
                variant="subtitle1"
                sx={{ fontWeight: "bold", mb: 1 }}
              >
                {steps[activeStep].title}
              </TypographyAtom>
              <TypographyAtom
                variant="body2"
                sx={{ mb: 2, whiteSpace: "pre-line" }}
              >
                {steps[activeStep].description}
              </TypographyAtom>
              {/* Optionally add an animated graphic if provided */}
              {steps[activeStep].graphic && (
                <Slide direction="up" in mountOnEnter unmountOnExit>
                  <Box sx={{ textAlign: "center", my: 2 }}>
                    {steps[activeStep].graphic}
                  </Box>
                </Slide>
              )}
            </Box>
          </Fade>
        </DialogContent>
        <DialogActions
          sx={{ flexDirection: "column", alignItems: "stretch", pb: 2, px: 3 }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mb: 1,
            }}
          >
            <Button onClick={handleBack} disabled={activeStep === 0}>
              Back
            </Button>
            <Button onClick={handleNext} variant="contained" color="primary">
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={dontShowAgain}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label="Don't show this again"
          /> */}
        </DialogActions>
      </Dialog>
      {/* Joyride guided tour */}
      <Joyride
        steps={tourSteps}
        run={runTour}
        continuous
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
        callback={(data) => {
          // Optionally handle callback events like tour end or step changes
          if (data.status === "finished" || data.status === "skipped") {
            setRunTour(false);
            onClose();
          }
        }}
      />
    </>
  );
};

export default WelcomeUserOrganism;
