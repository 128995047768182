import moment from "moment";
import { enqueueSnackbar } from "notistack";

class AWSTaskSubmitter {
  constructor(
    jobType,
    selectionArea,
    filteredJsonData,
    formData,
    selected,
    setDialogOpen,
    setDialogProps,
    isStaff = false
  ) {
    this.jobType = jobType;
    this.selectionArea = selectionArea;
    this.selected = selected;
    this.isStaff = isStaff;

    this.filteredJsonData = filteredJsonData;
    this.formData = formData;
    this.aoiLocation = "";
    this.setDialogOpen = setDialogOpen;
    this.setDialogProps = setDialogProps;
    // this.apiUrl = this.getApiUrl();
    // this.dagUrl = this.getDagUrl();
    // this.dpmKey = this.getDpmKey();
  }

  /**
   * Extracts the content between the first and the last period (if present).
   * @param {string} text - The input text
   * @returns {string} - The extracted content
   */
  extractIdentifierContent(text) {
    const firstPeriodIndex = text.indexOf(".");
    const lastPeriodIndex = text.lastIndexOf(".");

    if (firstPeriodIndex === -1) {
      return text; // Return the original text if no period is found
    }

    if (firstPeriodIndex === lastPeriodIndex) {
      return text.substring(firstPeriodIndex + 1); // Return content after the first period if no other period is found
    }

    return text.substring(firstPeriodIndex + 1, lastPeriodIndex); // Extract content between the first and last period
  }

  // getApiUrl() {
  //   const urls = {
  //     dpm1: "https://sf.earthobservatory.sg:8080/api/v1/variables/DPM1_variables",
  //     dpm2: "https://sf.earthobservatory.sg:8080/api/v1/variables/DPM2_variables",
  //     fpm1: "https://sf.earthobservatory.sg:8080/api/v1/variables/FPM1_variables",
  //     fpm2: "https://sf.earthobservatory.sg:8080/api/v1/variables/FPM2_variables",
  //   };
  //   return urls[this.jobType] || "";
  // }

  // getDagUrl() {
  //   const urls = {
  //     dpm1: "https://sf.earthobservatory.sg:8080/api/v1/dags/DPM1/dagRuns",
  //     dpm2: "https://sf.earthobservatory.sg:8080/api/v1/dags/DPM2/dagRuns",
  //     fpm1: "https://sf.earthobservatory.sg:8080/api/v1/dags/FPM1/dagRuns",
  //     fpm2: "https://sf.earthobservatory.sg:8080/api/v1/dags/FPM2/dagRuns",
  //   };
  //   return urls[this.jobType] || "";
  // }

  // getDpmKey() {
  //   const keys = {
  //     dpm1: "DPM1_variables",
  //     dpm2: "DPM2_variables",
  //     fpm1: "FPM1_variables",
  //     fpm2: "FPM2_variables",
  //   };
  //   return keys[this.jobType] || "";
  // }

  async fetchAoiLocation(bboxN, bboxE) {
    const aoiUrl = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${bboxN}&longitude=${bboxE}&localityLanguage=en`;
    const response = await fetch(aoiUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const aoiData = await response.json();
    return `${aoiData.city} in ${aoiData.countryName}`;
  }

  async submitTask(submitJob) {
    const { selectionArea, formData, setDialogOpen, setDialogProps } = this;

    const filterByDate = (data, date) => {
      return data.filter((feature) => feature.id.includes(date));
    };

    if (!selectionArea || !formData) {
      enqueueSnackbar("Missing selection area or form data", {
        variant: "error",
      });
      return;
    }

    const bboxN = selectionArea?.bounds._northEast.lat;
    const bboxE = selectionArea?.bounds._northEast.lng;
    const bboxS = selectionArea?.bounds._southWest.lat;
    const bboxW = selectionArea?.bounds._southWest.lng;

    try {
      this.aoiLocation = await this.fetchAoiLocation(bboxN, bboxE);
      console.log("AOI Location:", this.aoiLocation);
    } catch (error) {
      console.error("Error fetching AOI location:", error);
      enqueueSnackbar("Failed to fetch AOI location", { variant: "error" });
      return;
    }

    const master_date = formData?.awsMasterDate.utc().format("YYYYMMDD");

    const variable_data = {
      dir_name: formData?.directoryName,
      master_date: master_date,
      event_date: formData?.awsEventDate.utc().format("YYYYMMDD"),
      bboxS,
      bboxN,
      bboxW,
      bboxE,
      start: formData?.awsStartDateTime
        .utc()
        .startOf("day")
        .format()
        .replace("Z", "UTC"),
      end: formData?.awsEndDateTime
        .utc()
        .endOf("day")
        .format()
        .replace("Z", "UTC"),

      relativeOrbit: formData.awsRelOrbitNumber,
      selection_download: this.selected
        ?.map((id) => this.extractIdentifierContent(id))
        .sort((a, b) => {
          const nameA = a.toUpperCase(); // Ignore case
          const nameB = b.toUpperCase(); // Ignore case
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          return 0;
        }),

      additional_data: {
        submission_type: this.jobType,
        priority: formData.priority || "medium",
        // selection_area: [
        //   [bboxW, bboxN], // Top-left (Northwest)
        //   [bboxE, bboxN], // Top-right (Northeast)
        //   [bboxE, bboxS], // Bottom-right (Southeast)
        //   [bboxW, bboxS], // Bottom-left (Southwest)
        //   [bboxW, bboxN], // Closing the polygon (back to start)
        // ],
        expected_capture_datetime: formData.expectedCaptureDatetime
          ? formData.expectedCaptureDatetime.utc().format()
          : "",
        processing_mode: formData.expectedCaptureDatetime
          ? "scheduled"
          : "immediate",
        queue_end_date: formData.queueEndDate
          ? formData.queueEndDate.utc().format("YYYY-MM-DD")
          : "",
        polygon_boxes: filterByDate(this.filteredJsonData, master_date).map(
          (feature) => feature.geometry?.coordinates[0]
        ),
      },
    };

    const requestBody = {
      submission_type: this.jobType,
      variable_data: variable_data,
      total_selection_area: selectionArea?.area || 0,
      priority: formData.priority || "medium",
      number_of_slcs: this.selected.length,
      estimated_duration: null, // Optional
      // selection_area: [
      //   [bboxW, bboxN], // Top-left (Northwest)
      //   [bboxE, bboxN], // Top-right (Northeast)
      //   [bboxE, bboxS], // Bottom-right (Southeast)
      //   [bboxW, bboxS], // Bottom-left (Southwest)
      //   [bboxW, bboxN], // Closing the polygon (back to start)
      // ],
      // final_product_file_path: formData.directoryName || "",
      feedback: formData.feedback || "",
      // status: "pending approval",
      expected_capture_datetime: formData.expectedCaptureDatetime
        ? formData.expectedCaptureDatetime.utc().format()
        : null,
      processing_mode: formData.expectedCaptureDatetime
        ? "scheduled"
        : "immediate",
      queue_end_date: formData.queueEndDate
        ? formData.queueEndDate.utc().format("YYYY-MM-DD")
        : null,
      polygon_boxes: filterByDate(this.filteredJsonData, master_date).map(
        (feature) => feature.geometry?.coordinates[0]
      ),
      request_comment: formData.comment || "",
    };

    setDialogProps({
      onConfirm: async () => {
        submitJob.mutate(requestBody, {
          onSuccess: (data) => {
            enqueueSnackbar(
              `Job submitted successfully, Job ID: ${data.submission_id}`,
              { variant: "success" }
            );

            setDialogOpen(false);
          },
          onError: (error) => {
            console.error("Error submitting job:", error);
            enqueueSnackbar("Job submission failed", { variant: "error" });
          },
        });
      },
      onCancel: () => {
        enqueueSnackbar("Job not started", {
          variant: "info",
        });
        setDialogOpen(false);
      },

      data: {
        ...requestBody,
        filteredJsonData: this.filteredJsonData,
        reference_frame_polygon: filterByDate(
          this.filteredJsonData,
          master_date
        ).map((feature) => feature.geometry?.coordinates[0]),
        selection_area: [
          [bboxW, bboxN], // Top-left (Northwest)
          [bboxE, bboxN], // Top-right (Northeast)
          [bboxE, bboxS], // Bottom-right (Southeast)
          [bboxW, bboxS], // Bottom-left (Southwest)
          [bboxW, bboxN], // Closing the polygon (back to start)
        ],
        date_submitted: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
    });

    setDialogOpen(true);
  }

  // async submitTask() {
  //   const { selectionArea, formData, setDialogOpen, setDialogProps } = this;

  //   const bboxN = selectionArea?._northEast.lat;
  //   const bboxE = selectionArea?._northEast.lng;
  //   const bboxS = selectionArea?._southWest.lat;
  //   const bboxW = selectionArea?._southWest.lng;

  //   console.log("Initial BBox:", bboxN);

  //   try {
  //     this.aoiLocation = await this.fetchAoiLocation(bboxN, bboxE);
  //     console.log("AOI Location:", this.aoiLocation);
  //   } catch (error) {
  //     console.error("Error fetching AOI location:", error);
  //     return;
  //   }

  //   const master_date = formData?.awsMasterDate
  //     .utc()
  //     // .startOf("day")
  //     .format("YYYYMMDD");

  //   const value = {
  //     dir_name: formData?.directoryName,
  //     master_date: master_date,
  //     event_date: formData?.awsEventDate.utc().format("YYYYMMDD"),
  //     bboxS,
  //     bboxN,
  //     bboxW,
  //     bboxE,
  //     start: formData?.awsStartDateTime
  //       .utc()
  //       .startOf("day")
  //       .format()
  //       .replace("Z", "UTC"),
  //     end: formData?.awsEndDateTime
  //       .utc()
  //       .endOf("day")
  //       .format()
  //       .replace("Z", "UTC"),
  //     relativeOrbit: formData.awsRelOrbitNumber,
  //     selection_download: this.selected
  //       ?.map((id) => this.extractIdentifierContent(id))
  //       .sort((a, b) => {
  //         const nameA = a.toUpperCase(); // Ignore case
  //         const nameB = b.toUpperCase(); // Ignore case
  //         if (nameA > nameB) {
  //           return -1;
  //         }
  //         if (nameA < nameB) {
  //           return 1;
  //         }
  //         return 0;
  //       }),
  //   };

  //   const requestOptions = {
  //     method: "PATCH",
  //     mode: "cors",
  //     headers: {
  //       Authorization: "Basic YWlyZmxvdzphaXJmbG93",
  //       accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       description: "",
  //       key: this.dpmKey,
  //       value: JSON.stringify(value, null, 2),
  //     }),
  //   };

  //   const dagRequestOptions = {
  //     method: "POST",
  //     mode: "cors",
  //     headers: {
  //       Authorization: "Basic YWlyZmxvdzphaXJmbG93",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       conf: {},
  //     }),
  //   };

  //   const filterByDate = (data, date) => {
  //     return data.filter((feature) => feature.id.includes(date))[0];
  //   };

  //   console.log(
  //     filterByDate(this.filteredJsonData, master_date)?.geometry?.coordinates
  //   );

  //   if (selectionArea !== null) {
  //     setDialogProps({
  //       title: `Disaster area is ${this.aoiLocation}.`,
  //       message: value,
  //       onConfirm: async () => {
  //         enqueueSnackbar("Job Triggered", {
  //           variant: "info",
  //         });

  //         if (this.isStaff) {
  //           window.open(
  //             `https://sf.earthobservatory.sg:8080/dags/${this.jobType.toUpperCase()}/grid`
  //           );

  //           try {
  //             const variableResponse = await fetch(this.apiUrl, requestOptions);
  //             if (!variableResponse.ok) {
  //               throw new Error("Network response was not ok");
  //             }
  //             const variableData = await variableResponse.json();
  //             console.log("Variable Data:", variableData);
  //           } catch (error) {
  //             console.error("Error updating variables:", error);
  //           }

  //           try {
  //             const dagResponse = await fetch(this.dagUrl, dagRequestOptions);
  //             if (!dagResponse.ok) {
  //               throw new Error("Network response was not ok");
  //             }
  //             const dagData = await dagResponse.json();
  //             console.log("DAG Data:", dagData);
  //           } catch (error) {
  //             console.error("Error triggering DAG:", error);
  //           }
  //         } else {
  //           enqueueSnackbar("Request Submitted (TESTING)", {
  //             variant: "info",
  //           });
  //         }
  //         setDialogOpen(false);
  //       },
  //       onCancel: () => {
  //         enqueueSnackbar("Job not started", {
  //           variant: "info",
  //         });
  //         setDialogOpen(false);
  //       },

  //       data: {
  //         selection_area: [
  //           [bboxW, bboxN], // Top-left (Northwest)
  //           [bboxE, bboxN], // Top-right (Northeast)
  //           [bboxE, bboxS], // Bottom-right (Southeast)
  //           [bboxW, bboxS], // Bottom-left (Southwest)
  //           [bboxW, bboxN], // Closing the polygon (back to start)
  //         ],
  //         filteredJsonData: this.filteredJsonData,
  //         reference_frame_polygon: filterByDate(
  //           this.filteredJsonData,
  //           master_date
  //         )?.geometry?.coordinates,
  //         selected_slcs: this.selected,
  //         variable_data: value,
  //       },

  //     });
  //     setDialogOpen(true);
  //   } else {
  //     console.log("Selection area is empty");
  //   }
  // }
}

export default AWSTaskSubmitter;
