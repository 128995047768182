import { FileDownload } from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  Box,
  Checkbox,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import ButtonAtom from "components/ButtonAtom";
import TypographyAtom from "components/TypographyAtom";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import tokml from "tokml";
import { getNextOverpass } from "utils/whenOverpass";

const headCells = [
  {
    id: "identifier",
    numeric: false,
    disablePadding: true,
    label: "Identifier",
  },

  {
    id: "producttype",
    numeric: false,
    disablePadding: false,
    label: "Product Type",
  },

  {
    id: "beginposition",
    numeric: false,
    disablePadding: false,
    label: "Begin Position",
  },
  {
    id: "endposition",
    numeric: false,
    disablePadding: false,
    label: "End Position",
  },
  {
    id: "relativeorbitnumber",
    numeric: true,
    disablePadding: false,
    label: "Relative Orbit",
  },
  {
    id: "framenumber",
    numeric: true,
    disablePadding: false,
    label: "Frame Number",
  },
  {
    id: "polarisationchannels",
    numeric: false,
    disablePadding: false,
    label: "Polarisation Channels",
  },
  { id: "beammode", numeric: false, disablePadding: false, label: "Beam Mode" },
  {
    id: "orbitdirection",
    numeric: false,
    disablePadding: false,
    label: "Orbit Direction",
  },
  // {
  //   id: "acquisitiontype",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Acquisition Type",
  // },
  {
    id: "platformshortname",
    numeric: false,
    disablePadding: false,
    label: "Platform",
  },
  {
    id: "instrumentshortname",
    numeric: false,
    disablePadding: false,
    label: "Instrument",
  },
  {
    id: "processingdate",
    numeric: false,
    disablePadding: false,
    label: "Processing Date",
  },

  {
    id: "orbitnumber",
    numeric: true,
    disablePadding: false,
    label: "Orbit Number",
  },

  {
    id: "antennalookdirection",
    numeric: false,
    disablePadding: false,
    label: "Look Direction",
  },

  {
    id: "gekko_path",
    numeric: false,
    disablePadding: false,
    label: "Gekko Path",
  },
];

const SlidingTable = ({
  jsonData,
  currentHighlight,
  handleFeatureHighlight,
  selected,
  setSelected,
}) => {
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("beginposition");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [anchorEl, setAnchorEl] = useState(null);
  const [nextOverpass, setNextOverpass] = useState(null);
  // const [currentHighlightID, setCurrentHighlightID] = useState(null);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  const handleDownloadKml = () => {
    const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format

    jsonData.forEach((platform, index) => {
      const fileName = `data_${currentDate}_${index}.kml`; // Create a relevant file name with the date
      const kmlString = `data:text/xml;charset=utf-8,${encodeURIComponent(
        jsonToKml(platform)
      )}`;

      const link = document.createElement("a");
      link.href = kmlString;
      link.download = fileName;
      link.click();
    });
  };

  // useEffect(() => {
  //   setCurrentHighlightID(currentHighlightRef.current?.options.id);
  //   console.log(currentHighlightRef.current?.options.id);
  // }, [currentHighlightRef.current]);

  const jsonToKml = (json) => {
    // Convert JSON to KML format
    // This is a placeholder function. You need to implement the actual conversion logic.
    var kmlDocumentName = tokml(json, {
      documentName: "Sarfinder Metadata",
      documentDescription:
        "This is a KML file generated from the metadata of the Sarfinder platform",
    });

    return kmlDocumentName;
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = visibleRows.map((row) => row.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleDownloadJson = () => {
    const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format

    jsonData.forEach((platform, index) => {
      const fileName = `data_${currentDate}_${index}.json`; // Create a relevant file name with the date
      const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(platform, null, 2)
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = fileName;
      link.click();
    });
  };

  // const handleDownloadCsv = () => {
  //   const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format

  //   jsonData.forEach((platform, index) => {
  //     const fileName = `data_${currentDate}_${index}.csv`; // Create a relevant file name with the date
  //     const csvString = `data:text/csv;charset=utf-8,${encodeURIComponent(
  //       jsonToCsv(platform)
  //     )}`;
  //     const link = document.createElement("a");
  //     link.href = csvString;
  //     link.download = fileName;
  //     link.click();
  //   });
  // };

  // const jsonToCsv = (json) => {
  //   const items = json.features;
  //   const replacer = (key, value) => (value === null ? "" : value); // Handle null values
  //   const header = Object.keys(items[0].properties);
  //   const csv = [
  //     header.join(","), // Header row
  //     ...items.map((row) =>
  //       header
  //         .map((fieldName) => {
  //           let fieldValue = JSON.stringify(
  //             row.properties[fieldName],
  //             replacer
  //           );

  //           // Only replace if the fieldName is 'identifier'
  //           if (fieldName === "identifier") {
  //             fieldValue = fieldValue.replace(/ALOS-2_PALSAR-2_/g, ""); // Replace for 'identifier' field
  //           }

  //           return fieldValue;
  //         })
  //         .join(",")
  //     ),
  //   ].join("\r\n");

  //   return csv;
  // };

  const handleDownloadCsv = () => {
    const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format

    // Filter only selected rows
    const selectedRows = rows.filter((item, index) =>
      selected.includes(item.id)
    );

    if (selectedRows.length === 0) {
      alert("No rows selected for download.");
      return;
    }

    const fileName = `selected_data_${currentDate}.csv`;
    const csvString = `data:text/csv;charset=utf-8,${encodeURIComponent(
      jsonToCsv(selectedRows)
    )}`;

    const link = document.createElement("a");
    link.href = csvString;
    link.download = fileName;
    link.click();
  };

  const jsonToCsv = (items) => {
    if (!items.length) return "";

    const replacer = (key, value) => (value === null ? "" : value); // Handle null values
    const header = Object.keys(items[0]); // Use keys from first item for headers
    const csv = [
      header.join(","), // Header row
      ...items.map((row) =>
        header
          .map((fieldName) => {
            let fieldValue = JSON.stringify(row[fieldName], replacer);

            // Only replace if the fieldName is 'identifier'
            if (fieldName === "identifier") {
              fieldValue = fieldValue.replace(/ALOS-2_PALSAR-2_/g, "");
            }

            return fieldValue;
          })
          .join(",")
      ),
    ].join("\r\n");

    return csv;
  };

  const rows = jsonData?.flatMap((platformName) => {
    return platformName?.features?.map((item) => {
      return { id: item?.id, ...item?.properties };
    });
  });

  const visibleRows = useMemo(() => {
    return rows
      .slice()
      .sort((a, b) => {
        if (typeof a[orderBy] === "string" && typeof b[orderBy] === "string") {
          return order === "desc"
            ? b[orderBy].localeCompare(a[orderBy])
            : a[orderBy].localeCompare(b[orderBy]);
        } else {
          return order === "desc"
            ? b[orderBy] - a[orderBy]
            : a[orderBy] - b[orderBy];
        }
      })
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [order, orderBy, page, rowsPerPage, rows]);

  useEffect(() => {
    if (currentHighlight) {
      const { beginposition, platformshortname } = rows.find(
        (row) => row.id === currentHighlight
      );
      setNextOverpass(
        getNextOverpass(platformshortname, beginposition)?.overpass
      );
    }
  }, [currentHighlight]);

  return (
    <>
      <IconButton
        onClick={handleToggleDrawer}
        color="primary"
        aria-label="open table drawer"
        sx={{
          position: "fixed",
          bottom: open ? "50%" : "10px",
          left: "50%",
          transform: "translateX(-50%)",
          transition: "bottom 0.3s",
          zIndex: 999,
          width: "60px",
          height: "60px",
          backgroundColor: "white",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          borderRadius: "50%",
          "&:hover": {
            backgroundColor: "lightgray",
          },
        }}
      >
        {open ? (
          <ArrowDownwardIcon fontSize="large" />
        ) : (
          <ArrowUpwardIcon fontSize="large" />
        )}
      </IconButton>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={handleToggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            height: "48%",
            bottom: 0,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          },
        }}
      >
        <Slide
          sx={{ height: "100%" }}
          direction="up"
          in={open}
          mountOnEnter
          unmountOnExit
        >
          <Box sx={{ width: "100%", height: "100%" }}>
            <Paper
              sx={{
                width: "100%",
                mb: 2,
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 1,
                }}
              >
                <TypographyAtom variant="h6">
                  Total Selected: {selected?.length}
                </TypographyAtom>

                <TypographyAtom variant="h6">
                  Next Overpass: {nextOverpass}
                </TypographyAtom>

                <Tooltip title="Click to download options" placement="top">
                  <ButtonAtom
                    variant="contained"
                    color="primary"
                    onClick={handleMenuClick}
                  >
                    <FileDownload sx={{ mr: 1 }} />
                    Download Options
                  </ButtonAtom>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleDownloadJson();
                      handleMenuClose();
                    }}
                  >
                    Download GeoJSON
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDownloadKml();
                      handleMenuClose();
                    }}
                  >
                    Download KML
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleDownloadCsv();
                      handleMenuClose();
                    }}
                  >
                    Download CSV
                  </MenuItem>
                </Menu>
                {/* <IconButton onClick={handleToggleDrawer}>
                  <CloseIcon />
                </IconButton> */}
              </Box>

              <TableContainer
                sx={{
                  paddingX: 2,
                  paddingBottom: 2,
                  border: "1px solid #444",
                  borderRadius: "8px",
                  flex: 1,
                  overflowY: "auto",
                  // height: "100%",
                  // height: "500px",
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        padding="checkbox"
                        sx={{
                          backgroundColor: "#333", // Darker background for the header
                          fontWeight: "bold",
                          color: "#ffffff", // Light text color for headers
                          textAlign: "center",
                          "&:hover": {
                            color: "#76c7c0", // Accent color on hover
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Checkbox
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < visibleRows.length
                          }
                          checked={
                            visibleRows.length > 0 &&
                            selected.length === visibleRows.length
                          }
                          onChange={handleSelectAllClick}
                          inputProps={{ "aria-label": "select all rows" }}
                        />
                      </TableCell>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          // align={headCell.numeric ? "right" : "left"}
                          // align="center" // Center the text

                          // padding={headCell.disablePadding ? "none" : "normal"}
                          sx={{
                            backgroundColor: "#333", // Darker background for the header
                            fontWeight: "bold",
                            color: "#ffffff", // Light text color for headers
                            textAlign: "center",
                            "&:hover": {
                              color: "#76c7c0", // Accent color on hover
                              cursor: "pointer",
                            },
                            padding: headCell.disablePadding ? 0 : "0.2rem",
                          }}
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={() => handleRequestSort(headCell.id)}
                            sx={{
                              "&.Mui-active": {
                                color: "#76c7c0", // Accent color for active sorting
                              },
                            }}
                          >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      return (
                        <TableRow
                          key={row.id}
                          onClick={() => {
                            handleFeatureHighlight(row.id);
                            const {
                              identifier,
                              beginposition,
                              platformshortname,
                            } = row;
                            setNextOverpass(
                              getNextOverpass(platformshortname, beginposition)
                                ?.overpass
                            );
                          }}
                          sx={{
                            cursor: "pointer",

                            backgroundColor:
                              currentHighlight === row.id
                                ? "rgba(118, 199, 192, 0.2)" // Soft teal for highlight
                                : index % 2 === 0
                                ? "#111111" // Alternate dark gray for striped rows
                                : "inherit",
                            "&:hover": {
                              backgroundColor:
                                currentHighlight === row.id
                                  ? "rgba(118, 199, 192, 0.3)" // Slightly darker teal on hover
                                  : "rgba(255, 255, 255, 0.1)", // Light gray on hover for non-highlighted rows
                            },
                            transition: "background-color 0.3s ease",
                          }}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              // onClick={(event) => event.stopPropagation()} // Stop event propagation
                              onClick={(event) => handleClick(event, row.id)}
                              inputProps={{
                                "aria-labelledby": `enhanced-table-checkbox-${row.id}`,
                              }}
                            />
                          </TableCell>
                          <TableCell align="left">{row.identifier}</TableCell>

                          <TableCell align="center">
                            {row.producttype}
                          </TableCell>

                          <TableCell align="center">
                            {moment
                              .utc(row.beginposition)
                              .format("YYYY-MM-DD THH:mm:ss")}
                          </TableCell>
                          <TableCell align="center">
                            {moment
                              .utc(row.endposition)
                              .format("YYYY-MM-DD THH:mm:ss")}
                          </TableCell>

                          <TableCell align="center">
                            {row.relativeorbitnumber}
                          </TableCell>

                          <TableCell align="center">
                            {row.framenumber}
                          </TableCell>
                          <TableCell align="center">
                            {row.polarisationchannels}
                          </TableCell>
                          <TableCell align="center">{row.beammode}</TableCell>
                          <TableCell align="center">
                            {row.orbitdirection}
                          </TableCell>

                          {/* <TableCell align="center">
                          {row.acquisitiontype}
                        </TableCell> */}
                          <TableCell align="center">
                            {row.platformshortname}
                          </TableCell>
                          <TableCell align="center">
                            {row.instrumentshortname}
                          </TableCell>
                          <TableCell align="center">
                            {moment
                              .utc(row.processingdate)
                              .format("YYYY-MM-DD THH:mm:ss")}
                          </TableCell>

                          <TableCell align="center">
                            {row.orbitnumber}
                          </TableCell>

                          <TableCell align="center">
                            {row.antennalookdirection}
                          </TableCell>

                          <TableCell align="center">{row.gekko_path}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </Slide>
      </Drawer>
    </>
  );
};

export default SlidingTable;
