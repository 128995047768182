import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardContent,
  Grid,
  Stack,
  Chip,
  Skeleton,
  Typography,
  Box,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ScaleUpOnHover from "utils/Animations/ScaleUpOnHover";
import { formatDate, textToColorHex } from "utils/helper";
import TypographyAtom from "components/TypographyAtom";
import STOCK_IMG from "assets/EOS_PRODUCT.png";
import { Download, ImageTwoTone, Preview } from "@mui/icons-material";

const EventList = ({
  Title,
  Image,
  EventDate,
  Description,
  Tags,
  LastUpdated,
  ProductList,
  HasGreyscale,
  onClick,
}) => {
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };
  return (
    <Grid item xs={12} sm={6} md={6} lg={4} sx={{ maxWidth: "100%" }}>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        sx={{
          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "16px",
          border: "1px solid rgba(180, 240, 255, 0.29)",
          transition: "box-shadow 0.3s",
          width: "100%",

          "&:hover": {
            boxShadow: "0px 15px 25px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            paddingX: 2,
            width: "100%",
            display: "flex",
            flexDirection: "column",

            "& .MuiAccordionSummary-content": {
              alignItems: "center",
            },
            "& .MuiAccordionSummary-expandIconWrapper": {
              alignSelf: "center", // Align the icon at the bottom
              marginTop: "auto", // Push the icon to the bottom
            },
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            gap={2}
            sx={{ width: "100%" }}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              sx={{ width: "100%" }}
            >
              <Box
                component="img"
                src={Image ? Image : STOCK_IMG}
                alt="event image"
                sx={{
                  width: 80,
                  height: 80,
                  objectFit: "cover",
                  borderRadius: "8px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                }}
                loading="lazy"
                onLoad={() => setLoading(false)}
              />
              <Stack>
                <TypographyAtom variant="h6" bold>
                  {Title?.replace("EOS-RS ", "")}
                </TypographyAtom>
                <TypographyAtom variant="body2" color="text.secondary">
                  Date of event: {formatDate(EventDate)}
                </TypographyAtom>
                {HasGreyscale && (
                  <Chip
                    label="Greyscale Image Available"
                    icon={<ImageTwoTone />}
                    size="small"
                    sx={{
                      backgroundColor: "grey",
                      color: "white",
                      mt: 1,
                    }}
                  />
                )}
              </Stack>
            </Stack>
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              fullWidth
              startIcon={<Preview />}
              onClick={(event) => {
                event.stopPropagation(); // Prevent the accordion from opening
                onClick(); // Call the original onClick handler
              }}
            >
              Preview
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              fullWidth
              startIcon={
                <ExpandMoreIcon
                  sx={{
                    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s",
                  }}
                />
              }
              sx={{ alignSelf: "center", marginTop: "auto" }}
            >
              {expanded ? "See Less" : "See More"}
            </Button>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <CardContent>
            <Stack sx={{ gap: 2 }}>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {Tags.filter((item) => item.includes("Map")).map((item) => (
                  <Chip key={item} label={item} sx={textToColorHex(item)} />
                ))}
                {Tags.filter((item) => !item.includes("Map")).map((item) => (
                  <Chip
                    key={item}
                    label={item}
                    sx={{
                      backgroundColor: "lightgrey",
                      color: "black",
                      // fontSize: 10,
                    }}
                  />
                ))}
              </Stack>
              <TypographyAtom
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 5,
                  wordWrap: "break-word",
                }}
                variant="body1"
              >
                {decodeURIComponent(
                  escape(Description.replace(/^.+.This/, "This"))
                )}
              </TypographyAtom>
              {/* <CustomTypo fontSize={13} color={"lightgrey"}>
                Map produced on {formatDate(LastUpdated)}
              </CustomTypo> */}
              {ProductList && ProductList.length > 0 && (
                <Stack sx={{ gap: 2 }}>
                  <TypographyAtom variant="h6">Products</TypographyAtom>
                  {ProductList.map((product) => (
                    <Box
                      key={product.prod_name}
                      sx={{
                        border: "1px solid #ddd",
                        borderRadius: "8px",
                        padding: 2,
                      }}
                    >
                      <TypographyAtom variant="subtitle1">
                        {product.prod_title}
                      </TypographyAtom>
                      <Stack direction="row" alignItems="center" gap={1} mt={1}>
                        {product.private_files &&
                          Object.entries(product.private_files)
                            .filter(([fileName]) => fileName.endsWith(".tif"))
                            .map(([fileName, fileUrl]) => (
                              <Button
                                key={fileName}
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<Download />}
                                href={fileUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Download Greyscale
                              </Button>
                            ))}
                      </Stack>
                    </Box>
                  ))}
                </Stack>
              )}
            </Stack>
          </CardContent>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
export default EventList;
