import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import ButtonAtom from "components/ButtonAtom";
import shp from "shpjs";
import { parse as parseWKT } from "terraformer-wkt-parser";
import { LeafletControl } from "./MapTools";

const getRandomColor = () => {
  const letters = "89ABCDEF"; // Use only higher values to ensure bright colors
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

export default function GeoFileUploader({ onFileUpload }) {
  // const [uploadedLayers, setUploadedLayers] = useState([]);

  const handleFile = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = async (e) => {
      const { name } = file;
      const content = e.target.result;

      let geoJson;
      try {
        if (name.endsWith(".geojson")) {
          geoJson = JSON.parse(content);
        } else if (name.endsWith(".wkt")) {
          geoJson = parseWKT(content);
        } else if (name.endsWith(".shp") || name.endsWith(".zip")) {
          geoJson = await shp(content);
        } else {
          alert("Unsupported file format!");
          return;
        }

        // Create a new layer object with the required properties
        const newLayer = {
          id: `${name}-${Date.now()}`, // unique id
          name, // file name used as layer name
          visible: true,
          opacity: 1,
          geoJson,
          color: getRandomColor(), // Add random color
        };

        // setUploadedLayers((prev) => [...prev, newLayer]);
        onFileUpload(newLayer);
      } catch (error) {
        console.error("Error processing file:", error);
        alert(
          "Error processing file. Please ensure the file is correctly formatted."
        );
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <LeafletControl>
      <IconButton
        component="label"
        sx={{
          color: "#fff",
          backgroundColor: (theme) => theme.palette.primary.main,
          "&:hover": {
            backgroundColor: (theme) => theme.palette.secondary.dark,
          },
          transition: "background 0.3s ease, transform 0.2s",
        }}
      >
        <CloudUploadIcon />
        <input
          type="file"
          accept=".shp,.zip,.geojson,.wkt"
          onChange={handleFile}
          hidden
        />
      </IconButton>
    </LeafletControl>
    // <div
    //   style={{ position: "absolute", top: 12, left: 75, zIndex: 1000 }}
    //   className="leaflet-ui"
    // >
    //   <ButtonAtom
    //     variant="contained"
    //     component="label"
    //     color="primary"
    //     sx={{
    //       padding: "10px 16px",
    //       borderRadius: "8px",
    //       boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    //       display: "flex",
    //       alignItems: "center",
    //     }}
    //   >
    //     <CloudUploadIcon sx={{ marginRight: "8px" }} />
    //     Upload File
    //     <input
    //       type="file"
    //       accept=".shp,.zip,.geojson,.wkt"
    //       onChange={handleFile}
    //       hidden
    //     />
    //   </ButtonAtom>
    // </div>
  );
}
