import React from "react";
import { Typography } from "@mui/material";

/**
 * TypographyAtom component for rendering styled typography.
 *
 * @param {string} variant - The typography variant (e.g., 'body1', 'h5').
 * @param {boolean} bold - If true, applies bold styling.
 * @param {boolean} italic - If true, applies italic styling.
 * @param {object} sx - Additional custom styles.
 * @param {object} props - Other props to pass to the Typography component.
 * @returns {JSX.Element} The styled Typography component.
 */
const TypographyAtom = ({
  variant: customVariant,
  bold,
  italic,
  component,
  sx,
  ...props
}) => {
  // Default to 'body1' if no variant is provided
  const selectedVariant = customVariant || "body1";

  // Initialize style object
  const style = {};

  // Apply bold styling if specified
  if (bold) {
    style.fontWeight = 700;
  }

  // Apply italic styling if specified
  if (italic) {
    style.fontStyle = "italic";
  }

  return (
    <Typography
      variant={selectedVariant}
      component={component}
      sx={{ ...style, ...sx }}
      {...props}
    />
  );
};

export default TypographyAtom;
