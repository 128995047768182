import { Box, CircularProgress } from "@mui/material";
import { useState } from "react";

const WindyMap = ({ sx }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Box
      sx={{
        borderRadius: "1rem",

        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
        ...sx,
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <iframe
        src="https://embed.windy.com"
        frameBorder="0"
        width="100%"
        height="100%"
        title="Windy Weather Map"
        allowFullScreen
        onLoad={() => setIsLoading(false)}
      />
    </Box>
  );
};

export default WindyMap;
