import MenuIcon from "@mui/icons-material/Menu";
import { Button, Divider, Drawer, Stack, Toolbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EOS_LOGO from "assets/EOS Logo.png";
import ButtonAtom from "components/ButtonAtom";
import DynamicIconMUI from "components/DynamicIconMUI";
import UserAccountMolecule from "components/molecule/UserAccountMolecule";
import TypographyAtom from "components/TypographyAtom";
import useScreenSize from "hooks/useScreenSize";
import { useLogout } from "provider/auth/useLogout";
import { useUser } from "provider/auth/useUser";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRoute } from "../../utils/routes";

const fontWeight = 800;

const buttonStyles = (isActive) => ({
  color: isActive ? "#ffbd59FF" : "white",
  "&:hover": { backgroundColor: "transparent", color: "#ffbd59FF" },
  paddingX: "1rem",
});

const EOSLogo = () => {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [visible, setVisible] = useState(true);
  const logoWidth = 150;
  const logoHeight = 54;

  useEffect(() => {
    const checkSize = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        setVisible(width >= logoWidth && height >= logoHeight);
      }
    };

    checkSize(); // Initial check
    window.addEventListener("resize", checkSize);
    return () => window.removeEventListener("resize", checkSize);
  }, []);

  return (
    <div
      ref={containerRef}
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      {visible && (
        <Button
          onClick={() => navigate(getRoute("home"))}
          style={{ padding: 0, minWidth: "auto" }}
        >
          <img
            src={EOS_LOGO}
            alt="logo"
            style={{ width: `${logoWidth}px`, height: `${logoHeight}px` }}
          />
        </Button>
      )}
    </div>
  );
};

const NavButton = ({ to, label, currentPath, variant, onClick, className }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) onClick();
    else navigate(getRoute(to));
  };

  return (
    <Button
      className={className}
      onClick={handleClick}
      sx={buttonStyles(currentPath === getRoute(to))}
      href={
        to === "aboutus"
          ? "https://earthobservatory.sg/research/centres-labs/eos-rs"
          : undefined
      }
      target={to === "aboutus" ? "_blank" : undefined}
      variant={variant}
    >
      <TypographyAtom fontWeight={fontWeight} sx={{ whiteSpace: "nowrap" }}>
        {label}
      </TypographyAtom>
    </Button>
  );
};

const MenuButtons = ({ currentPath, isLoggedIn, isStaff, variant }) => {
  const staffButtons = [
    { to: "sarfinder", label: "Sarfinder", className: "sarfinder-page" },
    { to: "admin", label: "Admin", className: "admin-page" },
    { to: "upload", label: "Upload" },
  ];

  return (
    <>
      <NavButton
        to="home"
        label="Home"
        currentPath={currentPath}
        variant={variant}
      />
      {isLoggedIn && (
        <>
          {isStaff ? (
            staffButtons.map(({ to, label, className }) => (
              <NavButton
                className={className}
                key={to}
                to={to}
                label={label}
                currentPath={currentPath}
                variant={variant}
              />
            ))
          ) : (
            <NavButton
              to="request"
              label="Request"
              currentPath={currentPath}
              variant={variant}
            />
          )}
          <NavButton
            to="greyscale"
            label="Thresholding"
            currentPath={currentPath}
            variant={variant}
          />
        </>
      )}
      <NavButton
        to="products"
        label="Products"
        currentPath={currentPath}
        variant={variant}
      />
      {variant === "mobile" && <Divider />}
      <NavButton
        to="aboutus"
        label="About Us"
        currentPath={currentPath}
        variant={variant}
      />
      {variant === "mobile" && <Divider />}
      <NavButton
        to="faq"
        label="FAQ"
        currentPath={currentPath}
        variant={variant}
      />
    </>
  );
};

const AuthButton = ({ userQuery, logout }) => {
  const navigate = useNavigate();
  const isLoggedIn = Boolean(userQuery?.data?.username);
  const handleAuth = async () => {
    if (isLoggedIn) await logout();
    else navigate(getRoute("login"));
  };

  return (
    <ButtonAtom
      variant="contained"
      color="primary"
      sx={{ padding: "0.5rem 1rem", font: "black" }}
      onClick={handleAuth}
    >
      <TypographyAtom fontWeight={fontWeight}>
        {isLoggedIn ? "Logout" : "Login"}
      </TypographyAtom>
    </ButtonAtom>
  );
};

const CustomToolbar = ({ isMapPage, handleDrawerOpen, open }) => {
  const userQuery = useUser();
  const { logout } = useLogout();
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const { pathname: currentPath } = useLocation();
  const { isDesktopView } = useScreenSize();

  const isLoggedIn = Boolean(userQuery?.data?.username);
  const isStaff = userQuery?.data?.is_staff || userQuery?.data?.is_superuser;

  const toggleDrawer = (state) => () => setOpenDrawer(state);

  return (
    <>
      <Toolbar>
        {isMapPage && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        )}

        <EOSLogo />
        <Stack
          direction="row"
          sx={{
            width: "100%",
            justifyContent: "end",
            height: "100%",
            alignItems: "center",
          }}
          spacing={1}
        >
          {isDesktopView ? (
            <>
              <MenuButtons
                currentPath={currentPath}
                isLoggedIn={isLoggedIn}
                isStaff={isStaff}
              />
              <UserAccountMolecule
                isLoggedIn={isLoggedIn}
                userQuery={userQuery}
              />
              <AuthButton userQuery={userQuery} logout={logout} />
            </>
          ) : (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              sx={{ height: "fit-content" }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Stack>
      </Toolbar>
      <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>
        <Stack
          direction="row"
          sx={{
            padding: "1rem",
            borderBottom: "1px solid #ccc",
            backgroundColor: "#333",
            color: "white",
            alignItems: "center",
            width: "40vw",
          }}
        >
          <IconButton color="inherit" onClick={toggleDrawer(false)}>
            <DynamicIconMUI iconName="Close" />
          </IconButton>
          <TypographyAtom sx={{ fontWeight }} variant="h5">
            Menu
          </TypographyAtom>
        </Stack>
        <Stack sx={{ padding: 1 }}>
          <MenuButtons
            currentPath={currentPath}
            isLoggedIn={isLoggedIn}
            isStaff={isStaff}
            variant="mobile"
          />
          <UserAccountMolecule isLoggedIn={isLoggedIn} userQuery={userQuery} />
          <AuthButton userQuery={userQuery} logout={logout} />
        </Stack>
      </Drawer>
    </>
  );
};

export default CustomToolbar;
