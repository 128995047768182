import L from "leaflet";

/*
 * L.TileLayer.PixelFilter
 * https://github.com/greeninfo/L.TileLayer.PixelFilter
 * http://greeninfo-network.github.io/L.TileLayer.PixelFilter/
 */
L.tileLayerPixelFilter = function (url, options) {
  return new L.TileLayer.PixelFilter(url, options);
};

L.TileLayer.PixelFilter = L.TileLayer.extend({
  // the constructor saves settings and throws a fit if settings are bad, as typical
  // then adds the all-important 'tileload' event handler which basically "detects" an unmodified tile and performs the pxiel-swap
  initialize: function (url, options) {
    options = L.extend(
      {},
      L.TileLayer.prototype.options,
      {
        matchRGBA: null,
        missRGBA: null,
        pixelCodes: [],
        filter: null,
        crossOrigin: "Anonymous", // per issue 15, this is how you do it in Leaflet 1.x
        pane: "overlayPane",
      },
      options
    );
    L.TileLayer.prototype.initialize.call(this, url, options);
    L.setOptions(this, options);

    // go ahead and save our settings
    this.setMatchRGBA(this.options.matchRGBA);
    this.setMissRGBA(this.options.missRGBA);
    this.setPixelCodes(this.options.pixelCodes);

    // and add our tile-load event hook which triggers us to do the pixel-swap
    this.on("tileload", function (event) {
      this.applyFiltersToTile(event.tile);
    });
  },

  // settings setters
  setMatchRGBA: function (rgba) {
    // save the setting
    if (
      rgba !== null &&
      (typeof rgba !== "object" ||
        typeof rgba.length !== "number" ||
        rgba.length !== 4)
    )
      throw "L.TileLayer.PixelSwap expected matchRGBA to be RGBA [r,g,b,a] array or else null";
    this.options.matchRGBA = rgba;

    // force a redraw, which means new tiles, which mean new tileload events; the circle of life
    this.redraw(true);
  },
  setMissRGBA: function (rgba) {
    // save the setting
    if (
      rgba !== null &&
      (typeof rgba !== "object" ||
        typeof rgba.length !== "number" ||
        rgba.length !== 4)
    )
      throw "L.TileLayer.PixelSwap expected missRGBA to be RGBA [r,g,b,a] array or else null";
    this.options.missRGBA = rgba;

    // force a redraw, which means new tiles, which mean new tileload events; the circle of life
    this.redraw(true);
  },
  setPixelCodes: function (pixelcodes) {
    // save the setting
    if (typeof pixelcodes !== "object" || typeof pixelcodes.length !== "number")
      throw "L.TileLayer.PixelSwap expected pixelCodes to be a list of triplets: [ [r,g,b], [r,g,b], ... ]";
    this.options.pixelCodes = pixelcodes;

    // force a redraw, which means new tiles, which mean new tileload events; the circle of life
    this.redraw(true);
  },

  // extend the _createTile function to add the .crossOrigin attribute, since loading tiles from a separate service is a pretty common need
  // and the Canvas is paranoid about cross-domain image data. see issue #5
  // this is really only for Leaflet 0.7; as of 1.0 L.TileLayer has a crossOrigin setting which we define as a layer option
  _createTile: function () {
    var tile = L.TileLayer.prototype._createTile.call(this);
    tile.crossOrigin = "Anonymous";

    return tile;
  },

  // the heavy lifting to do the pixel-swapping
  // called upon 'tileload' and passed the IMG element
  // tip: when the tile is saved back to the IMG element that counts as a tileload event too! thus an infinite loop, as wel as comparing the pixelCodes against already-replaced pixels!
  //      so, we tag the already-swapped tiles so we know when to quit
  // if the layer is redrawn, it's a new IMG element and that means it would not yet be tagged
  applyFiltersToTile: function (imgelement) {
    // already processed, see note above
    if (imgelement.getAttribute("data-PixelFilterDone")) return;

    // copy the image data onto a canvas for manipulation
    const width = imgelement.width;
    const height = imgelement.height;
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext("2d");

    context.imageSmoothingEnabled = false;
    context.mozImageSmoothingEnabled = false;
    context.webkitImageSmoothingEnabled = false;
    context.msImageSmoothingEnabled = false;

    context.drawImage(imgelement, 0, 0);

    // Extract pixel data
    let pixels;
    try {
      pixels = context.getImageData(0, 0, width, height);
    } catch (e) {
      throw "L.TileLayer.PixelFilter getImageData() failed. Likely a cross-domain issue?";
    }

    const data = pixels.data;
    const filter = this.options.filter; // Access the filter function
    // console.log(filter);

    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];
      const a = data[i + 3];

      // Skip transparent pixels
      if (a === 0) continue;

      // Apply the custom filter if provided
      if (typeof filter === "function") {
        const [newR, newG, newB, newA] = filter([r, g, b, a]);
        // console.log(r, g, b, a);
        // console.log(newR, newG, newB, newA);
        data[i] = newR;
        data[i + 1] = newG;
        data[i + 2] = newB;
        data[i + 3] = newA;
      }
    }

    // Write the image back to the canvas and update the tile
    context.putImageData(pixels, 0, 0);
    imgelement.setAttribute("data-PixelFilterDone", true);
    imgelement.src = canvas.toDataURL("image/png");
  },
});
