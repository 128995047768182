import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  InputAdornment,
  IconButton,
  Pagination,
  Stack,
  Container,
} from "@mui/material";
import { Search, ImageTwoTone } from "@mui/icons-material";
import GreyscaleCard from "features/GreyscaleCard/GreyscaleCard";
import { useCallAPI, capitalizeEachWord } from "utils/helper";
import { enqueueSnackbar } from "notistack";
import { getDynamicRoute } from "utils/routes";
import AppHeader from "features/AppHeader/AppHeader";

const itemsPerPage = 16;

const GreyscalePage = () => {
  const [greyscaleData, setGreyscaleData] = useState([]);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const fetchGreyscaleData = useCallAPI(
    "fetchRequests",
    "/sarfinder/greyscale/get-list",
    "GET"
  );

  const Navigate = useNavigate();

  useEffect(() => {
    fetchGreyscaleData.mutate(null, {
      onSuccess: (data) => {
        //   setRequests(data?.job_submissions);
        setGreyscaleData(data?.events);
        console.log(data);
      },
      onError: (error) => {
        enqueueSnackbar("Failed to fetch requests", { variant: "error" });
      },
    });
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearchChange = (event) => {
    setQuery(event.target.value);
  };

  const filteredGreyscaleData = greyscaleData.filter((item) => {
    const matchesQuery = item.event_name
      .toLowerCase()
      .includes(query.toLowerCase());

    return matchesQuery;
  });

  const startIndex = (page - 1) * itemsPerPage;
  const paginatedData = filteredGreyscaleData.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <Container maxWidth="xl" sx={{ paddingY: `5rem`, overflowY: "auto" }}>
      <AppHeader />
      <Box sx={{ display: "flex", flexDirection: "column", padding: "2rem" }}>
        <Stack gap={4}>
          <Grid
            container
            spacing={6}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} md={3}>
              <Typography variant="h3" fontWeight="800">
                Greyscale Data
              </Typography>
              <Typography variant="h6">Click to view</Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                label="Search"
                variant="outlined"
                fullWidth
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { borderRadius: "20px" },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {paginatedData.map((item) => (
              <GreyscaleCard
                key={item.s3_prefix}
                Title={item.event_name}
                Description={item.s3_prefix} // Customize based on your data
                LastUpdated={item.processing_date}
                URL={item.url}
                // Tags={capitalizeEachWord(item.s3_prefix.split("_"))}
                onClick={() => {
                  Navigate(
                    getDynamicRoute("greyscalethresholding", item.s3_prefix)
                  );
                }}
              />
            ))}
          </Grid>

          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <Pagination
              color="primary"
              count={Math.ceil(filteredGreyscaleData.length / itemsPerPage)}
              page={page}
              onChange={handlePageChange}
              size="large"
            />
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};

export default GreyscalePage;
