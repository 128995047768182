import EOS_RS_LOGO from "assets/EOS-RS-Logo.png";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import NTU_LOGO from "assets/NTU-Logo.png";
import EOS_LOGO_WHITE from "assets/EOS-Logo-White.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Box, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import TypographyAtom from "./TypographyAtom";

const fontWeight = 800;
const fontFamily = "Myriad Pro Bold";

const linkStyle = {
  color: "white",
  "&:hover": {
    backgroundColor: "transparent",
    color: "#ffbd59FF",
  },
  cursor: "pointer",
  fontFamily: fontFamily,
  fontWeight: fontWeight,
  lineHeight: 2,
  textDecoration: "none",
  boxShadow: "none",
};

const footerStyle = {
  color: "white",
  fontFamily: fontFamily,
  fontWeight: fontWeight,
  lineHeight: 2,
  textDecoration: "none",
  boxShadow: "none",
};

const iconStyle = { color: "#FFFFFF", fontSize: "25px" };

const CustomFooter = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(#032852,#021122 73.22%,#010810)",
        minHeight: "200px",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 4,
      }}
    >
      <Grid container rowSpacing={4}>
        <Grid
          item
          md={3}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <img src={EOS_LOGO_WHITE} height={"80px"} />
        </Grid>

        <Grid
          item
          md={3}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Stack>
            <Link href="mailto:eos-rs@ntu.edu.sg" sx={linkStyle}>
              Contact us
            </Link>
            <Link href="https://earthobservatory.sg" sx={linkStyle}>
              Earth Observatory of Singapore
            </Link>
            <Link href="https://www.ntu.edu.sg/ase" sx={linkStyle}>
              Asian School of the Environment
            </Link>
          </Stack>
        </Grid>

        <Grid
          item
          md={3}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack>
            <TypographyAtom sx={{ fontWeight: 1000 }} style={footerStyle}>
              Follow Us
            </TypographyAtom>
            <Stack direction={"row"} spacing={1}>
              {/* <Link
        href="https://x.com/eos_rs?lang=en"
        target="_blank"
        rel="noopener noreferrer"
      >
        <YouTubeIcon style={iconStyle} />
      </Link>
  
      <Link
        href="https://x.com/eos_rs?lang=en"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InstagramIcon style={iconStyle} />
      </Link> */}

              <Link
                href="https://x.com/eos_rs?lang=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon style={iconStyle} />
              </Link>

              {/* <Link
        href="https://x.com/eos_rs?lang=en"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FacebookIcon style={iconStyle} />
      </Link> */}
            </Stack>
          </Stack>
        </Grid>

        <Grid
          item
          md={3}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack>
            <TypographyAtom sx={{ fontWeight: 1000 }} style={footerStyle}>
              An institute of
            </TypographyAtom>
            <img src={NTU_LOGO} style={{ width: "auto", height: "60px" }} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomFooter;
