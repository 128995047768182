import EarthquakeIcon from "assets/MarkerIcons/earthquake.png";
import FloodIcon from "assets/MarkerIcons/flood.png";
import WildfireIcon from "assets/MarkerIcons/wildfire.png";
import CycloneIcon from "assets/MarkerIcons/cyclone.png";
import TsunamiIcon from "assets/MarkerIcons/tsunami.png";
import VolcanoIcon from "assets/MarkerIcons/volcano.png";
import LandslideIcon from "assets/MarkerIcons/landslide.png";
import DefaultIcon from "assets/MarkerIcons/default.png";
import L from "leaflet";

export const typeIcons = {
  explosion: WildfireIcon,
  wildfire: WildfireIcon,
  flood: FloodIcon,
  cyclone: CycloneIcon,
  earthquake: EarthquakeIcon,
  typhoon: CycloneIcon,
  landslide: LandslideIcon,
  volcano: VolcanoIcon,
  default: DefaultIcon,
};

export const getTypeIcon = (eventType) =>
  typeIcons[eventType] || typeIcons.default;

export const eventIcons = {
  explosion: (eventNumber) =>
    L.divIcon({
      html: `<div class="circle-icon" style="background-color: #ff4d4d; width: 20px; height: 20px; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: white; font-size: 12px; font-weight: bold;">
              ${eventNumber}
            </div>`,
      iconSize: [20, 20],
      className: "custom-icon",
    }),
  wildfire: (eventNumber) =>
    L.divIcon({
      html: `<div class="circle-icon" style="background-color: #ff8c42; width: 20px; height: 20px; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: white; font-size: 12px; font-weight: bold;">
              ${eventNumber}
            </div>`,
      iconSize: [20, 20],
      className: "custom-icon",
    }),
  flood: (eventNumber) =>
    L.divIcon({
      html: `<div class="circle-icon" style="background-color: #4d9fd1; width: 20px; height: 20px; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: white; font-size: 12px; font-weight: bold;">
              ${eventNumber}
            </div>`,
      iconSize: [20, 20],
      className: "custom-icon",
    }),
  cyclone: (eventNumber) =>
    L.divIcon({
      html: `<div class="circle-icon" style="background-color: #f4d03f; width: 20px; height: 20px; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: white; font-size: 12px; font-weight: bold;">
              ${eventNumber}
            </div>`,
      iconSize: [20, 20],
      className: "custom-icon",
    }),
  earthquake: (eventNumber) =>
    L.divIcon({
      html: `<div class="circle-icon" style="background-color: #4caf50; width: 20px; height: 20px; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: white; font-size: 12px; font-weight: bold;">
              ${eventNumber}
            </div>`,
      iconSize: [20, 20],
      className: "custom-icon",
    }),
  typhoon: (eventNumber) =>
    L.divIcon({
      html: `<div class="circle-icon" style="background-color: #8e44ad; width: 20px; height: 20px; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: white; font-size: 12px; font-weight: bold;">
              ${eventNumber}
            </div>`,
      iconSize: [20, 20],
      className: "custom-icon",
    }),
  landslide: (eventNumber) =>
    L.divIcon({
      html: `<div class="circle-icon" style="background-color: #9e7d52; width: 20px; height: 20px; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: white; font-size: 12px; font-weight: bold;">
              ${eventNumber}
            </div>`,
      iconSize: [20, 20],
      className: "custom-icon",
    }),
  volcano: (eventNumber) =>
    L.divIcon({
      html: `<div class="circle-icon" style="background-color: #e74c3c; width: 20px; height: 20px; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: white; font-size: 12px; font-weight: bold;">
              ${eventNumber}
            </div>`,
      iconSize: [20, 20],
      className: "custom-icon",
    }),
  default: (eventNumber) =>
    L.divIcon({
      html: `<div class="circle-icon" style="background-color: #95a5a6; width: 20px; height: 20px; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: white; font-size: 12px; font-weight: bold;">
              ${eventNumber}
            </div>`,
      iconSize: [20, 20],
      className: "custom-icon",
    }),
};

export const getEventIcon = (eventType, eventNumber) =>
  (eventIcons[eventType] || eventIcons.default)(eventNumber);
// Custom rectangular collision force that takes into account label width/height.
