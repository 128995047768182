import Editor from "@monaco-editor/react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import AppHeader from "features/AppHeader/AppHeader";
import { useSnackbar } from "notistack";
import { useUser } from "provider/auth/useUser";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialOceanic } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useCallAPI } from "utils/helper";
import { getRoute } from "utils/routes";

import "leaflet/dist/leaflet.css";
import { Polygon, TileLayer } from "react-leaflet";

import {
  Cancel,
  CheckCircle,
  Close,
  Edit,
  Save,
  Visibility,
} from "@mui/icons-material";
import ButtonAtom from "components/ButtonAtom";
import TypographyAtom from "components/TypographyAtom";
import MapView from "features/MapPage/Map/MapView";
import { useMap } from "react-leaflet";

const JsonEditor = ({ value, onChange, error, selectedRequest }) => {
  const handleEditorChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <>
      <Editor
        height="380px"
        defaultLanguage="json"
        value={value || JSON.stringify(selectedRequest?.variable_data, null, 2)}
        onChange={handleEditorChange}
        theme="vs-dark"
        options={{
          minimap: { enabled: false },
          lineNumbers: "on",
          roundedSelection: false,
          scrollBeyondLastLine: false,
          readOnly: false,
          fontSize: 14,
          tabSize: 2,
          wordWrap: "on",
          formatOnPaste: true,
          formatOnType: true,
        }}
      />
      {error && (
        <TypographyAtom color={"red"} sx={{ marginTop: "8px" }}>
          {error}
        </TypographyAtom>
      )}
    </>
  );
};

const MapWithBounds = ({ polygonBoxes }) => {
  const map = useMap();

  useEffect(() => {
    if (polygonBoxes && polygonBoxes.length > 0) {
      const bounds = polygonBoxes.flat().map((coord) => [coord[1], coord[0]]);
      map.fitBounds(bounds);
    }
  }, [map, polygonBoxes]);

  return null;
};

const getStatusColor = (status, theme) => {
  switch (status) {
    case "approved":
      return theme.palette.success.main;
    case "rejected":
      return theme.palette.error.main;
    case "pending":
      return theme.palette.warning.main;
    case "waiting":
    case "processing":
      return theme.palette.info.main;
    case "cancelled":
      return theme.palette.grey[500];
    default:
      return theme.palette.text.primary;
  }
};

const AdminPage = () => {
  const theme = useTheme();

  const userQuery = useUser();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [requests, setRequests] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    action: null,
    requestId: null,
  });

  // COPY THIS INTO A COMPONENT
  const [isEditMode, setIsEditMode] = useState(false);

  const editJob = useCallAPI("editJob", "/sarfinder/submit-job/", "PUT");

  const [jsonValue, setJsonValue] = useState("");
  const [jsonError, setJsonError] = useState(null);

  const handleJsonChange = (newValue) => {
    setJsonValue(newValue);

    try {
      const parsedJson = JSON.parse(newValue);
      // const formattedJson = JSON.stringify(parsedJson, null, 2);

      setJsonError(null);
      setSelectedRequest({
        ...selectedRequest,
        variable_data: parsedJson,
      });
    } catch (error) {
      setJsonError("Invalid JSON format");
    }
  };

  const handleInputChange = (field, value) => {
    setSelectedRequest({
      ...selectedRequest,
      [field]: value,
    });
  };

  const handleSave = () => {
    // Implement save logic here
    console.log("Saved data:", selectedRequest);
    if (jsonError) {
      enqueueSnackbar("JSON is improperly formatted", { variant: "error" });
      return;
    }

    const requestBody = {
      pk: selectedRequest?.id,
      submission_type: selectedRequest?.submission_type,
      variable_data: selectedRequest?.variable_data,
      priority: selectedRequest?.priority,
    };
    editJob.mutate(requestBody, {
      onSuccess: (data) => {
        enqueueSnackbar(
          `Job edited successfully, Job ID: ${data.submission_id}`,
          { variant: "success" }
        );
      },
      onError: (error) => {
        console.error("Error editing job:", error);
        enqueueSnackbar("Job edit failed", { variant: "error" });
      },
    });
    setIsEditMode(false);
  };

  // COPY THIS INTO A COMPONENT

  const handleConfirmOpen = (id, action) => {
    setConfirmDialog({ open: true, action, requestId: id });
  };

  const handleConfirmClose = () => {
    setConfirmDialog({ open: false, action: null, requestId: null });
  };

  const handleConfirmAction = () => {
    if (confirmDialog.requestId && confirmDialog.action) {
      handleStatusChange(confirmDialog.requestId, confirmDialog.action);
    }
    handleConfirmClose();
  };

  const handleClickOpenRequest = (request) => {
    setSelectedRequest(request);
    setOpen(true);
  };

  const handleClickCloseRequest = () => {
    setOpen(false);
    setIsEditMode(false);

    setSelectedRequest(null);
  };

  // const handleStatusChange = (id, status) => {
  //   // Handle status change logic here
  // };

  const fetchRequests = useCallAPI(
    `fetchRequests`,
    `/sarfinder/jobs/list-job-submissions/`,
    "GET"
  );

  const approveJobs = useCallAPI(
    `approveJobs`,
    "/sarfinder/jobs/approve-and-run-jobs/",
    "POST"
  );

  const cancelJobs = useCallAPI(
    `cancelJobs`,
    "/sarfinder/jobs/cancel-jobs/",
    "POST"
  );

  const rejectJobs = useCallAPI(
    `rejectJobs`,
    "/sarfinder/jobs/reject-jobs/",
    "POST"
  );

  useEffect(() => {
    if (
      !userQuery.isPending &&
      !userQuery.data?.username &&
      !(userQuery.data?.is_staff || userQuery.data?.is_superuser)
    ) {
      navigate(getRoute("home"));
    }
  }, [userQuery, navigate]);

  useEffect(() => {
    fetchRequests.mutate(null, {
      onSuccess: (data) => {
        setRequests(data);
      },
      onError: (error) => {
        enqueueSnackbar("Failed to fetch requests", { variant: "error" });
      },
    });
  }, []);

  const handleStatusChange = (id, action) => {
    let data = { job_ids: [id] };

    switch (action) {
      case "approve":
        approveJobs.mutate(data, {
          onSuccess: (data) => {
            setRequests(data?.job_submissions);
          },
          onError: (error) => {
            enqueueSnackbar("Failed to fetch requests", { variant: "error" });
          },
        });
        break;
      case "cancel":
        cancelJobs.mutate(data, {
          onSuccess: (data) => {
            setRequests(data?.job_submissions);
          },
          onError: (error) => {
            enqueueSnackbar("Failed to fetch requests", { variant: "error" });
          },
        });
        break;
      case "reject":
        rejectJobs.mutate(data, {
          onSuccess: (data) => {
            setRequests(data?.job_submissions);
          },
          onError: (error) => {
            enqueueSnackbar("Failed to fetch requests", { variant: "error" });
          },
        });
        break;
      default:
        enqueueSnackbar("Invalid status", { variant: "error" });
        return;
    }

    // updateRequestStatus.mutate(
    //   { endpoint, payload: data },
    //   {
    //     onSuccess: (response) => {
    //       enqueueSnackbar("Request status updated successfully", {
    //         variant: "success",
    //       });
    //       setRequests((prevRequests) =>
    //         prevRequests.map((request) =>
    //           request.id === id ? { ...request, status } : request
    //         )
    //       );
    //     },
    //     onError: (error) => {
    //       enqueueSnackbar(`Failed to update request status: ${error}`, {
    //         variant: "error",
    //       });
    //     },
    //   }
    // );
  };

  return (
    <Container maxWidth="xl" sx={{ paddingY: `5rem`, overflowY: "auto" }}>
      <AppHeader />
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" gutterBottom>
          Job Submission Requests
        </Typography>
        {fetchRequests.isPending ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Request ID</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Directory</TableCell>
                  <TableCell>Submission Type</TableCell>
                  <TableCell>Date Submitted</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Number of SLCs</TableCell>
                  <TableCell>Estimated Duration</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requests
                  .slice()
                  .sort(
                    (a, b) =>
                      new Date(b.date_submitted) - new Date(a.date_submitted)
                  )
                  .map((request) => (
                    <TableRow
                      key={request.id}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#000000",
                        },
                      }}
                    >
                      <TableCell>{request.id}</TableCell>
                      <TableCell>{request.user || "N/A"}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            padding: 2,
                            marginTop: 2,
                            backgroundColor: theme.palette.background.default,
                            borderRadius: 1,
                          }}
                        >
                          <Typography variant="body2">
                            {request?.variable_data.dir_name}
                          </Typography>
                          {/* Add more data fields as needed */}
                        </Box>
                      </TableCell>
                      <TableCell>{request.submission_type}</TableCell>
                      <TableCell>
                        {new Date(request.date_submitted).toLocaleString()}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: getStatusColor(request.status.value, theme),
                          fontWeight: "bold",
                        }}
                      >
                        {request.status.display}
                      </TableCell>
                      <TableCell>{request.priority}</TableCell>
                      <TableCell>{request.number_of_slcs}</TableCell>
                      <TableCell>{request.estimated_duration}</TableCell>
                      <TableCell>
                        <Tooltip title="View Details" placement="top">
                          <IconButton
                            onClick={() => handleClickOpenRequest(request)}
                            sx={{
                              mr: 1,
                              backgroundColor: "#1976d2", // Primary color
                              "&:hover": {
                                backgroundColor: "#1565c0", // Darker on hover
                              },
                              padding: "8px",
                              borderRadius: "8px",
                            }}
                          >
                            <Visibility sx={{ color: "white" }} />
                          </IconButton>
                        </Tooltip>

                        {request.status.value === "pending" && (
                          <>
                            {/* Approve Button */}
                            <Tooltip title="Approve" placement="top">
                              <IconButton
                                onClick={() =>
                                  handleStatusChange(request.id, "approve")
                                }
                                sx={{
                                  mr: 1,
                                  backgroundColor: "#4caf50", // Green for approve
                                  "&:hover": {
                                    backgroundColor: "#388e3c", // Darker on hover
                                  },
                                  padding: "8px",
                                  borderRadius: "8px",
                                }}
                              >
                                <CheckCircle sx={{ color: "white" }} />
                              </IconButton>
                            </Tooltip>

                            {/* Reject Button */}
                            <Tooltip title="Reject" placement="top">
                              <IconButton
                                onClick={() =>
                                  handleConfirmOpen(request.id, "reject")
                                }
                                sx={{
                                  mr: 1,
                                  backgroundColor: "#f44336", // Red for reject
                                  "&:hover": {
                                    backgroundColor: "#d32f2f", // Darker on hover
                                  },
                                  padding: "8px",
                                  borderRadius: "8px",
                                }}
                              >
                                <Close sx={{ color: "white" }} />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                        {/* Cancel Button for 'waiting' or 'processing' */}
                        {(request.status.value === "waiting" ||
                          request.status.value === "processing") && (
                          <Tooltip title="Cancel" placement="top">
                            <IconButton
                              onClick={() =>
                                handleConfirmOpen(request.id, "cancel")
                              }
                              sx={{
                                mr: 1,
                                backgroundColor: "#ff9800", // Yellow for cancel
                                "&:hover": {
                                  backgroundColor: "#f57c00", // Darker on hover
                                },
                                padding: "8px",
                                borderRadius: "8px",
                              }}
                            >
                              <Cancel sx={{ color: "white" }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={handleClickCloseRequest}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
            <Box>
              <TypographyAtom variant="h5">
                Job Submission Request Details
              </TypographyAtom>

              <TypographyAtom
                sx={{
                  color: getStatusColor(selectedRequest?.status.value, theme),
                  fontWeight: "bold",
                }}
              >
                Status: {selectedRequest?.status.display}
              </TypographyAtom>
            </Box>
            <Stack flexDirection={"row"} gap={1}>
              <ButtonAtom
                variant={isEditMode ? "contained" : "outlined"}
                color={isEditMode ? "subtle" : "primary"}
                startIcon={<Edit />}
                onClick={() => setIsEditMode(!isEditMode)}
              >
                {isEditMode ? "Cancel" : "Edit"}
              </ButtonAtom>
              {isEditMode && (
                <ButtonAtom
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  startIcon={<Save />}
                >
                  Save
                </ButtonAtom>
              )}
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  {isEditMode ? (
                    <>
                      <Divider sx={{ my: 1 }} />
                      <TextField
                        label="Submission Type"
                        value={selectedRequest?.submission_type}
                        onChange={(e) =>
                          handleInputChange("submission_type", e.target.value)
                        }
                        fullWidth
                        margin="small"
                      />
                      <Divider sx={{ my: 1 }} />
                      <TextField
                        label="Priority"
                        value={selectedRequest?.priority}
                        onChange={(e) =>
                          handleInputChange("priority", e.target.value)
                        }
                        fullWidth
                        margin="small"
                      />
                      <Divider sx={{ my: 1 }} />

                      <TextField
                        label="Estimated Duration"
                        value={selectedRequest?.estimated_duration}
                        onChange={(e) =>
                          handleInputChange(
                            "estimated_duration",
                            e.target.value
                          )
                        }
                        fullWidth
                        margin="small"
                      />
                      <Divider sx={{ my: 1 }} />
                      <TextField
                        label="Feedback"
                        value={selectedRequest?.feedback}
                        onChange={(e) =>
                          handleInputChange("feedback", e.target.value)
                        }
                        fullWidth
                        margin="small"
                      />
                      <Divider sx={{ my: 1 }} />
                      <TextField
                        label="Request Comment"
                        value={selectedRequest?.request_comment}
                        onChange={(e) =>
                          handleInputChange("request_comment", e.target.value)
                        }
                        fullWidth
                        margin="small"
                      />
                    </>
                  ) : (
                    <>
                      <Typography variant="h6">
                        User: {selectedRequest?.user}
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="h6">
                        Submission Type: {selectedRequest?.submission_type}
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="h6">
                        Date Submitted:{" "}
                        {new Date(
                          selectedRequest?.date_submitted
                        ).toLocaleString()}
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="h6">
                        Priority: {selectedRequest?.priority}
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="h6">
                        Number of SLCs: {selectedRequest?.number_of_slcs}
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="h6">
                        Estimated Duration:{" "}
                        {selectedRequest?.estimated_duration}
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="h6">
                        Feedback: {selectedRequest?.feedback}
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="h6">
                        Request Comment: {selectedRequest?.request_comment}
                      </Typography>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <MapView
                style={{ height: "400px", width: "100%" }}
                center={[0, 0]}
                zoom={13}
                minimapView={false}
                showSearch={false}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {selectedRequest?.polygon_boxes &&
                  Array.isArray(selectedRequest?.polygon_boxes) &&
                  selectedRequest?.polygon_boxes.map((polygon, index) => (
                    <Polygon
                      key={index}
                      positions={polygon.map((coord) => [coord[1], coord[0]])}
                    />
                  ))}
                <MapWithBounds polygonBoxes={selectedRequest?.polygon_boxes} />
              </MapView>
            </Grid>
            <Grid item xs={12}>
              {isEditMode ? (
                <JsonEditor
                  value={jsonValue}
                  onChange={handleJsonChange}
                  error={jsonError}
                  selectedRequest={selectedRequest}
                />
              ) : (
                // <TextField
                //   label="Variable Data (JSON)"
                //   value={
                //     jsonValue ||
                //     JSON.stringify(selectedRequest?.variable_data, null, 2)
                //   }
                //   onChange={handleJsonChange}
                //   fullWidth
                //   multiline
                //   helperText={jsonError}
                //   rows={20}
                //   margin="normal"
                // />
                <SyntaxHighlighter
                  language="json"
                  style={materialOceanic}
                  customStyle={{
                    backgroundColor: theme.palette.background.default,
                    padding: "16px",
                    borderRadius: "8px",
                    fontSize: "0.9rem",
                    overflowX: "auto",
                  }}
                >
                  {JSON.stringify(selectedRequest?.variable_data, null, 2)}
                </SyntaxHighlighter>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={confirmDialog.open} onClose={handleConfirmClose}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to {confirmDialog.action} this request?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmAction} color="secondary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminPage;
