import CloseIcon from "@mui/icons-material/Close";
import GetAppIcon from "@mui/icons-material/GetApp";
import SatelliteAltRoundedIcon from "@mui/icons-material/SatelliteAltRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
} from "@mui/material";
import ButtonAtom from "components/ButtonAtom";
import { CustomPopup } from "components/CustomCard";
import TypographyAtom from "components/TypographyAtom";
import { handleDownload } from "utils/helper";

const DownloadsPopup = ({ open, downloads, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} sx={{ zIndex: 100000 }}>
      <CustomPopup sx={{ maxHeight: "80%", width: "80%" }}>
        <TypographyAtom color={"primary"} variant="h4" fontWeight="400">
          Downloads
        </TypographyAtom>
        <List sx={{ overflowY: "scroll" }}>
          {downloads.map((item, index) => (
            <>
              <ListItem key={index}>
                <SatelliteAltRoundedIcon
                  color="primary"
                  sx={{ marginRight: "1rem" }}
                />

                <ListItemText>
                  <TypographyAtom sx={{ wordBreak: "break-all" }}>
                    {item.prod_name}
                  </TypographyAtom>
                </ListItemText>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ButtonAtom
                    aria-label={`Download PNG - ${item.prod_name}`}
                    onClick={() => handleDownload(item.prod_main_png)}
                  >
                    <GetAppIcon />
                    <TypographyAtom variant="body2">PNG</TypographyAtom>
                  </ButtonAtom>
                  <ButtonAtom
                    aria-label={`Download TIFF - ${item.prod_name}`}
                    onClick={() => handleDownload(item.prod_tif)}
                  >
                    <GetAppIcon />
                    <TypographyAtom variant="body2">TIFF</TypographyAtom>
                  </ButtonAtom>
                  {item.private_files &&
                    Object.keys(item.private_files).some((key) =>
                      key.endsWith(".tif")
                    ) && (
                      <ButtonAtom
                        aria-label={`Download Greyscale TIFF - ${item.prod_name}`}
                        onClick={() => {
                          const greyscaleTifKey = Object.keys(
                            item.private_files
                          ).find((key) => key.endsWith(".tif"));
                          handleDownload(item.private_files[greyscaleTifKey]);
                        }}
                      >
                        <GetAppIcon />
                        <TypographyAtom variant="body2">
                          Greyscale TIFF
                        </TypographyAtom>
                      </ButtonAtom>
                    )}
                  <ButtonAtom
                    aria-label={`Download KMZ - ${item.prod_name}`}
                    onClick={() => handleDownload(item.prod_kmz)}
                  >
                    <GetAppIcon />
                    <TypographyAtom variant="body2">KMZ</TypographyAtom>
                  </ButtonAtom>
                </div>
              </ListItem>
              {item?.cvd_prod_tiles ? (
                <ListItem key={index} sx={{ display: "flex" }}>
                  <VisibilityRoundedIcon
                    color="navyblue"
                    sx={{ marginRight: "1rem" }}
                  />
                  <ListItemText>
                    <TypographyAtom sx={{ wordBreak: "break-all" }}>
                      {item.cvd_prod_name}
                    </TypographyAtom>
                  </ListItemText>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ButtonAtom
                      color="navyblue"
                      aria-label={`Download CVD PNG - ${item.cvd_prod_name}`}
                      onClick={() => handleDownload(item.cvd_prod_main_png)}
                    >
                      <GetAppIcon />
                      <TypographyAtom variant="body2">PNG</TypographyAtom>
                    </ButtonAtom>
                    <ButtonAtom
                      color="navyblue"
                      aria-label={`Download CVD TIFF - ${item.cvd_prod_name}`}
                      onClick={() => handleDownload(item.cvd_prod_tif)}
                    >
                      <GetAppIcon />
                      <TypographyAtom variant="body2">TIFF</TypographyAtom>
                    </ButtonAtom>
                    <ButtonAtom
                      color="navyblue"
                      aria-label={`Download CVD KMZ - ${item.cvd_prod_name}`}
                      onClick={() => handleDownload(item.cvd_prod_kmz)}
                    >
                      <GetAppIcon />
                      <TypographyAtom variant="body2">KMZ</TypographyAtom>
                    </ButtonAtom>
                  </div>
                </ListItem>
              ) : (
                <></>
              )}

              <Divider />
            </>
          ))}
        </List>
        <IconButton
          aria-label="Close"
          style={{ position: "absolute", top: 5, right: 5 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </CustomPopup>
    </Modal>
  );
};

export default DownloadsPopup;
