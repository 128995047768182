import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Grid,
  Stack,
  Chip,
  Skeleton,
  Box,
} from "@mui/material";
import ScaleUpOnHover from "utils/Animations/ScaleUpOnHover";
import { formatDate } from "utils/helper";
import TypographyAtom from "components/TypographyAtom";
import { ImageTwoTone } from "@mui/icons-material";
import { getTypeIcon } from "components/EventProductIcons/EventProductIcons";

export const getEventTypeFromName = (eventName) => {
  if (!eventName) return "default";
  const lowerName = eventName.toLowerCase();

  // Mapping each event type to an array of possible keywords
  const eventTypeMapping = {
    explosion: ["explosion", "blast"],
    wildfire: ["wildfire", "inferno", "fire", "fires"],
    flood: ["flood", "inundation"],
    cyclone: ["cyclone", "hurricane"],
    earthquake: ["earthquake", "seismic"],
    typhoon: ["typhoon"],
    landslide: ["landslide", "mudslide"],
    volcano: ["volcano", "eruption"],
  };

  for (const [eventType, keywords] of Object.entries(eventTypeMapping)) {
    for (const keyword of keywords) {
      if (lowerName.includes(keyword)) {
        return eventType;
      }
    }
  }
  return "default";
};

const GreyscaleCard = ({
  Title,
  EventDate,
  Description,
  Tags,
  LastUpdated,
  onClick,
  HasGreyscale,
}) => {
  const [loading, setLoading] = useState(true);

  return (
    <Grid item xs={12} sm={6} md={3} lg={3}>
      <ScaleUpOnHover>
        <Card
          onClick={onClick}
          sx={{
            borderRadius: 2,
            boxShadow: 3,
            cursor: "pointer",
            transition: "transform 0.3s ease-in-out",
            backgroundColor: "background.paper",
            "&:hover": { boxShadow: 6 },
          }}
        >
          <CardContent
            sx={{ display: "flex", flexDirection: "column", gap: 1 }}
          >
            {/* Icon and Title placed side-by-side */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mb: 1,
              }}
            >
              <Box
                component="img"
                src={getTypeIcon(
                  getEventTypeFromName(Title?.replace("EOS-RS ", ""))
                )}
                alt={getEventTypeFromName(Title?.replace("EOS-RS ", ""))}
                sx={{ width: 40, height: 40 }}
              />
              <TypographyAtom
                variant="h6"
                color="text.primary"
                fontWeight="bold"
                sx={{
                  whiteSpace: "normal",
                  wordBreak: "break-word", // Ensures long words wrap properly
                  overflow: "hidden", // Prevents horizontal scrolling
                }}
              >
                {Title?.replace("EOS-RS ", "")}
              </TypographyAtom>
            </Box>

            {EventDate && (
              <TypographyAtom variant="body2" color="text.secondary">
                {`Event Date: ${formatDate(EventDate)}`}
              </TypographyAtom>
            )}
            <TypographyAtom variant="body2" color="text.primary" noWrap>
              {Description?.length > 100
                ? `${Description.slice(0, 100)}...`
                : Description}
            </TypographyAtom>
            <Stack direction="row" spacing={1} flexWrap="wrap" mt={1}>
              {Tags?.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  size="small"
                  sx={{
                    backgroundColor: "grey.200",
                    color: "text.primary",
                    fontSize: 10,
                  }}
                />
              ))}
            </Stack>
            <TypographyAtom variant="caption" color="text.secondary" mt={1}>
              {`Map generated on ${formatDate(LastUpdated)}`}
            </TypographyAtom>
          </CardContent>
        </Card>
      </ScaleUpOnHover>
    </Grid>
  );
};

export default GreyscaleCard;
