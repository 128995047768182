import L, { DomEvent } from "leaflet"; // Import leaflet library
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
// import { leafletLayer, PolygonSymbolizer } from "protomaps-leaflet";
import { useMap } from "react-leaflet";
import "./MapView.css"; // Create this file for custom animations

import SearchIcon from "@mui/icons-material/Search";
import CustomCard from "components/CustomCard";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  FeatureGroup,
  LayerGroup,
  LayersControl,
  MapContainer,
  TileLayer,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import ButtonAtom from "components/ButtonAtom";
import TypographyAtom from "components/TypographyAtom";
import useScreenSize from "hooks/useScreenSize";
import { leafletLayer, PolygonSymbolizer } from "protomaps-leaflet";
import { calculateArea, formatNumber } from "utils/helper";
import { BboxWktTool, MeasureTool, RightClickCoordinates } from "./MapTools";
import { MinimapControl } from "./MinimapControl";

const ProtomapsLayer = ({ url }) => {
  const map = useMap();
  // Define the paint rules for styling the buildings
  let PAINT_RULES = [
    {
      dataLayer: "building",
      symbolizer: new PolygonSymbolizer({
        fill: "transparent", // Fill color
        stroke: "blue", // Outline color
        width: (zoom) => {
          // Dynamic width based on zoom
          if (zoom > 18) return 2;
          if (zoom > 16) return 1.5;
          return 1;
        },
      }),
    },
  ];
  const layer = useMemo(() => {
    return leafletLayer({
      url: url,
      zIndex: 1000,
      maxDataZoom: 14,
      maxNativeZoom: 24,

      updateWhenIdle: true,
      updateWhenZooming: false,
      keepBuffer: 2, // Increase buffer to reduce tile loading frequency
      useCache: true,
      crossOrigin: true,
      rendererFactory: L.canvas.tile,
      vectorTileLayerStyles: {
        building: {
          weight: 1,
          fillOpacity: 0.4,
          color: "red",
        },
      },
      tolerance: 3,

      paintRules: PAINT_RULES,
    });
  }, [url]); // Only recreate if url changes

  useEffect(() => {
    if (map && layer) {
      layer.addTo(map);
      return () => {
        map.removeLayer(layer);
      };
    }
  }, [map, layer]); // Only re-run if map or layer changes

  return null;
};

function MapSearchControl() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const { isDesktopView } = useScreenSize();

  const map = useMap();

  const coordinateMatchRegex = useMemo(
    () => /^(-?\d+(\.\d+)?)[, ]\s*(-?\d+(\.\d+)?)$/,
    []
  );

  const handleSearch = useCallback(async () => {
    if (!searchQuery) return;

    // Check if input is coordinates
    const coordinateMatch = searchQuery.trim().match(coordinateMatchRegex);

    if (coordinateMatch) {
      const lat = parseFloat(coordinateMatch[1]);
      const lon = parseFloat(coordinateMatch[3]);

      if (lat >= -90 && lat <= 90 && lon >= -180 && lon <= 180) {
        map.setView([lat, lon], 18);
        return;
      }
    }

    // Perform location search
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
        searchQuery
      )}&format=geojson`
    );
    const data = await response.json();

    if (data.features.length > 0) {
      setSearchResults(data.features);
      setOpenDropdown(true); // Open the dropdown automatically
    }
  }, [searchQuery, map, coordinateMatchRegex]);

  const handleResultSelect = useCallback(
    (event, newValue) => {
      setSelectedResult(newValue);

      if (newValue) {
        const selectedFeature = searchResults.find(
          (feature) => feature.properties.display_name === newValue
        );

        if (selectedFeature?.bbox) {
          map.fitBounds([
            [selectedFeature.bbox[1], selectedFeature.bbox[0]],
            [selectedFeature.bbox[3], selectedFeature.bbox[2]],
          ]);
        }
      }
      setOpenDropdown(false); // Close dropdown after selection
    },
    [searchResults, map]
  );

  return (
    <CustomCard
      className={"leaflet-ui"}
      elevation={3}
      sx={{
        position: "absolute",
        top: 10,
        left: 75,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "6px 10px",
        borderRadius: 3,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
      }}
    >
      <Autocomplete
        freeSolo
        options={searchResults.map((result) => result.properties.display_name)}
        value={selectedResult}
        open={openDropdown}
        // onOpen={() => setOpenDropdown(true)}
        // onClose={() => setOpenDropdown(false)}
        onInputChange={(event, newValue) => setSearchQuery(newValue)}
        onChange={handleResultSelect}
        PaperComponent={({ children }) => (
          <CustomCard
            sx={{
              borderRadius: 2,
              boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.12)",
            }}
          >
            {children}
          </CustomCard>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            variant="outlined"
            placeholder="Search location"
            sx={{
              width: isDesktopView ? 250 : 125,
              "& fieldset": { border: "none" },
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
                console.log("Pressed!");
                setOpenDropdown(true);
              }
            }}
          />
        )}
      />
      <IconButton onClick={handleSearch} color="primary">
        <SearchIcon />
      </IconButton>
    </CustomCard>
  );
}

function MapContents({
  bounds,
  setSelectionArea,
  selectionArea,
  minimapView,
  showSearch,
  enableRectangleSelection,
}) {
  const map = useMap();
  const featureGroupRef = useRef(null);
  const [mouseCoords, setMouseCoords] = useState(null);

  const switchPolygons = (layer) => {
    featureGroupRef.current.clearLayers();
    featureGroupRef.current.addLayer(layer);
  };

  const handleNewPolygonCreation = (e) => {
    const { layer } = e;
    const bounds = layer.getBounds();

    const selectionData = {
      bounds,
      area: calculateArea(bounds).toFixed(2),
    };

    switchPolygons(layer);
    setSelectionArea(selectionData);
  };

  const handlePolygonEdited = (e) => {
    const layers = e.layers;
    layers.eachLayer((layer) => {
      const bounds = layer.getBounds();

      const selectionData = {
        bounds,
        area: calculateArea(bounds).toFixed(2),
      };

      setSelectionArea(selectionData);
    });
  };

  useEffect(() => {
    if (bounds) {
      map.fitBounds(bounds);
    }
  }, [bounds, map]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMouseCoords(e.latlng);
    };

    map.on("mousemove", handleMouseMove);

    return () => {
      map.off("mousemove", handleMouseMove);
    };
  }, [map]);

  useEffect(() => {
    if (selectionArea) {
      const bounds = [
        [
          selectionArea.bounds._southWest.lat,
          selectionArea.bounds._southWest.lng,
        ],
        [
          selectionArea.bounds._southWest.lat,
          selectionArea.bounds._northEast.lng,
        ],
        [
          selectionArea.bounds._northEast.lat,
          selectionArea.bounds._northEast.lng,
        ],
        [
          selectionArea.bounds._northEast.lat,
          selectionArea.bounds._southWest.lng,
        ],
      ];
      const polygon = L.polygon(bounds, { color: "blue" });
      switchPolygons(polygon);
    }
  }, [selectionArea]);

  return (
    <>
      {showSearch && <MapSearchControl />}

      <LayerGroup position="topright">
        <FeatureGroup ref={featureGroupRef}>
          <EditControl
            position="topright"
            onCreated={handleNewPolygonCreation}
            onEdited={handlePolygonEdited}
            draw={{
              rectangle: enableRectangleSelection
                ? {
                    showArea: false,
                    metric: ["km"], // This will show measurements in km²
                    precision: { km: 2 }, // Show 2 decimal places for kilometers
                  }
                : false,
              polygon: false,
              circle: false,
              circlemarker: false,
              marker: false,
              polyline: false,
            }}
            edit={{
              edit: enableRectangleSelection ? true : false,
              remove: enableRectangleSelection ? true : false,
            }}
          />
        </FeatureGroup>
        {minimapView && <MinimapControl position="bottomright" />}
        <ProtomapsLayer url="https://overturemaps-tiles-us-west-2-beta.s3.amazonaws.com/2025-02-19/buildings.pmtiles" />

        <LayersControl>
          <LayersControl.BaseLayer name="Jawg.Matrix">
            <TileLayer
              url="https://tile.jawg.io/jawg-matrix/{z}/{x}/{y}{r}.png?access-token={accessToken}"
              attribution={`<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`}
              maxNativeZoom={20}
              accessToken="fp7uXbBF34PcZi0JgiDprLOSdyVUkVNioPVsElDHC7QA6Gudtdtzu6lLRjZKgq4g"
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer checked name="Google Hybrid Map">
            <TileLayer
              url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
              attribution={`<a href="https://www.crawfordcountypa.net/GIS/Documents/Crawford%20County%20PA%20GIS%20Web_Data%20Disclaimer.pdf?Web=1" title="Crawford County, PA GIS Web/Data" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy;`}
              maxNativeZoom={22}
              maxZoom={24}
              keepBuffer={2}
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="ESRI Satellite">
            <TileLayer
              url="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution={`<a href="hhttps://www.esri.com/en-us/legal/terms/full-master-agreement" title="This work is licensed under the Esri Master License Agreement." target="_blank">&copy; <b>ESRI</b>Maps</a> &copy;`}
              maxNativeZoom={21}
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="CartoDB.DarkMatter">
            <TileLayer
              url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
              attribution={`&copy; <a href="ttps://carto.com/attributions">CartoDB</a> contributors &copy;`}
              subdomains="abcd"
              maxNativeZoom={20}
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="OpenStreetMap">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          </LayersControl.BaseLayer>

          <LayersControl.Overlay name="World Hillshade (Dark)">
            <TileLayer
              url="https://server.arcgisonline.com/arcgis/rest/services/Elevation/World_Hillshade/MapServer/tile/{z}/{y}/{x}"
              attribution={`<a href="https://www.arcgis.com" title="Arcgis" target="_blank">&copy; <b>Arcgis</b>Maps</a> &copy; Sources: Esri, Maxar, Airbus DS, USGS, NGA, NASA, CGIAR, N Robinson, NCEAS, NLS, OS, NMA, Geodatastyrelsen, Rijkswaterstaat, GSA, Geoland, FEMA, Intermap, and the GIS user community contributors`}
              maxNativeZoom={15}
              opacity={0.3} // Adjust the opacity as needed
            />
          </LayersControl.Overlay>
        </LayersControl>
      </LayerGroup>
      {mouseCoords && (
        <CustomCard
          className={"leaflet-ui"}
          elevation={3}
          sx={{
            position: "absolute",
            bottom: 10,
            left: 10,
            padding: "6px 10px",
            borderRadius: 3,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
          }}
        >
          <div>
            Mouse Coordinates: {mouseCoords.lat.toFixed(5)},{" "}
            {mouseCoords.lng.toFixed(5)}
          </div>
        </CustomCard>
      )}

      {selectionArea && (
        <CustomCard
          elevation={3}
          sx={{
            position: "absolute",
            bottom: 50,
            left: 10,
            padding: "6px 10px",
            borderRadius: 3,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
          }}
        >
          <div>Box Coordinates:</div>
          <div>N: {selectionArea.bounds._northEast.lat.toFixed(5)}</div>
          <div>S: {selectionArea.bounds._southWest.lat.toFixed(5)}</div>
          <div>E: {selectionArea.bounds._northEast.lng.toFixed(5)}</div>
          <div>W: {selectionArea.bounds._southWest.lng.toFixed(5)}</div>
          <div>Area: {formatNumber(selectionArea?.area)} km²</div>
          <ButtonAtom
            startIcon={<ContentCopyIcon />}
            variant="outlined"
            onClick={() => {
              // Generate the WKT polygon representing the bounding box
              const wkt = `POLYGON((${selectionArea.bounds._southWest.lng.toFixed(
                5
              )} ${selectionArea.bounds._northEast.lat.toFixed(
                5
              )}, ${selectionArea.bounds._northEast.lng.toFixed(
                5
              )} ${selectionArea.bounds._northEast.lat.toFixed(
                5
              )}, ${selectionArea.bounds._northEast.lng.toFixed(
                5
              )} ${selectionArea.bounds._southWest.lat.toFixed(
                5
              )}, ${selectionArea.bounds._southWest.lng.toFixed(
                5
              )} ${selectionArea.bounds._southWest.lat.toFixed(
                5
              )}, ${selectionArea.bounds._southWest.lng.toFixed(
                5
              )} ${selectionArea.bounds._northEast.lat.toFixed(5)}))`;
              navigator.clipboard.writeText(wkt);
            }}
            sx={{ marginTop: 1, paddingY: 0 }}
          >
            <TypographyAtom variant="subtitle">Copy WKT</TypographyAtom>
          </ButtonAtom>
        </CustomCard>
      )}
    </>
  );
}

export default function MapView({
  setSelectionArea,
  selectionArea,
  setOpenLayers,
  bounds,
  style,
  mapViewRef,
  scrollWheelZoom = true,
  worldCopyJump = true,
  minimapView = false,
  showSearch = true,
  center = [0, 0],
  enableRectangleSelection = false,
  children,
  addLayer,
}) {
  // const [layerSettings, setLayerSettings] = useState([]);

  return (
    <>
      <MapContainer
        className="modern-map"
        style={{
          height: "100%",
          borderRadius: "1rem",
          zIndex: 10,
          ...style,
        }}
        center={bounds ? null : center}
        zoom={bounds ? 10 : 3}
        maxZoom={24}
        minZoom={3}
        zoomSnap={0.5}
        zoomDelta={2}
        scrollWheelZoom={scrollWheelZoom}
        zoomAnimation={true}
        fadeAnimation={true}
        inertia={true}
        inertiaDeceleration={3000}
        bounds={bounds}
        preferCanvas={true}
        worldCopyJump={worldCopyJump}
        ref={mapViewRef}
        // whenCreated={(map) => {
        //   map.zoomControl.setPosition("topright");
        //   L.DomUtil.addClass(map._container, "modern-map-container");
        // }}
      >
        {/* <ProtomapsLayer url="https://overturemaps-tiles-us-west-2-beta.s3.amazonaws.com/2024-10-23/buildings.pmtiles" /> */}

        <MapContents
          bounds={bounds}
          setSelectionArea={setSelectionArea}
          selectionArea={selectionArea}
          minimapView={minimapView}
          showSearch={showSearch}
          enableRectangleSelection={enableRectangleSelection}
        />
        {children}
        <MeasureTool />
        <RightClickCoordinates />
        <BboxWktTool onBoundsChange={setSelectionArea} />
      </MapContainer>
    </>
  );
}
