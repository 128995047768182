import CustomCard from "components/CustomCard";

const { Stack, Popover } = require("@mui/material");
const { default: TypographyAtom } = require("components/TypographyAtom");
const { useState } = require("react");

function UserAccountMolecule({ isLoggedIn, userQuery }) {
  const [anchorEl, setAnchorEl] = useState(null); // State to control Popover visibility

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element when clicked
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the popover
  };

  const open = Boolean(anchorEl); // Determine if popover is open
  const id = open ? "user-account-popover" : undefined;

  return (
    <>
      {isLoggedIn && (
        <Stack
          className="profile-section"
          direction="row"
          alignItems="center"
          sx={{
            backgroundColor: "#333",
            borderRadius: "8px",
            padding: "0.5rem 1rem",
            cursor: "pointer", // Make it clear that it's clickable
          }}
          onClick={handleClick} // Handle click to open popover
        >
          <TypographyAtom
            sx={{
              color: "#ffbd59",
              fontWeight: "bold",
              whiteSpace: "nowrap",
            }}
          >
            {userQuery?.data?.organization}
          </TypographyAtom>
        </Stack>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: { backgroundColor: "transparent", boxShadow: "none" }, // Remove background & shadow
        }}
      >
        <CustomCard
          sx={{
            padding: "16px 24px",
            borderRadius: 2,
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
            minWidth: "250px", // Ensuring a minimum width for the popover
          }}
        >
          <TypographyAtom
            variant="h6"
            sx={{
              marginBottom: "8px", // Space between the title and content
            }}
          >
            User Account
          </TypographyAtom>

          <TypographyAtom
            sx={{
              marginBottom: "6px", // Add some space between each detail
            }}
          >
            <strong>Username:</strong> {userQuery?.data?.username}
          </TypographyAtom>

          <TypographyAtom
            sx={{
              marginBottom: "6px",
            }}
          >
            <strong>Email:</strong> {userQuery?.data?.email}
          </TypographyAtom>

          <TypographyAtom
            sx={{
              marginBottom: "6px",
            }}
          >
            <strong>Organization:</strong> {userQuery?.data?.organization}
          </TypographyAtom>
        </CustomCard>
      </Popover>
    </>
  );
}

export default UserAccountMolecule;
