//Gets sets and removes the Auth Token Retrieved from Strapi
//Stores in local storage
import { useMutation } from "@tanstack/react-query";
import {
  AUTH_TOKEN,
  CSRF_COOKIE_NAME,
  SARFINDER_BE_API_URL,
} from "./constants";
import Cookies from "js-cookie";
import moment from "moment";
import { useMap } from "react-leaflet";
import { useEffect } from "react";
import * as satellite from "satellite.js";

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};
export const handleDownload = (url) => {
  // Implement your download logic here
  window.open(url, "_blank"); // Example: Opens the download URL in a new tab
};

export const parseXmlError = (xmlString) => {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "application/xml");

    const errorInfo = {
      // exceptionCode: exceptionNode.getAttribute("exceptionCode"),
      // locator: exceptionNode.getAttribute("locator"),
      exceptionText:
        xmlDoc.getElementsByTagName("ows:ExceptionText")[0].textContent,
    };

    return errorInfo;
  } catch (error) {
    console.error("Error parsing XML:", error);
    return null;
  }
};

export const formatDetails = (details) => {
  if (!details) return "";
  return Object.entries(details)
    .map(([key, value]) => `${key}: ${value.join(", ")}`)
    .join("; ");
};

export const useCallAPI = (key, endpoint, type = "GET") => {
  return useMutation({
    mutationKey: key,
    mutationFn: async (input) => {
      const response = await fetch(`${SARFINDER_BE_API_URL}${endpoint}`, {
        method: type,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get(CSRF_COOKIE_NAME),
        },
        body: input ? JSON.stringify(input) : undefined,
      });

      if (!response.ok) {
        const errorData = response.status === 400 ? await response.json() : {};
        const errorMessage =
          response.status === 400
            ? `Bad Request: ${errorData.message || "Invalid input"}`
            : "An error occurred while processing the request";
        throw new Error(errorMessage);
      }

      return response.json();
    },
  });
};

export const textToColorHex = (text) => {
  // Array of pastel colors
  const pastelColors = [
    "#FFCCCC",
    "#FFCC99",
    "#FFCCFF",
    "#CCFFCC",
    "#CCFF99",
    "#CCCCFF",
    "#99CCFF",
    "#99CC99",
    "#FF9999",
    "#FF9966",
    "#FFCC66",
    "#FFCCCC",
    "#CCFF99",
    "#FF99CC",
    "#FF9966",
    "#CCFFCC",
    "#FFCC99",
    "#CCCCFF",
    "#99CCFF",
    "#99CC99",
    "#FFCCFF",
    "#FFCC66",
    "#FF99CC",
    "#FF9966",
    "#CCFFCC",
    "#FFCCCC",
    "#FFCC99",
    "#FFCCFF",
    "#CCFFCC",
    "#CCFF99",
    "#CCCCFF",
    "#99CCFF",
    "#99CC99",
    "#FF9999",
    "#FF9966",
    "#FFCC66",
    "#FFCCCC",
    "#CCFF99",
    "#FF99CC",
    "#FF9966",
    "#CCFFCC",
    "#FFCC99",
    "#CCCCFF",
    "#99CCFF",
    "#99CC99",
    "#FFCCFF",
    "#FFCC66",
    "#FF99CC",
    "#FF9966",
    "#CCFFCC",
    // Add more pastel colors as needed
  ];

  var backgroundColor = "#000000";

  if (text === "Damage Proxy Map") backgroundColor = "#ffbd59";
  else if (text === "Flood Proxy Map") backgroundColor = "#8fd2ff";
  else {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Use modulus to select a pastel color from the array
    const colorIndex = Math.abs(hash) % pastelColors.length;

    // Set the selected pastel color as the background
    backgroundColor = pastelColors[colorIndex];

    // Calculate the luminance to determine text color
  }

  const luminance =
    (0.299 * parseInt(backgroundColor.slice(1, 3), 16) +
      0.587 * parseInt(backgroundColor.slice(3, 5), 16) +
      0.114 * parseInt(backgroundColor.slice(5, 7), 16)) /
    255;

  // Set text color based on luminance
  const textColor = luminance > 0.5 ? "#000000" : "#FFFFFF";

  return { backgroundColor, color: textColor };
};

export const formatDate = (inputDate) => {
  const formattedDate = moment(inputDate).format("DD MMMM YYYY");
  return formattedDate;
};

export const capitalizeEachWord = (inputString) => {
  return inputString.replace(/\b\w/g, (match) => match.toUpperCase());
};

export const MapWithBounds = ({ polygonBoxes }) => {
  const map = useMap();

  useEffect(() => {
    if (polygonBoxes && polygonBoxes.length > 0) {
      const bounds = polygonBoxes.flat().map((coord) => [coord[1], coord[0]]);
      map.fitBounds(bounds);
    }
  }, [map, polygonBoxes]);

  return null;
};

export const calculateArea = (coords) => {
  const lat1 = coords._southWest.lat;
  const lat2 = coords._northEast.lat;
  const lon1 = coords._southWest.lng;
  const lon2 = coords._northEast.lng;

  // WGS84 parameters
  const a = 6378137.0; // Semi-major axis (equatorial radius) in meters
  const f = 1 / 298.257223563; // Flattening
  const b = a * (1 - f); // Semi-minor axis (polar radius) in meters
  const e2 = (2 - f) * f; // First eccentricity squared

  // Convert degrees to radians
  const lat1Rad = (lat1 * Math.PI) / 180;
  const lat2Rad = (lat2 * Math.PI) / 180;
  const lonDiffRad = ((lon2 - lon1) * Math.PI) / 180;

  // Compute meridional radius of curvature (M) at midpoint latitude
  const midLatRad = (lat1Rad + lat2Rad) / 2;
  const sinMidLat = Math.sin(midLatRad);
  const M = (a * (1 - e2)) / Math.pow(1 - e2 * sinMidLat * sinMidLat, 1.5);

  // Compute normal radius of curvature (N) at midpoint latitude
  const N = a / Math.sqrt(1 - e2 * sinMidLat * sinMidLat);

  // Compute differential area dA ≈ M * dLat * N * dLon
  const dLat = lat2Rad - lat1Rad;
  const areaM2 = Math.abs(M * dLat * N * lonDiffRad); // Area in square meters
  const areaKm2 = areaM2 / 1e6; // Convert to square kilometers

  return areaKm2;
};

export const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function convertCoordinateAntiMeridian(coordinate) {
  if (coordinate[1] <= 0) coordinate[1] = coordinate[1] + 360;
  return coordinate;
}

// Function to check if satellite is within the bounding box
const isInBoundingBox = (lat, lon, boundingBox) => {
  const max_lat = boundingBox._northEast.lat;
  const min_lat = boundingBox._southWest.lat;
  const max_lon = boundingBox._northEast.lng;
  const min_lon = boundingBox._southWest.lng;

  return lat >= min_lat && lat <= max_lat && lon >= min_lon && lon <= max_lon;
};

// Function to find the next overpass over multiple orbital cycles.
// 'maxCycles' determines how many orbital periods will be checked.
export const findNextOverpass = (tle, boundingBox, maxCycles = 12) => {
  const satrec = satellite.twoline2satrec(tle.line1, tle.line2);
  const steps = 175;
  // Calculate the orbital period in seconds using mean motion (satrec.no is in radians per minute)
  const orbitalPeriodSeconds = ((2 * Math.PI) / satrec.no) * 60;
  // The time step within each orbital period
  const stepInterval = orbitalPeriodSeconds / steps;
  const now = new Date();

  // Loop over 'maxCycles' orbital periods
  for (let cycle = 0; cycle < maxCycles; cycle++) {
    // Loop through time steps within the current cycle
    for (let i = 0; i < steps; i++) {
      const time = new Date(
        now.getTime() + (cycle * orbitalPeriodSeconds + i * stepInterval) * 1000
      );
      const posVel = satellite.propagate(satrec, time);
      if (posVel.position) {
        const gmst = satellite.gstime(time);
        const posGd = satellite.eciToGeodetic(posVel.position, gmst);
        const lat = satellite.degreesLat(posGd.latitude);
        const lon = satellite.degreesLong(posGd.longitude);

        if (isInBoundingBox(lat, lon, boundingBox)) {
          console.log("Next overpass time:", time);
          return time;
        }
      }
    }
  }
  // If no overpass is found within the search window, return null.
  return null;
};
