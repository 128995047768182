import React, { useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import SATELLITE_IMG from "assets/satellite-stock.jpeg";
import AppHeader from "features/AppHeader/AppHeader";
import { useNavigate } from "react-router-dom";
import { getRoute } from "utils/routes";
import { useRegister } from "provider/auth/useRegister";
import { useUser } from "provider/auth/useUser";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TypographyAtom from "components/TypographyAtom";

const schema = yup.object().shape({
  username: yup.string().required("Username is required"),
  organization: yup.string().required("Organization is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  is_in_mailing_list: yup.boolean(),
});

export default function SignUp() {
  const navigate = useNavigate();
  const userQuery = useUser();
  const { register, isLoading } = useRegister();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: "",
      organization: "",
      email: "",
      password: "",
      confirmPassword: "",
      is_in_mailing_list: true,
    },
  });

  const [isVerificationPage, setIsVerificationPage] = React.useState(false);

  useEffect(() => {
    if (!userQuery.isLoading && userQuery.data?.username) {
      navigate(getRoute("home"));
    }
  }, [userQuery, navigate]);

  const onSubmit = async (data) => {
    try {
      await register(data);
      setIsVerificationPage(true);
    } catch (error) {
      if (error.username)
        setError("username", { type: "manual", message: error.username[0] });
      if (error.email)
        setError("email", { type: "manual", message: error.email[0] });
      if (error.password)
        setError("password", { type: "manual", message: error.password[0] });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <AppHeader />
      {isVerificationPage ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
            padding: 3,
          }}
        >
          <Box sx={{ padding: 4, maxWidth: 600, textAlign: "center" }}>
            <TypographyAtom variant="h5" gutterBottom fontWeight={"bold"}>
              Registration Submitted
            </TypographyAtom>
            <TypographyAtom variant="body1">
              Your registration has been submitted. Please wait for one of our
              administrators to approve. You will be sent an email regarding
              your approval within 1-3 working days.
            </TypographyAtom>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3 }}
              onClick={() => navigate(getRoute("home"))}
            >
              Take me back to the home page
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1, display: "flex", minHeight: "100vh" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: 3,
              }}
            >
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{ maxWidth: 400, margin: "auto" }}
              >
                <TypographyAtom
                  variant="h4"
                  gutterBottom
                  sx={{
                    marginTop: "70px",
                  }}
                >
                  Sign Up
                </TypographyAtom>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Username"
                      fullWidth
                      margin="dense"
                      error={!!errors.username}
                      helperText={errors.username?.message || " "}
                      FormHelperTextProps={{
                        sx: { minHeight: "1.1em" },
                      }}
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email"
                      type="email"
                      fullWidth
                      margin="dense"
                      error={!!errors.email}
                      helperText={errors.email?.message || " "}
                      FormHelperTextProps={{
                        sx: { minHeight: "1.1em" },
                      }}
                    />
                  )}
                />
                <Controller
                  name="organization"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Organization"
                      fullWidth
                      margin="dense"
                      error={!!errors.organization}
                      helperText={errors.organization?.message || " "}
                      FormHelperTextProps={{
                        sx: { minHeight: "1.1em" },
                      }}
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Password"
                      type="password"
                      fullWidth
                      margin="dense"
                      error={!!errors.password}
                      helperText={errors.password?.message || " "}
                      FormHelperTextProps={{
                        sx: { minHeight: "1.1em" },
                      }}
                    />
                  )}
                />
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Confirm Password"
                      type="password"
                      fullWidth
                      margin="dense"
                      error={!!errors.confirmPassword}
                      helperText={errors.confirmPassword?.message || " "}
                      FormHelperTextProps={{
                        sx: { minHeight: "1.1em" },
                      }}
                    />
                  )}
                />
                <Controller
                  name="is_in_mailing_list"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label="Add me to mailing list"
                    />
                  )}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isLoading}
                  sx={{ mt: 2, height: 56 }}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Register"
                  )}
                </Button>
                <Box sx={{ mt: 2, textAlign: "center" }}>
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => navigate(getRoute("login"))}
                    sx={{
                      background: "transparent",
                      "&:hover": {
                        background: "rgba(0, 0, 0, 0.04)",
                        textDecoration: "underline",
                      },
                    }}
                  >
                    I already have an account
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: "125%",
                  objectFit: "cover",
                }}
                alt="Satellite"
                src={SATELLITE_IMG}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
