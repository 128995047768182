export const HEADER_HEIGHT = "58px";
export const CSRF_COOKIE_NAME = "csrftoken";

export const environment = process.env.REACT_APP_BUILD_ENV || "DEVELOPMENT";

export const HOST =
  environment === "PRODUCTION"
    ? process.env.REACT_APP_PRODUCTION_HOSTNAME
    : process.env.REACT_APP_DEVELOPMENT_HOSTNAME;

export const SARFINDER_BE_API_URL =
  environment === "PRODUCTION"
    ? process.env.REACT_APP_SARFINDER_BE_PRODUCTION_API_URL
    : process.env.REACT_APP_SARFINDER_BE_DEVELOPMENT_API_URL;

//Default constants for the API URL and AUTH_TOKEN name for local storage
export const AVATAR_API = "https://ui-avatars.com/api";
export const METADATA_API =
  // "https://aria-sg-products.s3-ap-southeast-1.amazonaws.com/metadata.all.json";
  process.env.REACT_APP_METADATA_API;
export const API = HOST + "/api";
export const AUTH_TOKEN = "authToken";
export const BEARER = "Bearer";
export const GRAPHQL = HOST + "/graphql";
