import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Slider,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { GeoJSON, TileLayer } from "react-leaflet";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import ButtonAtom from "components/ButtonAtom";
import CVDSwitch from "components/CVDSwitch";
import CustomCard from "components/CustomCard";
import TypographyAtom from "components/TypographyAtom";
import { useMetadataContext } from "context/MetadataContext";
import AppHeader, { CustomDrawer, Main } from "features/AppHeader/AppHeader";
import CustomTileLayer from "features/CustomTileLayer";
import DownloadsPopup from "features/MapPage/DownloadsPopup";
import {
  FloatingSideButton,
  FloatingSidePeekCard,
} from "features/MapPage/FloatingSideCard";
import GeoFileUploader from "features/MapPage/Map/GeoFileUploader";
import LayerControlPanel from "features/MapPage/Map/LayerControlPanel";

import MapView from "features/MapPage/Map/MapView";
import PrintMap from "features/MapPage/Map/PrintMap";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import DisclaimerPopupOrganism from "features/MapPage/DisclaimerPopupOrganism";
import { MapMarkerController } from "features/MapPage/Map/MapTools";

const pluginOptions = {
  cropImageByInnerWH: true,
  hidden: false,
  preventDownload: false,
  domtoimageOptions: {},
  position: "topleft",
  screenName: "screen",
  // iconUrl: ICON_SVG_BASE64,
  hideElementsWithSelectors: [".leaflet-control-container"],
  mimeType: "image/png",
  caption: null,
  captionFontSize: 15,
  captionFont: "Arial",
  captionColor: "black",
  captionBgColor: "white",
  captionOffset: 5,
  onPixelDataFail: async function ({ plugin, domtoimageOptions }) {
    return plugin._getPixelDataOfNormalMap(domtoimageOptions);
  },
};

const drawerWidth = 400;

const getCenterPoint = (event_bbox) => {
  return [
    (event_bbox[0][0] + event_bbox[1][0]) / 2.0,
    (event_bbox[0][1] + event_bbox[1][1]) / 2.0,
  ];
};

function GreyscaleThresholdingPage(props) {
  const theme = useTheme();
  const mapRef = useRef();
  const [state, setState] = useState();
  const metadata = useMetadataContext();
  const { event_name } = useParams();

  const [sidebarDescription, setSidebarDescription] = useState({});
  const [open, setOpen] = useState(false);
  const [openLayers, setOpenLayers] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);

  const geoJsonRef = useRef();

  const appBarRef = useRef(null);
  const [appBarHeight, setAppBarHeight] = useState(0);
  const [threshold, setThreshold] = useState(0.5);
  const [transparency, setTransparency] = useState(false);

  const [uploadedLayers, setUploadedLayers] = useState([]);
  const [products, setProducts] = useState([]);
  const [checked, setChecked] = useState([]);
  const [jsonData, setJsonData] = useState({});

  const [isLayerVisible, setIsLayerVisible] = useState(true);

  const colorStops = [
    { value: 0, color: [255, 255, 0] },
    { value: 0.85, color: [255, 0, 0] },
    { value: 1, color: [124, 0, 0] },
  ];
  const toggleLayerVisibility = () => {
    setIsLayerVisible(!isLayerVisible);
  };

  const handleThresholdChange = (event, newValue) => {
    setThreshold(newValue);
  };

  const handleTransparencyToggle = () => {
    setTransparency((prev) => !prev);
  };

  const filterPixels = useCallback(
    ([r, g, b, a]) => {
      // Define color stops with values and colors

      // Function to interpolate between two colors
      const interpolateColor = (color1, color2, factor) => {
        return color1.map((c, i) => Math.round(c + factor * (color2[i] - c)));
      };

      // Function to get the color from stops based on value
      const getColorFromStops = (value) => {
        // Find the color stops that bracket the value
        for (let i = 0; i < colorStops.length - 1; i++) {
          if (
            value >= colorStops[i].value &&
            value <= colorStops[i + 1].value
          ) {
            const factor =
              (value - colorStops[i].value) /
              (colorStops[i + 1].value - colorStops[i].value);
            return interpolateColor(
              colorStops[i].color,
              colorStops[i + 1].color,
              factor
            );
          }
        }
        return colorStops[colorStops.length - 1].color;
      };

      // Normalize the red component to a value between 0 and 1
      const normalizedValue = r / 255.0;

      // Apply the color stops for the specific range of values
      if (normalizedValue <= threshold) {
        return [0, 0, 0, 0]; // Transparent for values below threshold
      } else {
        const newNormalizedValue =
          (r - 255.0 * threshold) / (255.0 - 255.0 * threshold);
        const color = getColorFromStops(newNormalizedValue);
        return [...color, a * (transparency ? 0.5 : 1)];
      }
    },
    [threshold, transparency]
  );

  const filterContrastPixels = useCallback(([r, g, b, a]) => {
    // Convert to grayscale (desaturation)
    const gray = 0.3 * r + 0.59 * g + 0.11 * b;

    // Add blue tint
    const newRed = gray * 0.9; // Slightly reduced red
    const newGreen = gray * 0.9; // Slightly reduced green
    const newBlue = gray * 1.2; // Increased blue for the tint

    return [newRed, newGreen, newBlue, a];
  }, []);

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, [appBarRef.current]);

  const handleOpenDownload = () => {
    if (checked.length) {
      setOpenDownload(true);
    } else {
      enqueueSnackbar("Please select at least 1 product", { variant: "info" });
    }
  };

  const handleCloseDownload = () => {
    setOpenDownload(false);
  };

  const GeoJSONMap = (url) => {
    fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        var newObj = jsonData;
        newObj[url] = data;
        setJsonData(newObj);
      });
  };
  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await fetch(
          `https://eos-rs-greyscale-sarfinder.s3.ap-southeast-1.amazonaws.com/${event_name}/metadata.json`
        );
        const metadata = await response.json();

        const tileLayer = {
          tms: metadata.tile_structure === "TMS",
          url: metadata.url,
          zIndex: 200,
          matchRGBA: [255, 0, 0, 255],
          missRGBA: [0, 0, 0, 0],
          minNativeZoom: metadata.min_zoom,
          maxNativeZoom: metadata.max_zoom,
          bounds: [
            [metadata.bounds[1], metadata.bounds[0]],
            [metadata.bounds[3], metadata.bounds[2]],
          ],
        };

        console.log(tileLayer.bounds);

        setProducts([tileLayer]);
      } catch (error) {
        console.error("Error fetching metadata:", error);
      }
    };

    if (event_name) {
      fetchMetadata();
    }
  }, [event_name]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSwitch = (value, newVal) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      var productIndex = products.indexOf(value);
      products[productIndex].cvd_selected = newVal.target.checked;
      // setProducts()
    } else {
      newChecked[currentIndex].cvd_selected = newVal.target.checked;
    }

    setChecked(newChecked);
  };

  const handleSideBarDisplay = (title, description) => {
    setSidebarDescription({ title: title, description: description });
    handleDrawerOpen();
  };

  const toggleSideBarVisibility = useCallback(() => {
    setOpenLayers((prev) => !prev);
  }, []);

  const onEachClick = (feature, layer, title, description) => {
    // const name = feature.properties.name;
    // const density = feature.properties.density;

    layer.on({
      click: (e) => {
        handleFeatureClick(e, title, description);
      },
    });
  };

  const handleFeatureClick = (e, title, description) => {
    setOpenLayers(false);
    if (!geoJsonRef.current) return;
    geoJsonRef.current.resetStyle();

    const layer = e.target;
    handleSideBarDisplay(title, description);

    layer.setStyle({ color: "#cc7076AA" });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DownloadsPopup
        open={openDownload}
        onClose={handleCloseDownload}
        downloads={checked}
      />
      <FloatingSideButton
        isOpen={openLayers}
        onClick={toggleSideBarVisibility}
      />
      <FloatingSidePeekCard isOpen={openLayers}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => {
              setOpenLayers(!openLayers);
            }}
          >
            <ChevronRightIcon />
          </IconButton>
          <TypographyAtom variant="h5">Products</TypographyAtom>
        </Box>

        <LayerControlPanel
          layers={uploadedLayers}
          setLayers={setUploadedLayers}
        />
        <Stack
          sx={{
            padding: "0.5rem",
            gap: "1rem",
            overflow: "auto",
            flex: 1,
          }}
        >
          <List key={"list-component"} dense sx={{ width: "100%" }}>
            {products
              .filter((item) => {
                // console.log(item);
                return item.isLatest;
              })
              .map((value, index) => {
                const labelId = `checkbox-list-${value}-${index}`;
                return (
                  <ListItem key={labelId} disablePadding>
                    <ListItemButton onClick={handleToggle(value)}>
                      <ListItemIcon>
                        <Checkbox
                          edge="end"
                          onChange={handleToggle(value)}
                          checked={checked.indexOf(value) !== -1}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>

                      {/* <ListItemAvatar>
                        <Avatar
                          alt={`Avatar n°${value + 1}`}
                          src={value.prod_main_png}
                        />
                      </ListItemAvatar> */}
                      <ListItemText
                        id={labelId}
                        primary={decodeURIComponent(escape(value.prod_title))}
                      />
                      {value?.cvd_prod_tiles ? (
                        <CVDSwitch
                          onChange={(newVal) => {
                            handleSwitch(value, newVal);
                          }}
                          checked={value.cvd_selected}
                          edge="end"
                        />
                      ) : (
                        <></>
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </List>
          {/* {state?.product_list
            .filter((item) => {
              return item.isLatest;
            })
            .map((item) => {
              return (
                <ProductCard
                  Title={item.prod_title}
                  Image={item.prod_main_png}
                  Date={item.prod_date}
                  Description={item.prod_desc}
                ></ProductCard>
              );
            })} */}
        </Stack>
        {/* <ButtonAtom variant="outlined" fullWidth onClick={handleOpenDownload}>
          Download Selected
        </ButtonAtom> */}
      </FloatingSidePeekCard>

      <AppHeader
        position="fixed"
        isMapPage
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        ref={appBarRef}
      />
      <CustomDrawer open={open}>
        {/* <DrawerHeader>
          <TypographyAtom
            variant="h5"
            sx={{ overflowWrap: "anywhere" }}
            color={"primary"}
          >
            {state.event?.event_display_name}
          </TypographyAtom>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Stack sx={{ padding: "1rem", gap: "1rem" }}>
          <TypographyAtom variant="h5" sx={{ textDecoration: "underline" }}>
            Product Description
          </TypographyAtom>
          <TypographyAtom variant="h6" sx={{ lineBreak: "anywhere" }}>
            {sidebarDescription?.title}
          </TypographyAtom>
          <TypographyAtom sx={{ whiteSpace: "pre-wrap" }}>
            {sidebarDescription?.description}
          </TypographyAtom>
        </Stack>
        <Divider /> */}
      </CustomDrawer>

      <Main
        open={open}
        style={{
          height: `calc(100vh - ${appBarHeight}px)`,
          marginTop: `${appBarHeight}px`,
        }}
      >
        <DisclaimerPopupOrganism
          drawerIsOpen={open}
          drawerWidth={drawerWidth}
        />

        <MapView
          bounds={products[0]?.bounds}
          geoJsonRef={geoJsonRef}
          addLayer={setUploadedLayers}
          mapViewRef={mapRef}
        >
          <GeoFileUploader
            onFileUpload={(layer) =>
              setUploadedLayers((prev) => [...prev, layer])
            }
          />

          <CustomCard
            className="leaflet-ui"
            sx={{
              position: "absolute",
              bottom: 50,
              left: 20,
              padding: "16px",
              borderRadius: "12px",
              width: "35%",
              zIndex: 1000,
            }}
          >
            <Typography variant="h6" fontWeight="bold" mb={1}>
              Adjust Threshold
            </Typography>

            {/* Slider & Input Inline */}
            <Box display="flex" alignItems="center" gap={2}>
              <Slider
                value={threshold}
                onChange={handleThresholdChange}
                aria-labelledby="threshold-slider"
                valueLabelDisplay="auto"
                min={0}
                max={1}
                step={0.001}
                sx={{ flexGrow: 1 }}
              />
              <TextField
                type="number"
                value={threshold}
                onChange={(e) => {
                  let value = parseFloat(e.target.value);
                  if (isNaN(value)) value = 0;
                  if (value < 0) value = 0;
                  if (value > 1) value = 1;
                  handleThresholdChange(null, value);
                }}
                inputProps={{ min: 0, max: 1, step: 0.01 }}
                sx={{ width: "100px" }}
                size="small"
                variant="outlined"
              />
            </Box>

            {/* Toggle Buttons */}
            <Box display="flex" alignItems="center" gap={2}>
              <ToggleButtonGroup
                value={transparency}
                exclusive
                onChange={handleTransparencyToggle}
                aria-label="transparency toggle"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                  mt: 2,
                }}
              >
                <ToggleButton
                  value={true}
                  aria-label="translucent"
                  title="Switch to Translucent"
                  disabled={transparency}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                    borderRadius: "8px",
                    "&.Mui-selected": {
                      backgroundColor: "rgba(0, 150, 255, 0.2)",
                      color: "#0096ff",
                      fontWeight: "bold",
                    },
                  }}
                >
                  Translucent
                </ToggleButton>
                <ToggleButton
                  value={false}
                  aria-label="opaque"
                  title="Switch to Opaque"
                  disabled={!transparency}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                    borderRadius: "8px",
                    "&.Mui-selected": {
                      backgroundColor: "rgba(0, 150, 255, 0.2)",
                      color: "#0096ff",
                      fontWeight: "bold",
                    },
                  }}
                >
                  Opaque
                </ToggleButton>
              </ToggleButtonGroup>

              {/* Layer Visibility Button */}
              <ButtonAtom
                onClick={toggleLayerVisibility}
                variant="contained"
                sx={{
                  marginTop: "16px",
                  width: "100%",
                  padding: "12px",
                  fontWeight: "bold",
                  backgroundColor: isLayerVisible ? "#d32f2f" : "#1976d2",
                  "&:hover": {
                    backgroundColor: isLayerVisible ? "#b71c1c" : "#1565c0",
                  },
                }}
              >
                {isLayerVisible ? "Hide" : "Show"} Blue Contrast Layer
              </ButtonAtom>
            </Box>
            <PrintMap colorScale={colorStops} thresholdValue={threshold} />
          </CustomCard>

          {isLayerVisible && (
            <CustomTileLayer
              name={"Blue Contrast"}
              url={
                "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              }
              attribution={`<a href="https://www.esri.com/en-us/legal/terms/full-master-agreement" title="This work is licensed under the Esri Master License Agreement." target="_blank">&copy; <b>ESRI</b>Maps</a> &copy;`}
              maxNativeZoom={21}
              filter={filterContrastPixels}
            />
          )}

          {products.map((layer, index) => (
            <CustomTileLayer
              key={index}
              tms={layer.tms}
              url={layer.url}
              zIndex={layer.zIndex}
              matchRGBA={layer.matchRGBA}
              missRGBA={layer.missRGBA}
              minNativeZoom={layer.minNativeZoom}
              maxNativeZoom={layer.maxNativeZoom}
              filter={filterPixels}
            />
          ))}

          {uploadedLayers
            .slice()
            .reverse()
            .map((layer, index) => {
              if (!layer.visible) return null; // Skip rendering if the layer is not visible
              const layerKey = `${layer.id}-${index}`;

              return (
                <>
                  {layer.tileLayer && (
                    <CustomTileLayer
                      tms={true}
                      url={layer?.tileLayer.url}
                      maxNativeZoom={parseInt(layer?.tileLayer?.prod_max_zoom)}
                      minNativeZoom={parseInt(layer?.tileLayer?.prod_min_zoom)}
                      opacity={layer.opacity}
                      zIndex={200}
                      // matchRGBA={[255, 0, 0, 255]}
                      // missRGBA={[0, 0, 0, 0]}
                      // filter={filterPixels}
                    />
                  )}

                  {layer.geoJson && (
                    <GeoJSON
                      key={layerKey}
                      data={layer.geoJson}
                      style={{
                        opacity: layer.opacity,
                        color: layer.color,
                        fillOpacity: layer.opacity, // Apply opacity to the fill layer
                      }}
                    />
                  )}
                </>
              );
            })}

          {checked.map((item) => {
            return (
              <>
                <TileLayer
                  tms={true}
                  url={
                    item?.cvd_selected
                      ? `${item?.cvd_prod_tiles}{z}/{x}/{y}.png`
                      : `${item?.prod_tiles}{z}/{x}/{y}.png`
                  }
                  maxNativeZoom={parseInt(item?.prod_max_zoom)}
                  minNativeZoom={parseInt(item?.prod_min_zoom)}
                  zIndex={20}
                  // matchRGBA={[255, 0, 0, 255]}
                  // missRGBA={[0, 0, 0, 0]}
                  // filter={filterPixels}
                />
                <GeoJSON
                  style={{ color: "#be93e677", weight: 2 }}
                  ref={geoJsonRef}
                  onEachFeature={(feature, layer) => {
                    onEachClick(
                      feature,
                      layer,
                      decodeURIComponent(escape(item?.prod_title)),
                      decodeURIComponent(escape(item?.prod_desc))
                    );
                  }}
                  key={item?.prod_rfp_file}
                  data={jsonData[item?.prod_rfp_file]}
                ></GeoJSON>
              </>
            );
          })}

          <MapMarkerController />

          {/* <Rectangle
            bounds={state?.event?.event_bbox}
            pathOptions={{ color: "white", fill: false, weight: 5 }}
          /> */}
        </MapView>
      </Main>
    </Box>
  );
}

export default GreyscaleThresholdingPage;
