//Stores and provides the data defined by AuthContext to all the components below it

import React, { useState } from "react";
import { useEffect } from "react";
import { MetadataContext } from "context/MetadataContext";
import { METADATA_API } from "utils/constants";
import { useCallAPI } from "utils/helper";
import { useUser } from "./auth/useUser";
import { useSnackbar } from "notistack";

const MetadataProvider = ({ children }) => {
  const [metadata, setMetadata] = useState();
  const userQuery = useUser();
  const { enqueueSnackbar } = useSnackbar();

  // API call to fetch bucket responses
  const fetchBucketResponses = useCallAPI(
    `fetchBucketResponses`,
    `/bucket/responses/`,
    "GET"
  );

  const mergeDictionaries = (primaryDict, secondaryDict) => {
    if (!primaryDict || !secondaryDict || !secondaryDict.private_products) {
      return primaryDict;
    }

    return primaryDict.map((event) => {
      const eventKey = event.event_name;

      // Check if the event name exists in the secondary dictionary
      if (secondaryDict.private_products[eventKey]) {
        const privateProducts = secondaryDict.private_products[eventKey];

        event.hasGreyscale = true;

        // Iterate over each product in the primary product list
        event.product_list = event.product_list.map((product) => {
          const productName = product.prod_name;

          // If there is a private product with a similar base name, merge it
          for (const [privateProductKey, privateProductValue] of Object.entries(
            privateProducts
          )) {
            if (privateProductKey.startsWith(productName)) {
              if (!product.private_files) {
                product.private_files = {};
              }
              product.private_files[privateProductKey] =
                privateProductValue.file_url;
            }
          }

          return product;
        });
      }

      return event;
    });
  };

  const headers = {
    "Content-Type": "application/json; charset=utf-8",
  };

  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  useEffect(() => {
    const response = fetch(METADATA_API, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (
          !userQuery.isPending &&
          userQuery.data?.username &&
          (userQuery.data?.is_staff || userQuery.data?.is_superuser)
        ) {
          fetchBucketResponses.mutate(null, {
            onSuccess: (data) => {
              const mergedResult = mergeDictionaries(result.reverse(), data);
              console.log(mergedResult);

              setMetadata(mergedResult);
            },
            onError: (error) => {
              enqueueSnackbar("Failed to fetch bucket responses", {
                variant: "error",
              });
            },
          });
        } else if (!userQuery.isPending) {
          setMetadata(result.reverse());
        }
      });
  }, [userQuery.isPending]);

  return (
    <MetadataContext.Provider value={metadata}>
      {children}
    </MetadataContext.Provider>
  );
};

export default MetadataProvider;
