import { Box, CircularProgress, Grid, TextField } from "@mui/material";
import SATELLITE_IMG from "assets/satellite-stock.jpeg";
import ButtonAtom from "components/ButtonAtom";
import TypographyAtom from "components/TypographyAtom";
import AppHeader from "features/AppHeader/AppHeader";
import { useLogin } from "provider/auth/useLogin";
import { useUser } from "provider/auth/useUser";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { getRoute } from "utils/routes";

export default function Login() {
  const userQuery = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { login, isLoading: isLoginLoading } = useLogin();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!userQuery.isLoading && userQuery.data?.username) {
      const redirectRoute = new URLSearchParams(location.search).get(
        "redirect"
      );
      navigate(redirectRoute || getRoute("home"));
    }
  }, [userQuery, navigate, location]);

  const onSubmit = async (data) => {
    try {
      await login(data);
    } catch (error) {
      setError("identifier", {
        type: "manual",
        message:
          "Invalid credentials. Please check your username/email and password.",
      });
      setError("password", {
        type: "manual",
        message:
          "Invalid credentials. Please check your username/email and password.",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <AppHeader />
      <Box sx={{ flexGrow: 1, display: "flex" }}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: 3,
            }}
          >
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              sx={{ maxWidth: 400, margin: "auto" }}
            >
              <TypographyAtom variant="h4" gutterBottom>
                Login
              </TypographyAtom>
              <Controller
                name="identifier"
                control={control}
                defaultValue=""
                rules={{ required: "Username or Email is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Username or Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.identifier}
                    helperText={
                      errors.identifier ? errors.identifier.message : " "
                    }
                    FormHelperTextProps={{
                      sx: { minHeight: "1.5em" },
                    }}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{ required: "Password is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : " "}
                    FormHelperTextProps={{
                      sx: { minHeight: "1.5em" },
                    }}
                  />
                )}
              />
              <ButtonAtom
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoginLoading}
                fullWidth
                sx={{ mt: 2, height: 56 }}
              >
                {isLoginLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Login"
                )}
              </ButtonAtom>

              <Box
                sx={{
                  mt: 1,
                  textAlign: "center",
                  display: "flex",
                  gap: 1,
                  justifyContent: "space-between",
                }}
              >
                <ButtonAtom
                  fullWidth
                  variant="text"
                  color="subtle"
                  disabled={isLoginLoading}
                  onClick={() => navigate(getRoute("signup"))}
                >
                  I don't have an account
                </ButtonAtom>
                <ButtonAtom
                  fullWidth
                  variant="text"
                  color="subtle"
                  disabled={isLoginLoading}
                  onClick={() => navigate(getRoute("password_reset"))}
                >
                  I forgot my password
                </ButtonAtom>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Box
              component="img"
              sx={{
                width: "100%",
                height: "125%",
                objectFit: "cover",
              }}
              alt="Satellite"
              src={SATELLITE_IMG}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
