import React from "react";
import AppHeader from "features/AppHeader/AppHeader";
import { Box, Typography } from "@mui/material";
import TypographyAtom from "components/TypographyAtom";

const Error404Page = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppHeader />
      <TypographyAtom variant="h1">404 Error!</TypographyAtom>
      <TypographyAtom variant="h4">{"Page not found :("}</TypographyAtom>
    </Box>
  );
};

export default Error404Page;
