import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Modal,
  Stack,
  Slider,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { GeoJSON, Rectangle, TileLayer } from "react-leaflet";
import L, { tileLayer } from "leaflet";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import GetAppIcon from "@mui/icons-material/GetApp";
import SatelliteAltRoundedIcon from "@mui/icons-material/SatelliteAltRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import AppHeader, {
  CustomDrawer,
  DrawerHeader,
  Main,
} from "features/AppHeader/AppHeader";
import {
  FloatingSideButton,
  FloatingSidePeekCard,
} from "features/MapPage/FloatingSideCard";
import MapView from "features/MapPage/Map/MapView";
import CVDSwitch from "components/CVDSwitch";
import CustomCard, { CustomPopup } from "components/CustomCard";
import { useMetadataContext } from "context/MetadataContext";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { handleDownload } from "utils/helper";
import DownloadsPopup from "features/MapPage/DownloadsPopup";
import TypographyAtom from "components/TypographyAtom";
import ButtonAtom from "components/ButtonAtom";
import CustomTileLayer from "features/CustomTileLayer";
import LayerControlPanel from "features/MapPage/Map/LayerControlPanel";
import GeoFileUploader from "features/MapPage/Map/GeoFileUploader";
import DisclaimerPopupOrganism from "features/MapPage/DisclaimerPopupOrganism";
import useScreenSize from "hooks/useScreenSize";

const drawerWidth = 400;

function EventProductPage(props) {
  const theme = useTheme();
  const [state, setState] = useState();
  const metadata = useMetadataContext();
  const { event_name } = useParams();
  const [checked, setChecked] = useState([]);
  const [jsonData, setJsonData] = useState({});
  const [products, setProducts] = useState([]);
  const [sidebarDescription, setSidebarDescription] = useState({});
  const [open, setOpen] = useState(false);
  const [openLayers, setOpenLayers] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const { isDesktopView } = useScreenSize();

  const geoJsonRef = useRef();

  const appBarRef = useRef(null);
  const [appBarHeight, setAppBarHeight] = useState(0);
  const [threshold, setThreshold] = useState(0.0);
  const [transparency, setTransparency] = useState(false);

  const [uploadedLayers, setUploadedLayers] = useState([]);

  const handleThresholdChange = (event, newValue) => {
    setThreshold(newValue);
  };

  const handleTransparencyToggle = () => {
    setTransparency((prev) => !prev);
  };
  const filterDPMPixels = ([r, g, b, a]) => {
    const average = ((255 - (r + g + b) / 3.0) * a) / 255;
    return (average / 255.0) * 1 <= threshold
      ? [0, 0, 0, 0]
      : [r, g, b, a * (transparency ? 0.5 : 1)];
  };

  const filterFPMPixels = ([r, g, b, a]) => {
    return [r, g, b, a * (transparency ? 0.5 : 1)];
  };

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, [appBarRef.current]);

  const handleOpenDownload = () => {
    if (checked.length) {
      setOpenDownload(true);
    } else {
      enqueueSnackbar("Please select at least 1 product", { variant: "info" });
    }
  };

  const handleCloseDownload = () => {
    setOpenDownload(false);
  };

  const GeoJSONMap = (url) => {
    fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        var newObj = jsonData;
        newObj[url] = data;
        setJsonData(newObj);
      });
  };

  useEffect(() => {
    if (event_name && metadata) {
      const event_metadata = metadata?.find((events) => {
        return events.event_name == event_name;
      });

      setState({
        event: event_metadata,
        product_list: event_metadata.product_list,
      });
    }
  }, [event_name, metadata]);

  useEffect(() => {
    if (state) {
      var finalList = [];
      var productList = state?.product_list;

      productList
        .filter((filter) => {
          return filter.prod_cvd === false;
        })
        .forEach((item) => {
          GeoJSONMap(item.prod_rfp_file);
          var cvd = productList.filter((filter) => {
            return filter.prod_name === `${item.prod_name}_cvd`;
          });
          if (cvd.length) {
            const cvd_metadata = cvd[0];
            finalList.push({
              ...item,
              cvd_prod_tiles: cvd_metadata.prod_tiles,
              cvd_prod_kmz: cvd_metadata.prod_kmz,
              cvd_prod_main_png: cvd_metadata.prod_main_png,
              cvd_prod_name: cvd_metadata.prod_name,
              cvd_prod_rfp_file: cvd_metadata.prod_rfp_file,
              cvd_prod_tif: cvd_metadata.prod_tif,
              cvd_selected: false,
            });
          } else {
            finalList.push({ ...item, cvd_selected: false });
          }
        });

      setSidebarDescription({
        title: state?.product_list[0].prod_title,
        description: decodeURIComponent(
          escape(state?.product_list[0].prod_desc)
        ),
      });

      var layerObject = [];

      finalList.forEach((item) => {
        // Create a new layer object with the required properties
        const newLayer = {
          id: `${item?.prod_rfp_file}-${Date.now()}`, // unique id
          name: item?.prod_name, // file name used as layer name
          visible: true,
          opacity: 1,
          lock: true,
          tileLayer: {
            url: `${item?.prod_tiles}{z}/{x}/{y}.png`,
            prod_max_zoom: parseInt(item?.prod_max_zoom),
            prod_min_zoom: parseInt(item?.prod_min_zoom),
          },
        };

        layerObject.push(newLayer);
      });

      setProducts(finalList);
      // setUploadedLayers((prev) => [...prev, ...layerObject]);
      setOpenLayers(true);
    }
  }, [state]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSwitch = (value, newVal) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      var productIndex = products.indexOf(value);
      products[productIndex].cvd_selected = newVal.target.checked;
      // setProducts()
    } else {
      newChecked[currentIndex].cvd_selected = newVal.target.checked;
    }

    setChecked(newChecked);
  };

  const handleSideBarDisplay = (title, description) => {
    setSidebarDescription({ title: title, description: description });

    if (isDesktopView) handleDrawerOpen();
  };

  const onEachClick = (feature, layer, title, description) => {
    // const name = feature.properties.name;
    // const density = feature.properties.density;

    layer.on({
      click: (e) => {
        handleFeatureClick(e, title, description);
      },
    });
  };

  const handleFeatureClick = (e, title, description) => {
    setOpenLayers(false);
    if (!geoJsonRef.current) return;
    geoJsonRef.current.resetStyle();

    const layer = e.target;
    handleSideBarDisplay(title, description);

    layer.setStyle({ color: "#cc7076AA" });
  };

  return (
    <>
      {state ? (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <DownloadsPopup
            open={openDownload}
            onClose={handleCloseDownload}
            downloads={checked}
          />
          <FloatingSideButton
            isOpen={openLayers}
            onClick={() => {
              setOpenLayers(!openLayers);
            }}
          />
          <FloatingSidePeekCard isOpen={openLayers}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setOpenLayers(!openLayers);
                }}
              >
                <ChevronRightIcon />
              </IconButton>
              <TypographyAtom variant="h5">Products</TypographyAtom>
            </Box>

            <Stack
              sx={{
                padding: "0.5rem",
                gap: "1rem",
                overflow: "auto",
                flex: 1,
              }}
            >
              <List key={"list-component"} dense sx={{ width: "100%" }}>
                {products
                  .filter((item) => {
                    // console.log(item);
                    return item.isLatest;
                  })
                  .map((value, index) => {
                    const labelId = `checkbox-list-${value}-${index}`;
                    return (
                      <ListItem key={labelId} disablePadding>
                        <ListItemButton onClick={handleToggle(value)}>
                          <ListItemIcon>
                            <Checkbox
                              edge="end"
                              onChange={handleToggle(value)}
                              checked={checked.indexOf(value) !== -1}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>

                          {/* <ListItemAvatar>
                        <Avatar
                          alt={`Avatar n°${value + 1}`}
                          src={value.prod_main_png}
                        />
                      </ListItemAvatar> */}
                          <ListItemText
                            id={labelId}
                            primary={decodeURIComponent(
                              escape(value.prod_title)
                            )}
                          />
                          {value?.cvd_prod_tiles ? (
                            <CVDSwitch
                              onChange={(newVal) => {
                                handleSwitch(value, newVal);
                              }}
                              checked={value.cvd_selected}
                              edge="end"
                            />
                          ) : (
                            <></>
                          )}
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
              {/* {state?.product_list
            .filter((item) => {
              return item.isLatest;
            })
            .map((item) => {
              return (
                <ProductCard
                  Title={item.prod_title}
                  Image={item.prod_main_png}
                  Date={item.prod_date}
                  Description={item.prod_desc}
                ></ProductCard>
              );
            })} */}
            </Stack>
            <ButtonAtom
              variant="outlined"
              fullWidth
              onClick={handleOpenDownload}
            >
              Download Selected
            </ButtonAtom>
          </FloatingSidePeekCard>

          <AppHeader
            position="fixed"
            isMapPage
            handleDrawerOpen={handleDrawerOpen}
            open={open}
            ref={appBarRef}
          />
          <CustomDrawer open={open}>
            <DrawerHeader>
              <TypographyAtom
                variant="h5"
                sx={{ overflowWrap: "anywhere" }}
                color={"primary"}
              >
                {state.event?.event_display_name}
              </TypographyAtom>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <Stack sx={{ padding: "1rem", gap: "1rem" }}>
              <TypographyAtom variant="h5" sx={{ textDecoration: "underline" }}>
                Product Description
              </TypographyAtom>
              <TypographyAtom variant="h6" sx={{ lineBreak: "anywhere" }}>
                {sidebarDescription?.title}
              </TypographyAtom>
              <TypographyAtom sx={{ whiteSpace: "pre-wrap" }}>
                {sidebarDescription?.description}
              </TypographyAtom>
            </Stack>
            <Divider />
          </CustomDrawer>

          <Main
            open={open}
            style={{
              height: `calc(100vh - ${appBarHeight}px)`,
              marginTop: `${appBarHeight}px`,
            }}
          >
            <DisclaimerPopupOrganism
              drawerIsOpen={open}
              drawerWidth={drawerWidth}
            />

            <MapView bounds={state?.event?.event_bbox} geoJsonRef={geoJsonRef}>
              {/* <CustomCard
                className="leaflet-ui"
                sx={{
                  position: "absolute",
                  bottom: 50,
                  left: 20,
                  padding: "16px",
                  borderRadius: "12px",
                  width: "35%",
                  zIndex: 1000,
                }}
              >
                <Typography variant="h6" fontWeight="bold" mb={1}>
                  Adjust DPM Threshold
                </Typography>


                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection={"column"}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width={"100%"}
                  >
                    <TypographyAtom variant="subtitle1">
                      {"Low Probability of Change"}
                    </TypographyAtom>
                    <Typography variant="subtitle1">
                      {"High Probability of Change"}
                    </Typography>
                  </Box>
                  <Slider
                    value={threshold}
                    onChange={handleThresholdChange}
                    aria-labelledby="threshold-slider"
                    valueLabelDisplay="auto"
                    min={0}
                    max={1}
                    step={0.001}
                    sx={{ flexGrow: 1 }}
                  />
                </Box>


                <Box display="flex" alignItems="center" gap={2}>
                  <ToggleButtonGroup
                    value={transparency}
                    exclusive
                    onChange={handleTransparencyToggle}
                    aria-label="transparency toggle"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                      mt: 2,
                    }}
                  >
                    <ToggleButton
                      value={true}
                      aria-label="translucent"
                      title="Switch to Translucent"
                      disabled={transparency}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        borderRadius: "8px",
                        "&.Mui-selected": {
                          backgroundColor: "rgba(0, 150, 255, 0.2)",
                          color: "#0096ff",
                          fontWeight: "bold",
                        },
                      }}
                    >
                      Translucent
                    </ToggleButton>
                    <ToggleButton
                      value={false}
                      aria-label="opaque"
                      title="Switch to Opaque"
                      disabled={!transparency}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        borderRadius: "8px",
                        "&.Mui-selected": {
                          backgroundColor: "rgba(0, 150, 255, 0.2)",
                          color: "#0096ff",
                          fontWeight: "bold",
                        },
                      }}
                    >
                      Opaque
                    </ToggleButton>
                  </ToggleButtonGroup>

               
                </Box>
              </CustomCard> */}

              {/* Layer Visibility Button */}
              {/* <ButtonAtom
                              onClick={toggleLayerVisibility}
                              variant="contained"
                              sx={{
                                marginTop: "16px",
                                width: "100%",
                                padding: "12px",
                                fontWeight: "bold",
                                backgroundColor: isLayerVisible ? "#d32f2f" : "#1976d2",
                                "&:hover": {
                                  backgroundColor: isLayerVisible ? "#b71c1c" : "#1565c0",
                                },
                              }}
                            >
                              {isLayerVisible ? "Hide" : "Show"} Blue Contrast Layer
                            </ButtonAtom> */}

              {/* <GeoFileUploader
                onFileUpload={(layer) =>
                  setUploadedLayers((prev) => [...prev, layer])
                }
              />

              {uploadedLayers
                .slice()
                .reverse()
                .map((layer, index) => {
                  if (!layer.visible) return null; // Skip rendering if the layer is not visible
                  const layerKey = `${layer.id}-${index}`;

                  return (
                    <>
                      {layer.tileLayer && (
                        <CustomTileLayer
                          tms={true}
                          url={layer?.tileLayer.url}
                          maxNativeZoom={parseInt(
                            layer?.tileLayer?.prod_max_zoom
                          )}
                          minNativeZoom={parseInt(
                            layer?.tileLayer?.prod_min_zoom
                          )}
                          opacity={layer.opacity}
                          zIndex={200}
                          // matchRGBA={[255, 0, 0, 255]}
                          // missRGBA={[0, 0, 0, 0]}
                          // filter={filterPixels}
                        />
                      )}

                      {layer.geoJson && (
                        <GeoJSON
                          key={layerKey}
                          data={layer.geoJson}
                          style={{
                            opacity: layer.opacity,
                            color: layer.color,
                            fillOpacity: layer.opacity, // Apply opacity to the fill layer
                          }}
                        />
                      )}
                    </>
                  );
                })} */}

              {checked.map((item) => {
                return (
                  <>
                    <CustomTileLayer
                      tms={true}
                      url={
                        item?.cvd_selected
                          ? `${item?.cvd_prod_tiles}{z}/{x}/{y}.png`
                          : `${item?.prod_tiles}{z}/{x}/{y}.png`
                      }
                      maxNativeZoom={parseInt(item?.prod_max_zoom)}
                      minNativeZoom={parseInt(item?.prod_min_zoom)}
                      zIndex={20}
                      matchRGBA={[255, 0, 0, 255]}
                      missRGBA={[0, 0, 0, 0]}
                      filter={
                        item?.prod_name.includes("DPM")
                          ? filterDPMPixels
                          : filterFPMPixels
                      }
                    />

                    <GeoJSON
                      style={{ color: "#be93e6aa", weight: 2, fillOpacity: 0 }}
                      ref={geoJsonRef}
                      onEachFeature={(feature, layer) => {
                        onEachClick(
                          feature,
                          layer,
                          decodeURIComponent(escape(item?.prod_title)),
                          decodeURIComponent(escape(item?.prod_desc))
                        );
                      }}
                      key={item?.prod_rfp_file}
                      data={jsonData[item?.prod_rfp_file]}
                    />
                  </>
                );
              })}

              <Rectangle
                bounds={state?.event?.event_bbox}
                pathOptions={{ color: "white", fill: false, weight: 5 }}
              />
            </MapView>
          </Main>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

export default EventProductPage;
