//Entry point for the webpack and deals with the routes
import { CssBaseline, ThemeProvider, responsiveFontSizes } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as Sentry from "@sentry/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import MyriadProBold from "assets/MYRIADPRO-BOLD.woff";
import MyriadPro from "assets/MYRIADPRO-REGULAR.woff";
import "index.css";
import { SnackbarProvider } from "notistack";
import { LoadingScreenProvider } from "provider/LoadingScreen";
import MetadataProvider from "provider/MetadataProvider";
import ReactQueryProvider from "provider/ReactQueryProvider";
import ReactDOM from "react-dom/client";
import { environment } from "utils/constants";
import WebRoute from "./utils/router";

if (environment === "PRODUCTION") {
  Sentry.init({
    dsn: "https://6cc1584c9fa1623c974e6268a89b40df@o4508255608766464.ingest.de.sentry.io/4508255811403856",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: environment,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

let theme = createTheme({
  typography: {
    fontFamily: ['"Myriad Pro"', "Helvetica Neue", "Roboto"].join(","),
    h1: {
      fontFamily: "Myriad Pro",
      letterSpacing: -0.5,
      textTransform: "uppercase",
    },
    h2: {
      fontFamily: "Myriad Pro Bold",
      letterSpacing: -1,
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: "Myriad Pro Bold",
      letterSpacing: -1,
      lineHeight: 1.2,
    },
    h4: {
      fontFamily: "Myriad Pro Bold",
      letterSpacing: -1,
      lineHeight: 1.2,
    },
    h5: {
      fontFamily: "Myriad Pro Bold",
      letterSpacing: -1,
      lineHeight: 1.2,
    },
    h6: {
      fontFamily: "Myriad Pro Bold",
      letterSpacing: -1,
      lineHeight: 1.2,
    },
    body1: {
      fontFamily: "Arial",

      letterSpacing: -0.3,
      lineHeight: 1.3,
    },

    body2: {
      fontFamily: "Arial",
      lineHeight: 1.3,
    },
    button: {
      fontFamily: "Myriad Pro Bold",
      letterSpacing: -0.3,
      fontSize: "1rem",
    },
  },
  palette: {
    mode: "dark",
    background: {
      default: "#171717",
      highlight: "#B7B7B7",
    },
    primary: {
      main: "#FFBD5A",
    },
    secondary: {
      main: "#ffffff",
    },
    subtle: {
      main: "#78212A",
    },
    light: {
      main: "#D8E1FF",
    },
    navyblue: {
      main: "#3477e3",
    },
    typography: {
      main: "#ffffff",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Myriad Pro';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Myriad Pro'), local('Myriad Pro-Regular'), url(${MyriadPro}) format('woff');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Myriad Pro Bold';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Myriad Pro'), local('Myriad Pro-Bold'), url(${MyriadProBold}) format('woff');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

      `,
    },
    MuiButtonBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: "2rem",
        },
      },
    },
  },
});
theme = responsiveFontSizes(theme);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <ReactQueryProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <CssBaseline />
        <SnackbarProvider>
          <LoadingScreenProvider>
            <MetadataProvider>
              <WebRoute />
              <ReactQueryDevtools initialIsOpen={false} />
            </MetadataProvider>
          </LoadingScreenProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ReactQueryProvider>
  </ThemeProvider>
);
