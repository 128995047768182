import { useEffect } from "react";
import L from "leaflet";
import "./leaflet-tileLayerPixelFilter";
import { useMap } from "react-leaflet";

const CustomTileLayer = ({
  url,
  matchRGBA = null,
  missRGBA = null,
  pixelCodes = [],
  filter = null, // Default to null
  ...options
}) => {
  const map = useMap();

  useEffect(() => {
    // if (!L.tileLayerPixelFilter) {
    //   console.error("Leaflet PixelFilter plugin is not available.");
    //   return;
    // }

    // const validateRGBA = (rgba) =>
    //   rgba === null ||
    //   (Array.isArray(rgba) &&
    //     rgba.length === 4 &&
    //     rgba.every((num) => typeof num === "number"));

    // if (!validateRGBA(matchRGBA)) {
    //   console.error(
    //     "matchRGBA should be an array of four numbers [r, g, b, a] or null."
    //   );
    //   return;
    // }

    // if (!validateRGBA(missRGBA)) {
    //   console.error(
    //     "missRGBA should be an array of four numbers [r, g, b, a] or null."
    //   );
    //   return;
    // }

    const layer = L.tileLayerPixelFilter(url, {
      ...options,
      matchRGBA,
      missRGBA,
      pixelCodes,
      filter, // Pass the filter function
      pane: "overlayPane",
    });

    layer.addTo(map);

    return () => {
      map.removeLayer(layer);
    };
  }, [filter]);

  return null;
};

export default CustomTileLayer;
