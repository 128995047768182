import BorderOuterIcon from "@mui/icons-material/BorderOuter";
import StraightenIcon from "@mui/icons-material/Straighten";
import { Box, IconButton, useTheme } from "@mui/material";
import ButtonAtom from "components/ButtonAtom";
import CustomCard from "components/CustomCard";
import L from "leaflet";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Polyline, useMap, useMapEvents } from "react-leaflet";
import MarkerIcon from "assets/Marker-Icon.png";
import RoomIcon from "@mui/icons-material/Room";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
} from "@mui/material";
import { calculateArea, formatNumber } from "utils/helper";
import wkt from "wkt";

// --- LeafletControl Component ---
// A modular control container that places React children in a Leaflet control overlay.
export function LeafletControl({ position = "topright", children }) {
  const map = useMap();
  const containerRef = useRef(L.DomUtil.create("div"));

  useEffect(() => {
    // Prevent click events from bubbling to the map.
    const stopPropagation = (e) => {
      if (e.type !== "contextmenu") e.stopPropagation(); // Allow right-click events
    };

    containerRef.current.addEventListener("click", stopPropagation);
    containerRef.current.addEventListener("mousedown", stopPropagation);
    containerRef.current.addEventListener("touchstart", stopPropagation);

    const control = L.control({ position });
    control.onAdd = () => containerRef.current;
    control.addTo(map);

    return () => {
      containerRef.current.removeEventListener("touchstart", stopPropagation);
      containerRef.current.removeEventListener("mousedown", stopPropagation);
      containerRef.current.removeEventListener("click", stopPropagation);
      control.remove();
    };
  }, [map, position]);

  return createPortal(children, containerRef.current);
}

// --- MeasureTool Component ---
export function MeasureTool() {
  const [measurePoints, setMeasurePoints] = useState([]);
  const [isMeasuring, setIsMeasuring] = useState(false);
  const [currentMouse, setCurrentMouse] = useState(null);
  const map = useMap();
  const theme = useTheme();

  // Use map events for measuring points and mouse tracking.
  useMapEvents({
    click: (e) => {
      if (isMeasuring) {
        setMeasurePoints((points) => [...points, e.latlng]);
      }
    },
    mousemove: (e) => {
      if (isMeasuring) {
        setCurrentMouse(e.latlng);
      }
    },
  });

  const startMeasuring = (e) => {
    // Prevent any click propagation that might register a point.
    // e.stopPropagation();
    setMeasurePoints([]);
    setIsMeasuring(true);
  };

  const stopMeasuring = () => {
    setIsMeasuring(false);
    setCurrentMouse(null);
  };

  // Calculate distances.
  const fixedDistance = measurePoints.reduce((acc, curr, index, arr) => {
    if (index === 0) return 0;
    return acc + arr[index - 1].distanceTo(curr);
  }, 0);

  const segmentDistance =
    isMeasuring && currentMouse && measurePoints.length > 0
      ? measurePoints[measurePoints.length - 1].distanceTo(currentMouse)
      : 0;

  const totalDistance = fixedDistance + segmentDistance;
  const polylinePositions =
    isMeasuring && currentMouse
      ? [...measurePoints, currentMouse]
      : measurePoints;

  return (
    <>
      {/* Control overlay for measure tool buttons and instructions */}
      <LeafletControl position="topright">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          {!isMeasuring ? (
            <IconButton
              onClick={startMeasuring}
              sx={{
                color: "#fff",
                backgroundColor: (theme) => theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.secondary.dark,
                },
                transition: "background 0.3s ease, transform 0.2s",
              }}
            >
              <StraightenIcon />
            </IconButton>
          ) : (
            <ButtonAtom
              onClick={stopMeasuring}
              style={{
                padding: "8px 16px",
                borderRadius: "4px",
                border: "none",
                background: theme.palette.error.light,
                color: "#fff",
                cursor: "pointer",
                fontSize: "14px",
                transition: "background 0.3s ease, transform 0.2s",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              Stop Measuring
            </ButtonAtom>
          )}

          {isMeasuring && (
            <CustomCard
              style={{
                padding: "10px",
                borderRadius: "6px",
                fontSize: "14px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
                maxWidth: "250px",
              }}
            >
              <b>Measuring Mode:</b>
              <ul style={{ padding: "5px", margin: 0, listStyle: "none" }}>
                <li>Click on the map to add points.</li>
                <li>Press "Stop Measuring" to end.</li>
              </ul>
              {totalDistance > 0 && (
                <div style={{ marginTop: "0.5rem" }}>
                  Total Distance: {(totalDistance / 1000).toFixed(2)} km
                </div>
              )}
            </CustomCard>
          )}
        </div>
      </LeafletControl>

      {/* Draw the polyline */}
      {polylinePositions.length > 1 && (
        <Polyline
          positions={polylinePositions}
          color={"#66c2ff"}
          weight={4}
          opacity={0.8}
        />
      )}

      {/* Floating segment distance label following the mouse */}
      {isMeasuring && currentMouse && measurePoints.length > 0 && (
        <FloatingTooltip
          latlng={currentMouse}
          text={`Segment: ${(segmentDistance / 1000).toFixed(2)} km`}
        />
      )}
    </>
  );
}

export const MapMarkerController = () => {
  const map = useMap();
  const theme = useTheme();

  // Create a custom marker icon
  const customIcon = new L.Icon({
    iconUrl: MarkerIcon,
    iconSize: [28, 36],
    iconAnchor: [20, 36],
  });

  // Marker clustering group (optional)
  const markerClusterGroup = L.markerClusterGroup
    ? L.markerClusterGroup()
    : null;
  if (markerClusterGroup && !map.hasLayer(markerClusterGroup)) {
    map.addLayer(markerClusterGroup);
  }

  const [isMarkerMode, setIsMarkerMode] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [markerDescription, setMarkerDescription] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [currentMarker, setCurrentMarker] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);

  // Handle map clicks only when marker mode is active
  useMapEvents({
    click: (e) => {
      if (isMarkerMode) {
        setCurrentMarker(e.latlng);
        setMarkerDescription("");
        setEditingIndex(null);
        setOpenDialog(true);
      }
    },
  });

  const toggleMarkerMode = () => {
    setIsMarkerMode((prev) => !prev);
  };

  const handleDescriptionChange = (e) => {
    setMarkerDescription(e.target.value);
  };

  const handleSaveDescription = () => {
    if (currentMarker && markerDescription) {
      let newMarkerInstance;
      // If editing an existing marker update it
      if (editingIndex !== null) {
        const markerObj = markers[editingIndex];
        markerObj.instance.setPopupContent(markerDescription);
        newMarkerInstance = markerObj.instance;
        const updatedMarker = {
          ...markerObj,
          description: markerDescription,
        };
        setMarkers((prev) =>
          prev.map((m, index) => (index === editingIndex ? updatedMarker : m))
        );
      } else {
        // Create new marker instance
        newMarkerInstance = L.marker(currentMarker, {
          icon: customIcon,
          draggable: true,
        });

        // Adjust popup offset
        newMarkerInstance
          .bindPopup(markerDescription, {
            offset: L.point(0, -30),
          })
          .openPopup();

        // Update marker position on drag end
        newMarkerInstance.on("dragend", (event) => {
          const updatedLatLng = event.target.getLatLng();
          setMarkers((prev) =>
            prev.map((marker) =>
              marker.instance === newMarkerInstance
                ? { ...marker, latlng: updatedLatLng }
                : marker
            )
          );
        });

        // Allow editing on click: open dialog pre-filled with description
        newMarkerInstance.on("click", () => {
          setCurrentMarker(newMarkerInstance.getLatLng());
          setMarkerDescription(newMarkerInstance.getPopup().getContent());
          const index = markers.findIndex(
            (m) => m.instance === newMarkerInstance
          );
          setEditingIndex(index);
          setOpenDialog(true);
        });

        // Add marker to clustering group if enabled
        if (markerClusterGroup) {
          markerClusterGroup.addLayer(newMarkerInstance);
        } else {
          newMarkerInstance.addTo(map);
        }

        // Save marker data with extra metadata
        setMarkers((prev) => [
          ...prev,
          {
            latlng: currentMarker,
            description: markerDescription,
            instance: newMarkerInstance,
            createdAt: new Date().toISOString(),
            category: "Default",
          },
        ]);
      }
      setOpenDialog(false);
      setMarkerDescription("");
      setEditingIndex(null);
    }
  };

  const handleCancel = () => {
    setOpenDialog(false);
    setMarkerDescription("");
    setEditingIndex(null);
  };

  const clearMarkers = () => {
    markers.forEach((marker) => {
      if (markerClusterGroup) {
        markerClusterGroup.removeLayer(marker.instance);
      } else {
        map.removeLayer(marker.instance);
      }
    });
    setMarkers([]);
  };

  const centerOnMarker = (latlng) => {
    map.setView(latlng, map.getZoom(), { animate: true });
  };

  const deleteMarker = (index) => {
    const markerObj = markers[index];
    if (markerClusterGroup) {
      markerClusterGroup.removeLayer(markerObj.instance);
    } else {
      map.removeLayer(markerObj.instance);
    }
    setMarkers((prev) => prev.filter((_, i) => i !== index));
  };

  const exportMarkers = () => {
    const data = markers.map((marker) => ({
      lat: marker.latlng.lat,
      lng: marker.latlng.lng,
      description: marker.description,
      createdAt: marker.createdAt,
      category: marker.category,
    }));
    const blob = new Blob([JSON.stringify(data, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "markers.json";
    a.click();
  };

  return (
    <>
      {/* Control overlay */}
      <LeafletControl position="topright">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          {isMarkerMode && (
            <CustomCard
              sx={{
                padding: 2,
                borderRadius: 2,
                maxWidth: "300px",
                fontSize: "14px",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              {/* Button row */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "0.5rem",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={clearMarkers}
                >
                  Clear Markers
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={toggleMarkerMode}
                >
                  {isMarkerMode ? "Exit Marker Mode" : "Enter Marker Mode"}
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  size="small"
                  onClick={exportMarkers}
                >
                  Export
                </Button>
              </Box>

              <strong>Placed Markers</strong>
              {markers.length === 0 ? (
                <p>No markers added yet.</p>
              ) : (
                <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                  {markers.map((marker, index) => (
                    <li key={index} style={{ marginBottom: "8px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "0.5rem",
                        }}
                      >
                        <Box sx={{ flex: "1 1 auto" }}>
                          <strong>{index + 1}.</strong> {marker.description}
                        </Box>
                        <Box sx={{ display: "flex", gap: "0.5rem" }}>
                          <Button
                            variant="text"
                            size="small"
                            onClick={() => centerOnMarker(marker.latlng)}
                          >
                            Center
                          </Button>
                          <Button
                            variant="text"
                            size="small"
                            color="error"
                            onClick={() => deleteMarker(index)}
                          >
                            Delete
                          </Button>
                        </Box>
                      </Box>
                    </li>
                  ))}
                </ul>
              )}
            </CustomCard>
          )}

          {/* Circular toggle button */}
          <IconButton
            onClick={toggleMarkerMode}
            sx={{
              backgroundColor: isMarkerMode
                ? theme.palette.error.light
                : theme.palette.primary.main,
              borderRadius: "50%",
              color: "#fff",
              "&:hover": {
                backgroundColor: isMarkerMode
                  ? theme.palette.error.dark
                  : theme.palette.secondary.dark,
                transform: "scale(1.05)",
                transition: "transform 0.2s ease",
              },
            }}
          >
            <RoomIcon />
          </IconButton>
        </Box>
      </LeafletControl>

      {/* Dialog for adding or editing a marker description */}
      <Dialog open={openDialog} onClose={handleCancel}>
        <DialogTitle>
          {editingIndex !== null
            ? "Edit Marker Description"
            : "Add Marker Description"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Marker Description"
            fullWidth
            variant="outlined"
            value={markerDescription}
            onChange={handleDescriptionChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveDescription} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// --- FloatingTooltip Component ---
// This component repositions itself dynamically relative to the current mouse pointer.
function FloatingTooltip({ latlng, text }) {
  const map = useMap();
  const containerPoint = map.latLngToContainerPoint(latlng);

  return createPortal(
    <div
      style={{
        position: "absolute",
        top: containerPoint.y,
        left: containerPoint.x,
        transform: "translate(-50%, -120%)",
        background: "rgba(50,50,50,0.85)",
        color: "#fff",
        padding: "6px 10px",
        borderRadius: "4px",
        pointerEvents: "none",
        whiteSpace: "nowrap",
        fontSize: "12px",
        transition: "opacity 0.3s ease",
        zIndex: 1100,
      }}
    >
      {text}
    </div>,
    map.getContainer()
  );
}
// Helper function to convert decimal coordinates to DMS (Degrees, Minutes, Seconds)
function toDMS(decimal, isLat = true) {
  const absolute = Math.abs(decimal);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);
  const direction = decimal >= 0 ? (isLat ? "N" : "E") : isLat ? "S" : "W";
  return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
}

// --- RightClickCoordinates Component ---
export function RightClickCoordinates() {
  const [menuPosition, setMenuPosition] = useState(null);
  const [clickedLatLng, setClickedLatLng] = useState(null);
  const theme = useTheme();
  const map = useMap();

  useMapEvents({
    contextmenu: (e) => {
      e.originalEvent.preventDefault();
      const containerPoint = map.latLngToContainerPoint(e.latlng);
      setMenuPosition(containerPoint);
      setClickedLatLng(e.latlng);
    },
  });

  // Helper to copy text to clipboard then close the menu.
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setMenuPosition(null);
    });
  };

  // Menu action handlers
  const handleCopyDecimal = () => {
    if (clickedLatLng) {
      const text = `${clickedLatLng.lat.toFixed(
        5
      )}, ${clickedLatLng.lng.toFixed(5)}`;
      copyToClipboard(text);
    }
  };

  const handleCopyDMS = () => {
    if (clickedLatLng) {
      const latDMS = toDMS(clickedLatLng.lat, true);
      const lngDMS = toDMS(clickedLatLng.lng, false);
      copyToClipboard(`${latDMS}, ${lngDMS}`);
    }
  };

  const handleCenterMap = () => {
    if (clickedLatLng) {
      map.setView(clickedLatLng, map.getZoom());
      setMenuPosition(null);
    }
  };

  const handleOpenGoogleMaps = () => {
    if (clickedLatLng) {
      const url = `https://www.google.com/maps/search/?api=1&query=${clickedLatLng.lat},${clickedLatLng.lng}`;
      window.open(url, "_blank");
      setMenuPosition(null);
    }
  };

  const handleClose = () => {
    setMenuPosition(null);
  };

  // Style for the context menu container
  const menuStyle = {
    position: "absolute",
    top: menuPosition ? menuPosition.y : 0,
    left: menuPosition ? menuPosition.x : 0,
    background: "#222",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
    overflow: "hidden",
    minWidth: "220px",
    transition: "opacity 0.3s ease",
    zIndex: 1100,
  };

  // Style for each menu item
  const itemStyle = {
    padding: "10px 16px",
    cursor: "pointer",
    borderBottom: `1px solid #444`,
    transition: "background 0.3s ease",
  };

  return (
    <>
      {menuPosition && (
        <div style={menuStyle}>
          <div
            onClick={handleCopyDecimal}
            style={itemStyle}
            onMouseEnter={(e) => (e.currentTarget.style.background = "#333")}
            onMouseLeave={(e) =>
              (e.currentTarget.style.background = "transparent")
            }
          >
            Copy Coordinates (Decimal)
          </div>
          <div
            onClick={handleCopyDMS}
            style={itemStyle}
            onMouseEnter={(e) => (e.currentTarget.style.background = "#333")}
            onMouseLeave={(e) =>
              (e.currentTarget.style.background = "transparent")
            }
          >
            Copy Coordinates (DMS)
          </div>

          <div
            onClick={handleCenterMap}
            style={itemStyle}
            onMouseEnter={(e) => (e.currentTarget.style.background = "#333")}
            onMouseLeave={(e) =>
              (e.currentTarget.style.background = "transparent")
            }
          >
            Center Map Here
          </div>
          <div
            onClick={handleOpenGoogleMaps}
            style={itemStyle}
            onMouseEnter={(e) => (e.currentTarget.style.background = "#333")}
            onMouseLeave={(e) =>
              (e.currentTarget.style.background = "transparent")
            }
          >
            Open in Google Maps
          </div>
          <div
            onClick={handleClose}
            style={{
              ...itemStyle,
              borderBottom: "none",
              textAlign: "center",
            }}
            onMouseEnter={(e) => (e.currentTarget.style.background = "#333")}
            onMouseLeave={(e) =>
              (e.currentTarget.style.background = "transparent")
            }
          >
            Close
          </div>
        </div>
      )}
    </>
  );
}

/**
 * A dialog component for entering WKT representing a bounding box.
 */
const BboxWktInputDialog = ({ open, onClose, onSubmit, error }) => {
  const [wktString, setWktString] = useState("");

  const handleWktChange = (event) => {
    setWktString(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(wktString);
    setWktString(""); // Clear input after submission
  };

  const handleClose = () => {
    onClose();
    setWktString(""); // Clear input when closing the dialog
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Enter Bounding Box WKT</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="WKT Polygon"
          type="text"
          fullWidth
          multiline
          rows={4}
          placeholder="Example: POLYGON((lon lat, lon lat, lon lat, lon lat, lon lat))"
          value={wktString}
          onChange={handleWktChange}
          error={!!error}
          helperText={
            error || "Enter a valid WKT polygon representing the bounding box."
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/**
 * A reusable tool component that renders a button in the map container.
 * When clicked, it opens a dialog to enter a bounding box in WKT format.
 * After submission, it parses the WKT and passes the calculated bounds to the onBoundsChange callback.
 */
export const BboxWktTool = ({
  appBarHeight = 64,
  position = { top: "1rem", left: "4rem" },
  onBoundsChange,
}) => {
  const [bboxWktDialogOpen, setBboxWktDialogOpen] = useState(false);
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const map = useMap();

  const handleBboxWktDialogOpen = () => {
    setError("");
    setBboxWktDialogOpen(true);
  };

  const handleBboxWktDialogClose = () => {
    setError("");
    setBboxWktDialogOpen(false);
  };

  const handleBboxWktSubmit = (wktString) => {
    try {
      const parsed = wkt.parse(wktString);
      if (parsed && parsed.type === "Polygon") {
        const coordinates = parsed.coordinates[0];
        const latLngs = coordinates.map(([lng, lat]) => ({ lat, lng }));
        const bounds = {
          _southWest: latLngs.reduce(
            (acc, { lat, lng }) => ({
              lat: Math.min(acc.lat, lat),
              lng: Math.min(acc.lng, lng),
            }),
            { lat: Infinity, lng: Infinity }
          ),
          _northEast: latLngs.reduce(
            (acc, { lat, lng }) => ({
              lat: Math.max(acc.lat, lat),
              lng: Math.max(acc.lng, lng),
            }),
            { lat: -Infinity, lng: -Infinity }
          ),
          toBBoxString: function () {
            return `${this._southWest.lng},${this._southWest.lat},${this._northEast.lng},${this._northEast.lat}`;
          },
        };

        if (map) {
          map.fitBounds([
            [bounds._southWest.lat, bounds._southWest.lng],
            [bounds._northEast.lat, bounds._northEast.lng],
          ]);
        }

        // Callback to update map bounds
        if (onBoundsChange) {
          const selectionData = {
            bounds,
            area: calculateArea(bounds),
          };
          onBoundsChange(selectionData);
        }
        setSnackbarMsg("Bounding box updated successfully!");
        setSnackbarOpen(true);
      } else {
        setError("Invalid WKT: Not a Polygon");
      }
    } catch (err) {
      setError("Error parsing WKT. Please ensure the format is correct.");
      console.error("Error parsing WKT:", err);
    }
    setBboxWktDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <LeafletControl position="topright">
      <IconButton
        color="secondary"
        onClick={handleBboxWktDialogOpen}
        sx={{
          zIndex: 1000,
          backgroundColor: (theme) => theme.palette.primary.main,
          "&:hover": {
            backgroundColor: (theme) => theme.palette.secondary.dark,
          },
        }}
      >
        <BorderOuterIcon />
      </IconButton>

      <BboxWktInputDialog
        open={bboxWktDialogOpen}
        onClose={handleBboxWktDialogClose}
        onSubmit={handleBboxWktSubmit}
        error={error}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // Position bottom-left
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </LeafletControl>
  );
};
