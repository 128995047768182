export class QueryUtils {
  constructor(formData, selectionArea) {
    this.cqlQueryDict = {};
    this.formData = formData;
    this.selectionArea = selectionArea;
  }

  // FUNCTION TO ADD VALUE TO DICTIONARY IF THE VALUE FOR THE SPECIFIED KEY IS NOT EMPTY
  valueCheck(key, value) {
    if (value) {
      this.cqlQueryDict[key] = value;
    }
  }

  // ADDS THE QUERY TO THE QUERY DICTIONARY IF TRUTHY
  fieldCheck(key, field, partial = false) {
    const value = this.formatQuery(key, this.formData[field], partial);
    if (value) {
      this.cqlQueryDict[key] = value;
    }
  }

  // ADDS THE RANGED QUERY TO THE QUERY DICTIONARY IF TRUTHY. WILL CALL FIELD CHECK, IF ONLY ONE VALUE KEYED IN
  fieldRangeCheck(key, field, endField) {
    const fieldValue = document.getElementById(field).value;
    const endFieldValue = document.getElementById(endField).value;

    if (fieldValue && endFieldValue) {
      this.cqlQueryDict[
        key
      ] = `${key} BETWEEN ${fieldValue} AND ${endFieldValue}`;
    } else {
      this.fieldCheck(key, field);
    }
  }

  //RETURNS AN EMPTY STRING IF NO INPUT VALUE. BUT RETURNS ""='VALUE'" IF GOT VALUE. AND IF IT HAS A RANGE, RETURN WITH RANGE FILTER
  formatQuery(key, obj, partial = false) {
    if (obj == "" || obj === null) return "";
    else if (partial) {
      return key + " Like '%" + obj + "%'";
    } else {
      return key + "='" + obj + "'";
    }
  }

  serialize(obj) {
    var str = [];
    if (Object.keys(obj).length != 0) {
      for (const key of Object.keys(obj))
        str.push(encodeURIComponent(obj[key]));
      return "&CQL_FILTER=" + str.join(" AND ");
    }

    return "";
  }

  bboxQuery() {
    if (this.selectionArea?.bounds.toBBoxString())
      return (
        "BBOX(geometry, " +
        this.selectionArea.bounds.toBBoxString() +
        ",'EPSG:4326')"
      );
    else return "";
  }

  //GETS THE TIME RANGE FROM USER INPUT
  timeQuery() {
    const startDate = this.formData?.startDateTime.utc().format();
    const endDate = this.formData?.endDateTime.utc().format();

    if (endDate && startDate) {
      return (
        "endposition AFTER " +
        startDate +
        " AND beginposition BEFORE " +
        endDate
      );
    } else if (startDate) {
      return "endposition AFTER " + startDate;
    } else if (endDate) {
      return "beginposition BEFORE " + endDate;
    } else {
      return "";
    }
  }

  appendOverpassTime(date, timeID) {
    const time = document.getElementById(timeID).value;
    if (time) {
      return date + "T" + time;
    } else {
      return date + "T00:00";
    }
  }

  //RETRIEVES ALL THE FILTERS FORM THE INPUT FIELDS
  getFilters() {
    this.valueCheck("timefilter", this.timeQuery());
    this.valueCheck("bboxfilter", this.bboxQuery());
    this.fieldCheck("orbitdirection", "orbitDirection");
    this.fieldCheck("identifier", "identifier", true);
    this.fieldCheck("beammode", "beamMode");
    this.fieldCheck("producttype", "productType");
    this.fieldRangeCheck("framenumber", "frameNumber", "endFrameNumber");
    // fieldCheck("orbitnumber", "orbitNumber");
    this.fieldRangeCheck(
      "relativeorbitnumber",
      "relOrbitNumber",
      "endRelOrbitNumber"
    );

    console.log(this.cqlQueryDict);
    return this.serialize(this.cqlQueryDict);
  }
}
