import SatelliteIcon from "assets/MarkerIcons/satellite.png";
import L from "leaflet";
import { useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import * as satellite from "satellite.js";
import { convertCoordinateAntiMeridian } from "utils/helper";

// Helper function to split a path at the antimeridian
const splitPathAtAntimeridian = (path) => {
  const segments = [];
  let currentSegment = [path[0]];

  for (let i = 1; i < path.length; i++) {
    const prevLng = currentSegment[currentSegment.length - 1][1];
    const currLng = path[i][1];
    if (Math.abs(prevLng - currLng) > 180) {
      segments.push(currentSegment);
      currentSegment = [path[i]];
    } else {
      currentSegment.push(path[i]);
    }
  }
  segments.push(currentSegment);
  return segments;
};

const SatelliteAnimation = ({ tleArray, enableOffset = false }) => {
  const map = useMap();
  // References for markers and polylines (orbit paths) for each satellite.
  const satelliteRefs = useRef([]);
  const polylineRefs = useRef([]);

  const satelliteIcon = new L.Icon({
    iconUrl: SatelliteIcon,
    iconSize: [32, 32],
  });

  // Create satellite records (satrec) once from the TLEs.
  const satrecs = tleArray.map((tle) =>
    satellite.twoline2satrec(tle.line1, tle.line2)
  );

  // Function to generate an orbit path for visualization using the current time as reference.
  // (This will create a static path for context; the live position updates separately.)
  const generateOrbitPath = (satrec) => {
    const steps = 175;
    // Calculate orbital period in seconds using the mean motion (satrec.no in radians per minute)
    const orbitalPeriodSeconds = ((2 * Math.PI) / satrec.no) * 60;
    const stepInterval = orbitalPeriodSeconds / steps;
    const path = [];
    const now = new Date();
    for (let i = 0; i < steps; i++) {
      const time = new Date(now.getTime() + i * stepInterval * 1000);
      const posVel = satellite.propagate(satrec, time);
      if (posVel.position) {
        const gmst = satellite.gstime(time);
        const posGd = satellite.eciToGeodetic(posVel.position, gmst);
        const lat = satellite.degreesLat(posGd.latitude);
        const lon = satellite.degreesLong(posGd.longitude);
        path.push(
          enableOffset ? convertCoordinateAntiMeridian([lat, lon]) : [lat, lon]
        );
      }
    }
    return path;
  };

  useEffect(() => {
    if (map && tleArray.length > 0) {
      // Clear previous markers and polylines
      satelliteRefs.current = [];
      polylineRefs.current = [];

      // Generate orbit paths for each satellite based on current time.
      const satellitePaths = satrecs.map((satrec) => generateOrbitPath(satrec));

      satrecs.forEach((satrec, index) => {
        const path = satellitePaths[index];
        const segments = splitPathAtAntimeridian(path);
        const polylineGroup = [];

        // Draw orbit path on the map.
        segments.forEach((segment) => {
          const polyline = L.polyline(segment, {
            color: `hsl(${(index * 360) / tleArray.length}, 100%, 50%)`,
            weight: 3,
            opacity: 0.6,
          }).addTo(map);
          polylineGroup.push(polyline);
        });
        polylineRefs.current.push(polylineGroup);

        const now = new Date();
        // Compute the initial marker position using the current time.
        const posVel = satellite.propagate(satrec, now);
        let initialPos = [0, 0];
        if (posVel.position) {
          const gmst = satellite.gstime(now);
          const posGd = satellite.eciToGeodetic(posVel.position, gmst);
          const lat = satellite.degreesLat(posGd.latitude);
          const lon = satellite.degreesLong(posGd.longitude);
          initialPos = enableOffset
            ? convertCoordinateAntiMeridian([lat, lon])
            : [lat, lon];
        }
        // Create and add the satellite marker to the map.
        // satelliteRefs.current.push(
        //   L.marker(initialPos, { icon: satelliteIcon }).addTo(map)
        // );

        const marker = L.marker(initialPos, { icon: satelliteIcon }).addTo(map);
        // Attach a click event listener to the marker.
        marker.on("click", () => {
          const nowClick = new Date();
          const posVelClick = satellite.propagate(satrec, nowClick);
          if (posVelClick.position && posVelClick.velocity) {
            const gmst = satellite.gstime(nowClick);
            const posGd = satellite.eciToGeodetic(posVelClick.position, gmst);
            const lat = satellite.degreesLat(posGd.latitude).toFixed(2);
            const lon = satellite.degreesLong(posGd.longitude).toFixed(2);
            const alt = posGd.height.toFixed(2); // Altitude in kilometers
            // Compute the speed (magnitude of velocity vector) in km/s
            const { x, y, z } = posVelClick.velocity;
            const speed = Math.sqrt(x * x + y * y + z * z).toFixed(2);
            const popupContent = `<div style="font-size: 14px;">
              <strong>Satellite Info</strong><br/>
              Latitude: ${lat}&deg;<br/>
              Longitude: ${lon}&deg;<br/>
              Altitude: ${alt} km<br/>
              Speed: ${speed} km/s
            </div>`;
            marker.unbindPopup();
            marker.bindPopup(popupContent).openPopup();
          }
        });
        satelliteRefs.current.push(marker);
      });

      // Live update interval for updating positions
      const interval = setInterval(() => {
        satrecs.forEach((satrec, index) => {
          const posVel = satellite.propagate(satrec, new Date());
          if (posVel.position) {
            const gmst = satellite.gstime(new Date());
            const posGd = satellite.eciToGeodetic(posVel.position, gmst);
            const lat = satellite.degreesLat(posGd.latitude);
            const lon = satellite.degreesLong(posGd.longitude);
            const position = enableOffset
              ? convertCoordinateAntiMeridian([lat, lon])
              : [lat, lon];
            const marker = satelliteRefs.current[index];
            marker.setLatLng(position);
          }
        });
      }, 1000); // update every 1 second

      // Cleanup function removes interval and map layers when the effect re-runs or component unmounts.
      return () => {
        clearInterval(interval);
        satelliteRefs.current.forEach((marker) => marker.remove());
        polylineRefs.current.forEach((group) =>
          group.forEach((polyline) => polyline.remove())
        );
      };
    }
  }, [map, tleArray, satrecs]);

  return null;
};

export default SatelliteAnimation;
